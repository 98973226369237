<template>
  <li class="chat-history-item">
    <button
      class="chat-history-button"
      @click="selectChatHistory"
      :class="{ 'active': chatHistory.id === selectedChatHistoryId }"
    >
      {{ truncatedText(chatHistory) }}
    </button>
  </li>
</template>

<style scoped>
.chat-history-item {
  margin-bottom: 10px;
}

.chat-history-button {
  background-color: transparent;
  text-align: left;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px 15px;
  border: 2px solid transparent;
  border-radius: 4px;
  transition: border-color 0.2s ease;
}

.chat-history-button.active {
  background-color: var(--kate-background);
}
</style>

<script>
export default {
  props: {
    chatHistory: {
      type: Object,
      required: true,
    },
    selectedChatHistoryId: {
      type: Number,
    },
  },

  methods: {
    truncatedText(chatHistory) {
      return chatHistory.query.length > 25 ? `${chatHistory.query.slice(0, 25)}...` : chatHistory.query;
    },

    selectChatHistory() {
      this.$emit('select-chat', this.chatHistory);
    },
  },
};
</script>
