<template>
  <li class="profile-page" ref="profilePage">
    <button type="button" class="btn-icon user-avatar" aria-label="User" title="User" @click="toggleOpen">
      <img id="navbar-avatar" :src="avatarUrl" class="img-circle"  alt=""/>
      {{ displayName }}
      <i class="fas fa-caret-down"></i>
    </button>
    <ul class="dropdown" :class="[{ 'is-open': isOpen }, { 'dashboard-dropdown' : isDashboard }]">
      <li v-if="formattedUserId" class="user-id">
        <span>{{ isDashboard ? 'User' : 'Learner' }} ID: <b>{{ formattedUserId }}</b></span>
      </li>
      <li class="profile-link">
        <router-link :to="{ name: 'profile_landing' }">
          <i class="fas fa-user-circle"></i>My Profile
        </router-link>
      </li>
      <support-dropdown
        @open="openSupportDropdown"
        @close="closeSupportDropdown"
        @open-help-modal="handleOpenHelpModal"
        @open-module-modal="handleOpenModuleModal"
        :isOpen="showSupportDropdown"
        class="support-dropdown"
      ></support-dropdown>
      <li>
        <button type="button" id="logout-button" @click="logout"><i class="fas fa-sign-out-alt"></i>Log Out</button>
      </li>
    </ul>

    <k-issue class="help-modal"
      :show="showHelp"
      :help-key="helpModalData.helpKey"
      :default-module-name="helpModalData.moduleName"
      :default-asset-name="helpModalData.moduleAssetName"
      :default-thread-name="helpModalData.threadName"
      :programme-id="helpModalData.programmeId"
      :module-id="helpModalData.moduleId"
      :asset-id="helpModalData.assetId"
      :module-asset-id="helpModalData.moduleAssetId"
      :module-asset-type="helpModalData.moduleAssetType"
      :module-asset-type-pretty="helpModalData.moduleAssetTypePretty"
      :thread-id="helpModalData.threadId"
      @close="showHelp = false"
      @enter-screenshot-mode="enterScreenshotMode"
    ></k-issue>

    <select-module class="help-modal"
      v-if="showChooseModule"
      :programme-id="moduleModalData.programmeId"
      :show="showChooseModule"
      @close="showChooseModule = false"
    ></select-module>
  </li>
</template>

<style>
.help-modal {
  text-align: left;
}
</style>

<script>
import useGlobalStore from '@stores/global';
import formatId from '../../modules/format-user-id';
import SupportDropdown from './support-dropdown.vue';
import KIssue from '../k-issue-form.vue';
import SelectModule from './select-module.vue';

export default {
  components: {
    SupportDropdown,
    KIssue,
    SelectModule,
  },

  props: {
    isOpen: {
      type: Boolean,
    },
  },

  data() {
    return {
      store: useGlobalStore(),
      showSupportDropdown: false,
      showHelp: false,
      showChooseModule: false,
      helpModalData: {},
      moduleModalData: {},
    };
  },

  watch: {
    route() {
      // Close the dropdown if the route changes
      this.$emit('close');
    },
    inScreenshotMode() {
      if (this.inScreenshotMode === false) {
        this.exitScreenshotMode();
      }
    },
  },

  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  computed: {
    route() {
      return this.$route;
    },
    isDashboard() {
      return this.store.isDashboard;
    },
    name() {
      return this.store.userFullName;
    },
    userPreferredName() {
      return this.store.userPreferredName;
    },
    formattedUserId() {
      if (!this.store.userId) {
        return undefined;
      }
      return formatId(this.store.userId);
    },
    avatarUrl() {
      return this.store.avatarUrl;
    },
    displayName() {
      if (!this.userPreferredName && this.name) {
        return this.name.split(' ')[0];
      }
      return this.userPreferredName;
    },
    inScreenshotMode() {
      return this.store.inScreenshotMode;
    },
  },

  methods: {
    logout() {
      this.$Loading.start();
      this.$http.post('/api/auth/logout', {}, {
        // respect set-cookie header for logging out
        withCredentials: true,
      }).then(() => {
        this.store.logOut();
        this.$router.push({ name: 'login_page' });
      }).then(() => {
        this.$Loading.finish();
      });
    },

    handleClickOutside(event) {
      const profilePage = this.$refs.profilePage;
      if (profilePage && !profilePage.contains(event.target) && !this.showSupportDropdown) {
        this.$emit('close');
      }
    },

    toggleOpen() {
      if (this.isOpen) {
        this.$emit('close');
      } else {
        this.$emit('open');
      }
    },

    openSupportDropdown() {
      this.showSupportDropdown = true;
    },

    closeSupportDropdown() {
      this.showSupportDropdown = false;
    },

    handleOpenHelpModal(data) {
      this.helpModalData = data;
      this.showHelp = true;
    },

    handleOpenModuleModal(data) {
      this.moduleModalData = data;
      this.showChooseModule = true;
    },

    enterScreenshotMode() {
      // Hide help modal
      this.showHelp = false;
      // Close the support dropdown
      this.showSupportDropdown = false;
      // Enter screenshot mode
      this.store.enterScreenshotMode();
    },

    exitScreenshotMode() {
      this.showHelp = true;
      this.store.exitScreenshotMode();
    },
  },
};

</script>
