<template>
  <li class="notification" :class="{ 'is-open': isOpen }" ref="notifDropdown">
    <button type="button" class="btn-icon notification-dropdown-btn" aria-label="Notification" title="Notification" @click="toggleOpen">
      <i class="fas fa-bell"></i>
      <span v-if="unreadNotifications && unreadNotifications.length > 0" class="count-bubble">
        {{ unreadNotifications.length }}
      </span>
    </button>
    <span class="dropdown-container-arrow"></span>
    <ul v-if="notifications && notifications.length > 0" class="dropdown" :class="{ 'is-open': isOpen }">
      <li v-for="n in notificationSample"
        :key="n.id"
        class="notification-item"
        :class="{ unread: !n.read_at }">
        <router-link
          v-if="isRoutable(n)"
          :to="getThreadRoute(n)">
          <span class="subject" v-html="$kpurify.sanitise(n.subject)"></span>
          <span v-if="!n.read_at" class="new-label">New</span>
          <span class="body">{{ n.body }}</span>
          <span class="post-time">{{ parseTimestamp(n.posted_at) }}</span>
        </router-link>
        <div class="no-link-notification" v-else>
          <span class="subject" v-html="$kpurify.sanitise(n.subject)"></span>
          <span v-if="!n.read_at" class="new-label">New</span>
          <span class="body">{{ n.body }}</span>
          <span class="post-time">{{ parseTimestamp(n.posted_at) }}</span>
        </div>
      </li>
      <li class="show-more" v-if="showMoreButtonVisible">
        <button class="btn btn-primary" @click="showMore()">Show More</button>
      </li>
    </ul>
  </li>
</template>

<style scoped>
.notification-dropdown-btn > i {
  position: relative;
}

i.fas.fa-bell {
  padding: 0 5px;
  margin: 0;
  font-size: 1.2em;
}

.count-bubble {
  position: absolute;
  color: var(--kate-mono-0);
  background-color: var(--kate-danger);
  top: 7px;
  right: 7px;
  font-size: 0.7em;
  margin: 0;
}

li.notification-item.unread {
  border-bottom: 1px solid var(--kate-secondary);
  border-top: 1px solid var(--kate-secondary);
  background-color: var(--kate-background-body);
}

.no-link-notification {
  padding: 10px;
}

.no-link-notification:hover {
  background-color: var(--kate-background-body-alpha);
}

.no-link-notification > span.subject {
  color: var(--kate-mono-0);
}

li.notification-item > a > span,
li.notification-item > .no-link-notification > span {
  display: block;
  line-height: 1.5;
  text-align: left;
}

li.notification-item > a > span.body,
li.notification-item > a > span.post-time,
li.notification-item > .no-link-notification > span.body,
li.notification-item > .no-link-notification > span.post-time {
  font-size: 0.8em;
  color: var(--kate-type-light);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

li.notification-item > a > span.post-time,
li.notification-item > .no-link-notification > span.post-time {
  color: var(--kate-type-primary);
}

span.new-label {
  width: min-content;
  font-size: 0.9em;
  padding: 0 5px;
  border-radius: 5px;
  background-color: var(--kate-success);
  color: var(--kate-type-dark);
}

ul.dropdown {
  padding-bottom: 0 !important;
}

.dropdown button {
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  border: 0;
  padding: 8px 15px;
  border-radius: 0;
  line-height: unset !important;
}
</style>

<style>
nav.navbar div ul.nav li.notification-item.unread a:hover {
  text-decoration: none;
}

nav.navbar div ul.nav li.notification-item.unread a:hover > span.subject b,
nav.navbar div ul.nav li.notification-item.unread a:hover > span.subject {
  color: var(--kate-logo-lime-green);
}

nav.navbar div ul.nav li.notification-item.unread .subject b {
  color: var(--kate-secondary);
}

nav.navbar div ul.nav li.notification-item .subject b {
  color: var(--kate-warning-light);
}
</style>

<script>
import TimeMixin from '@mixins/time-mixins';

export default {
  mixins: [TimeMixin],

  data() {
    return {
      nNotificationsShown: undefined,
      nExtraNotificationIncrements: 10,
    };
  },

  beforeMount() {
    this.nNotificationsShown = this.nExtraNotificationIncrements;
  },

  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  props: {
    notifications: {
      type: Array,
    },
    isOpen: {
      type: Boolean,
    },
    isDashboard: {
      type: Boolean,
    },
  },

  watch: {
    route() {
      // Close if ever the route changes
      this.onClose();
    },
    isOpen() {
      if (!this.isOpen) {
        this.nNotificationsShown = this.nExtraNotificationIncrements;
      }
    },
  },

  computed: {
    route() {
      return this.$route;
    },
    unreadNotifications() {
      if (!this.notifications) {
        return [];
      }
      return this.notifications.filter(x => !x.read_at);
    },
    notificationSample() {
      return this.notifications.slice(0, this.nNotificationsShown);
    },
    showMoreButtonVisible() {
      return this.notifications.length > this.nExtraNotificationIncrements
          && this.notificationSample.length !== this.notifications.length;
    },
    notificationRouteMap() {
      return {
        thread: notification => ({
          name: this.getThreadPageName(notification.payload.module_id),
          params: {
            moduleId: notification.payload.module_id,
            threadId: notification.payload.root_parent_id,
          },
        }),
        accept_answer: notification => ({
          name: this.getThreadPageName(notification.payload.module_id),
          params: {
            moduleId: notification.payload.module_id,
            threadId: notification.payload.root_parent_id,
          },
        }),
        new_question: notification => ({
          name: this.getThreadPageName(notification.payload.module_id),
          params: {
            moduleId: notification.payload.module_id,
            threadId: notification.payload.thread_id,
          },
        }),
        question_assigned_to_admin: notification => ({
          name: this.getThreadPageName(notification.payload.module_id),
          params: {
            moduleId: notification.payload.module_id,
            threadId: notification.payload.thread_id,
          },
        }),
      };
    },
  },

  methods: {
    toggleOpen() {
      if (this.isOpen) {
        this.onClose();
      } else {
        this.onOpen();
      }
    },
    onOpen() {
      this.$emit('open');
    },
    onClose() {
      // Only read notifications if menu was open at the time
      this.nNotificationsShown = this.nExtraNotificationIncrements;
      this.$emit('close', this.isOpen);
    },
    showMore() {
      this.nNotificationsShown += this.nExtraNotificationIncrements;
    },
    isRoutable(notification) {
      return Boolean(Object.prototype.hasOwnProperty.call(this.notificationRouteMap, notification.notification_type));
    },
    getThreadPageName(moduleId) {
      if (this.isDashboard) {
        return moduleId ? 'knowledge_thread' : 'knowledge_detached_thread';
      }
      return moduleId ? 'knowledge_thread' : 'profile_knowledge_thread';
    },
    getThreadRoute(notification) {
      return this.notificationRouteMap[notification.notification_type](notification);
    },

    handleClickOutside(event) {
      const notifDropdown = this.$refs.notifDropdown;
      if (notifDropdown && !notifDropdown.contains(event.target)) {
        this.onClose();
      }
    },
  },
};
</script>
