<template>
  <div class="chart-container">
    <apexchart :height="`${height}px`" type="line" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<style>
  .apexcharts-tooltip {
    background-color: var(--kate-background-body) !important;
    color: var(--kate-type-light);
  }

  .apexcharts-tooltip.apexcharts-theme-dark .apexcharts-tooltip-title {
    background: var(--kate-background);
  }

  .apexcharts-xaxistooltip {
    background-color: var(--kate-background-body) !important;
  }

  .apexcharts-toolbar {
    display: flex;
    gap: 15px;
    font-size: 1.5em;
  }

  .apexcharts-toolbar > div {
    color: var(--kate-secondary);
  }

  .apexcharts-toolbar > div:hover {
    color: var(--kate-secondary-dark);
  }

  .apexcharts-menu {
    display: none;
  }
</style>

<script>
export default {
  props: {
    yaxisMax: {
      type: Number,
    },
    xaxisData: {
      type: Array,
    },
    seriesOne: {
      type: Array,
    },
    seriesOneTitle: {
      type: String,
      default: 'Series One',
    },
    seriesOneType: {
      type: String,
      default: 'line',
    },
    stepSize: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 300,
    },
  },

  data() {
    return {
      chartOptions: {
        chart: {
          height: 100,
          type: 'line',
          width: '100%',
          sparkline: {
            enabled: false,
          },
          dropShadow: {
            enabled: true,
            top: -1,
            left: 0,
            blur: 5,
            opacity: 0.5,
            color: 'var(--kate-chart-dropshadow-color)',
          },
          toolbar: {
            show: true,
            tools: {
              zoom: '<i class="far fa-object-group"></i>',
              zoomin: '<i class="fas fa-search-plus"></i>',
              zoomout: '<i class="fas fa-search-minus"></i>',
              pan: false,
              download: false,
              reset: false,
              selection: false,
            },
          },
        },
        noData: {
          text: 'No data available',
          align: 'center',
          verticalAlign: 'middle',
          style: {
            fontSize: '20px',
            color: 'rgba(255, 255, 255, .5)',
          },
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          labels: {
            colors: 'var(--kate-type-primary)',
            useSeriesColors: false,
          },
          itemMargin: {
            horizontal: 5,
            vertical: 15,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
          markers: {
            size: 3,
            fillColors: [
              'var(--kate-chart-markers-color)',
            ],
            radius: 2,
          },
        },
        grid: {
          show: true,
          borderColor: 'var(--kate-background-alpha)',
        },
        responsive: [{
          breakpoint: 1000,
          options: {},
        }],
        // Chart lines strokes styling
        stroke: {
          width: 3,
          curve: 'smooth',
          colors: [
            'var(--kate-chart-color)',
          ],
        },
        // Fancy optional gradient
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            gradientToColors: [
              'var(--kate-chart-gradient-to-color)',
            ],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
          },
        },
        // Markers on the lines
        markers: {
          size: 4,
          strokeColors: '#0e0d1a',
          strokeWidth: 1,
          colors: ['#008ffb'],
          strokeOpacity: 0.9,
          shape: 'circle',
          radius: 2,
          hover: {
            sizeOffset: 2,
          },
        },
        xaxis: {
          // Prop xaxis goes here to populate x-axis entries
          type: 'string',
          categories: this.xaxisData,
          tickAmount: 12,
          axisBorder: {
            show: true,
            color: 'var(--kate-background-alpha)',
            height: 1,
            width: '100%',
            offsetX: 0,
            offsetY: 0,
          },
          labels: {
            show: true,
            maxHeight: 120,
            style: {
              colors: 'var(--kate-type-alt-dark)',
              fontSize: '12px',
            },
          },
        },
        yaxis: {
          min: 0,
          stepSize: this.stepSize,
          // Set the max to be the total of all learners with prop yaxisMax
          max: this.yaxisMax,
          showForNullSeries: false,
          tickAmount: this.yaxisMax <= 5 ? this.yaxisMax : 5,
          labels: {
            show: true,
            style: {
              colors: 'var(--kate-type-alt-dark)',
            },
          },
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: false,
          fillSeriesColor: false,
          shared: true,
          theme: 'dark',
          style: {
            fontSize: '12px',
          },
          markers: {
            show: false,
          },
        },
      },
      // Data that will create the lines
      series: [
        {
          name: this.seriesOneTitle,
          type: this.seriesOneType,
          data: this.seriesOne,
        },
      ],
    };
  },

  watch: {
    seriesOne() {
      this.updateSeriesData();
    },
  },

  methods: {
    updateSeriesData() {
      this.series = [
        {
          name: this.seriesOneTitle,
          type: this.seriesOneType,
          data: this.seriesOne,
        },
      ];
    },
  },
};

</script>
