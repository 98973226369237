<template>
  <div class="panel panel-default">
    <div class="panel-body">
      <span class="current-token-label">Your current token is:</span>
      <div class="http-token">
        <input id="repo-access-token-input" :type="tokenFieldType" readonly="true" v-model="repoAccessToken">
        <button @click="toggleShowRepoToken" title="Clone http token" aria-label="Clone http token"><i class="fas fa-eye clickable"></i></button>
      </div>
      <div class="renew-token-button-container">
        <button class="btn btn-primary renew-token-button" @click="createRepoAccessToken">
          <i class="fas fa-sync-alt"></i>&nbsp;
          Reset token
        </button>
      </div>
      <p class="reset-token-warning"><strong>WARNING: </strong>This will log you out of all currently cloned repositories. In order to update an existing repository, copy the new URL on your project page and run:<pre>git remote set-url origin &lt;NEW_URL&gt;</pre></p>
    </div>
  </div>
</template>

<style scoped>
#repo-access-token-input {
  min-width: 210px;
}

.current-token-label {
  font-size: large;
  font-weight: bold;
}

.http-token {
  text-align: left;
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: large;
}

.renew-token-button-container {
  margin-bottom: 2em;
}

.reset-token-warning {
  font-size: larger;
}
</style>

<script>
import RepoControl from '@mixins/repo-control-mixins';

export default {
  mixins: [RepoControl],
  data() {
    return {
      showRepoToken: false,
    };
  },
  computed: {
    tokenFieldType() {
      return this.showRepoToken ? 'text' : 'password';
    },
  },
  beforeMount() {
    this.getRepoAccessToken();
  },
  methods: {
    toggleShowRepoToken() {
      this.showRepoToken = !this.showRepoToken;
    },
  },
};
</script>
