<template>
  <div class="all-files-container">
    <ul>
      <li v-for="(entry, filename, index) in layout"
          class="file-summary-item"
          :key="filename">
        <k-file-dropdown
          :start-toggled="startWithEntryToggled(filename, index)"
          :file-level-error="isFileLevelError(filename)"
          :grey="greyFile(filename)"
          :entry="entry"
          :filename="filename"
          @select="emitSelect"
          ></k-file-dropdown>
      </li>
    </ul>
  </div>
</template>

<style>
.all-files-container .k-file-link.toggled + .k-function-list {
  max-height: 425px;
  transition: max-height 0.3s;
  align-items: center;
  background-color: var(--kate-background-body-alpha);
  overflow-y: auto;
  padding: 0 15px;
}

.file-summary-item .k-function-list li a {
  word-break: break-word;
  padding-right: 5px;
}

.file-summary-item .grey {
  word-break: break-all;
}
</style>

<style scoped>
.all-files-container ul {
  padding-left: 0;
}

ul li.file-summary-item {
  padding: 5px 5px 0;
  list-style: none;
}

ul li.file-summary-item.active a.k-file-link:hover,
ul li.file-summary-item.active a span {
  text-decoration: none;
}

.file-summary-item.active .k-function-list,
.file-summary-item.toggled .k-function-list {
  max-height: 500px;
  transition: max-height 0.3s;
  align-items: center;
}

ul li.file-summary-item .k-layout-label-not_implemented,
ul li.file-summary-item .k-layout-label-failure,
ul li.file-summary-item .k-layout-label-success {
  border-radius: 4px;
  padding: 2px 10px;
  cursor: pointer;
}

ul li.file-summary-item .k-layout-label-success {
  color: var(--kate-type-dark);
}

.k-file-link {
  cursor: pointer;
  position: relative;
  width: 100%;
  display: block;
  color: var(--kate-primary);
}

.k-file-link:hover .error-badge {
  background-color: var(--kate-danger-dark);
}

.active .k-file-link:hover .error-badge {
  background-color: var(--kate-danger);
  text-decoration: none;
}

</style>

<script>
import KFileDropdown from './k-file-dropdown.vue';

export default {
  components: {
    'k-file-dropdown': KFileDropdown,
  },

  props: {
    layout: Object,
    syntaxErrors: Array,
    fileLevelErrors: Array,
    timeoutError: {
      type: Boolean,
      default: false,
    },
    memoryError: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    firstEntryWithErrorIndex() {
      const keys = Object.keys(this.layout);
      for (let i = 0; i < keys.length; i++) {
        if (this.layout[keys[i]].error_count > 0) {
          return i;
        }
      }
      return 0;
    },
  },

  methods: {
    greyFile(filename) {
      if (this.timeoutError || this.memoryError) {
        return true;
      }
      if (!this.syntaxErrors) {
        return false;
      }
      return this.syntaxErrors.indexOf(filename) === -1;
    },
    isFileLevelError(filename) {
      if (!this.fileLevelErrors) {
        return false;
      }
      return this.fileLevelErrors.indexOf(filename) !== -1;
    },
    emitSelect(filename, functionName) {
      this.$emit('select', filename, functionName);
    },
    startWithEntryToggled(filename, index) {
      if (this.isFileLevelError(filename)) {
        return false;
      }
      return index === this.firstEntryWithErrorIndex;
    },
  },
};
</script>
