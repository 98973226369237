<template>
  <div>
  <k-modal class="add-question-modal" :show="show" @close="close">
    <template #header>
      <h2>Ask a question</h2>
    </template>
    <template #body>
      <form class="help-ticket-form" enctype="multipart/form-data">
        <fieldset class="form-entry">
          <span>Content type:</span><br>
          <div class="form-info">
            <span>Please select one</span>
          </div>
          <k-dropdown placeholder="Select" :options="availableAssetTypes" v-model="selectedAssetType"></k-dropdown>
        </fieldset>

        <fieldset v-if="assetsOfSelectedType" class="form-entry">
          <div class="form-info">
            <span>Please select which {{ assetTypePrettyName(selectedAssetType) }} your question relates to</span>
          </div>
          <k-dropdown placeholder="Select" :options="assetsOfSelectedType" v-model="selectedModuleAssetId"></k-dropdown>
        </fieldset>

        <fieldset class="form-entry"><!-- // jscpd:ignore-start -->
          <span>Title:</span><br>
          <div class="form-info">
            <span>Please keep it short and specific</span>
          </div>
          <input v-model="title" maxlength="100" placeholder="Enter title here..." class="form-input k-issue-text">
        </fieldset>

        <fieldset class="form-entry">
          <span>Body:</span><br>
          <div class="form-info">
            <span>Please include all the information someone would need to answer your question.</span><br>
          </div>
          <k-text-editor
            id="questionTextEditor"
            v-model="body"
            @pasteResources="pasteResources"
          ></k-text-editor>
        </fieldset><!-- // jscpd:ignore-end -->
      </form>

        <div class="form-entry">
          <button title="Upload resources" class="btn btn-primary" @click="openUploadDialog">
            Upload Resources
            <i title="Upload resources" class="fas fa-upload clickable"></i>
          </button>
          <!-- eslint-disable-next-line label-has-for -->
          <input id="upload-file-input" type="file"
            name="files"
            style="display: none;"
            ref="filesInput"
            :accept="acceptedResources"
            @change="filesChange($event.target.files)" multiple>
          <k-file-list :files="resources" @remove-file="removeResource"></k-file-list>
        </div>
    </template>
    <template #footer>
      <button @click="close" class="btn btn-danger">Cancel</button>
      <button @click="addNewQuestion" :disabled="disableSend" class="btn btn-primary send-btn">
        <i class="fa fa-spinner fa-spin" v-if="creationInProgress"></i> Post question</button>
    </template>
  </k-modal>
  <!-- // jscpd:ignore-start -->
  <k-modal :show="showEditModal" @close="closeEditModal" class="view-programme-confirm-edit-modal">
    <template #header>
      <h3>You have unsaved edits</h3>
    </template>
    <template #body>
      <div class="view-programme-confirm-modal-body">
        <p>Are you sure you want to stop editing this post?</p>
        <p>Your changes will not be saved.</p>
      </div>
    </template>
    <template #footer>
      <button class="modal-return-button btn btn-default cancel-btn" @click="closeEditModal">Cancel </button>
      <button class="btn btn-danger modal-delete-button" @click="discardChanges()">
        Discard Changes
      </button>
    </template>
  </k-modal>
  <!-- // jscpd:ignore-end -->
  </div>
</template>

<style>
.add-question-modal .modal-header {
  padding: 10px 30px;
}
</style>

<style scoped>
.form-entry {
  margin-bottom: 15px;
}

.form-entry:last-child {
  margin-bottom: 0;
}

.form-entry input,
.form-entry textarea {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 0;
  margin: 5px 0;
}

.form-info {
  color: var(--kate-type-primary);
  min-height: unset;
}

.form-entry textarea {
  width: 100%;
  margin-bottom: 1rem;
  padding: 8px;
}

.question-extra-info p {
  color: var(--kate-type-light);
  margin-bottom: 5px;
}

ul.file-list {
  overflow: hidden;
}

</style>

<script>
import KModal from '@base-components/k-modal.vue';
import KDropdown from '@base-components/k-dropdown.vue';
import ErrorMixin from '@mixins/error-mixins';
import TimeMixin from '@mixins/time-mixins';
import ResourceMixin from '@mixins/resource-mixins';
import SidebarMixin from '@mixins/sidebar-mixin';
import KFileList from '@base-components/k-file-list.vue';
import KTextEditor from '@base-components/k-text-editor.vue';
import { ASSET_TYPES } from '../constants';

export default {
  mixins: [ErrorMixin, TimeMixin, ResourceMixin, SidebarMixin],

  components: {
    'k-modal': KModal,
    'k-dropdown': KDropdown,
    'k-file-list': KFileList,
    'k-text-editor': KTextEditor,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      title: '',
      body: '',
      trackChanges: false,
      isEdited: false,
      showEditModal: false,
      creationInProgress: false,
      resources: [],
      assetTypes: Object.keys(ASSET_TYPES).map(at => (
        { id: at, name: this.assetTypePrettyName(at) }
      )),
      selectedAssetType: undefined,
      selectedModuleAssetId: undefined,
    };
  },

  watch: {
    selectedAssetType() {
      if (this.selectedAssetType) {
        this.selectedModuleAssetId = undefined;
      }
      if (this.trackChanges) {
        this.isEdited = true;
      }
    },

    resources() {
      if (this.trackChanges) {
        this.isEdited = true;
      }
    },

    body() {
      if (this.trackChanges) {
        this.isEdited = true;
      }
    },

    title() {
      if (this.trackChanges) {
        this.isEdited = true;
      }
    },

    show() {
      if (this.show) {
        this.trackChanges = true;
      }
    },
  },

  computed: {
    moduleId() {
      return parseInt(this.$route.params.moduleId, 10);
    },

    availableAssetTypes() {
      return this.assetTypes.filter(option => this.assets.map(a => a.asset_type).includes(option.id));
    },

    assetsOfSelectedType() {
      if (this.selectedAssetType !== undefined) {
        return this.assets.filter(a => a.asset_type === this.selectedAssetType);
      }
      return undefined;
    },

    selectedAssetId() {
      if (this.selectedModuleAssetId) {
        const asset = this.assets.find(x => x.id === this.selectedModuleAssetId && x.asset_type === this.selectedAssetType);
        return asset ? asset[`${this.selectedAssetType}_id`] : null;
      }
      return null;
    },

    validForm() {
      return Boolean(this.title && this.body && this.selectedAssetType !== undefined && this.selectedModuleAssetId);
    },

    disableSend() {
      return !this.validForm || this.body.length > 10000;
    },
  },

  methods: {
    addNewQuestion() {
      this.$logger.info('Adding new thread', undefined, true);
      this.creationInProgress = true;
      this.getCreatePayload().then(payload => {
        this.$http.post(`/api/curriculum/knowledge/${this.moduleId}/threads`, payload).then(() => {
          this.$ktoast.success('Question has been posted');
          this.$logger.info('Posted new question', { moduleId: this.moduleId }, true);
          this.$emit('added');
          this.isEdited = false;
          this.close();
        }).catch(err => {
          this.$logger.error('Error posting question', { moduleId: this.moduleId }, err);
          this.showError(err);
        }).then(() => {
          this.creationInProgress = false;
        });
      });
    },

    getCreatePayload() {
      return this.formatResourcesForUpload().then(formattedResources => ({
        title: this.title,
        body: this.body,
        resources: formattedResources,
        asset_type: this.selectedAssetType,
        asset_id: this.selectedAssetId,
      }));
    },

    openUploadDialog() {
      this.$refs.filesInput.click();
    },

    clearFields() {
      this.title = '';
      this.body = '';
      this.resources = [];
      this.selectedAssetType = undefined;
      this.selectedModuleAssetId = undefined;
      this.trackChanges = false;
      this.isEdited = false;
    },

    close() {
      if (this.isEdited) {
        this.openEditModal();
      } else {
        this.clearFields();
        this.$emit('close');
      }
    },

    openEditModal() {
      this.showEditModal = true;
    },

    closeEditModal() {
      this.showEditModal = false;
    },

    discardChanges() {
      this.isEdited = false;
      this.closeEditModal();
      this.close();
    },

    assetTypePrettyName(assetType) {
      return ASSET_TYPES[assetType].prettyName;
    },
  },
};
</script>
