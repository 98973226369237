<template>
  <li class="email-item" :class="{ 'primary-email' : email.is_primary }">
    <div class="address">
      <a :href="mailTo">{{ email.email }}</a>
      <span class="badge primary" v-if="email.is_primary">primary</span>
      <span class="badge primary-pending" v-else-if="email.primary_pending">primary pending</span>
      <span class="badge unverified" v-else-if="unverified">unverified</span>
    </div>
    <div class="action-buttons">
      <k-tooltip text="Resend verification">
        <button v-if="unverified"
          class="btn btn-redo-verification"
          :disabled="taskInProgress"
          @click="$emit('refresh', email)"
          aria-label="Resend verification"
          title="Resend verification"
        >
          <i class="fas fa-redo"></i>
        </button>
      </k-tooltip>
      <k-tooltip text="Remove email">
        <button v-if="!email.is_primary"
          class="btn btn-trash"
          :disabled="taskInProgress"
          @click="$emit('remove', email)"
          aria-label="Remove email"
          title="Remove email"
        >
          <i class="fas fa-trash-alt"></i>
        </button>
      </k-tooltip>
    </div>
  </li>
</template>

<style scoped>
.email-item {
  display: flex;
  justify-content: space-between;
}

.address {
  margin-top: 10px;
}

.action-buttons {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 5px;
}

.action-buttons button {
  padding: 5px 10px;
}

.badge {
  font-size: 0.85em;
  margin-left: 8px;
  margin-top: -2px;
}

.badge.primary {
  background-color: var(--kate-button-primary);
  border-color: var(--kate-button-primary);
  color: var(--kate-type-dark);
}

.badge.primary-pending,
.badge.unverified {
  background-color: var(--kate-danger);
  border-color: var(--kate-danger);
}

.btn-redo-verification {
  padding: 0;
  background-color: transparent;
}

.btn-redo-verification span,
.btn-redo-verification i {
  color: var(--kate-success);
  font-size: 1.3em;
  transition: all 0.3s;
}

.btn-redo-verification:hover span,
.btn-redo-verification:hover i {
  color: var(--kate-success-alt);
  transition: all 0.3s;
}
</style>

<script>
import KTooltip from '@base-components/k-tooltip.vue';

export default {
  components: {
    KTooltip,
  },

  props: {
    email: {
      type: Object,
      required: true,
    },
    taskInProgress: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    mailTo() {
      return `mailto:${this.email.email}`;
    },
    unverified() {
      return this.email.in_progress;
    },
  },
};
</script>
