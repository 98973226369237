<template>
  <transition name="modal">
    <div class="modal-mask" v-show="show" @click="clickAwayClose">
      <div class="modal-container" @click.stop>
        <span class="modal-close-button btn" @click="close" role="button" aria-label="Close modal" title="Close modal"><i class="fas fa-times"></i></span>
        <div class="modal-header">
          <slot name="header">
          <h3>KATE</h3>
          </slot>
        </div>
        <div class="modal-body">
          <slot name="body">
          </slot>
        </div>
        <div class="modal-footer text-right">
          <slot name="footer">
          <button class="modal-default-button btn btn-outlined" @click="close" aria-label="Close modal" title="Close modal">
            Close
          </button>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 0.5);
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-items: center;
}

.modal-container {
  min-width: 50%;
  max-height: 80vh;
  overflow: auto;
  margin: 0 auto;
  background-color: var(--kate-panel-alt);
  border-radius: 5px;
  transition: all 0.3s ease;
  position: relative;
  color: var(--kate-type-light);
}

.modal-header {
  padding: 20px 30px;
  border-bottom: 2px solid var(--kate-background-alpha);
}

.modal-header h3 {
  margin-top: 0;
  color: var(--kate-primary);
  font-weight: 400;
  font-size: 2em;
}

.modal-body {
  margin: 20px 0;
  padding: 0 30px;
  overflow: auto;
  max-height: 70%;
  position: relative;
}

.text-right {
  text-align: right;
}

.modal-close-button {
  position: absolute;
  top: 0;
  right: 0;
  font-size: large;
  color: var(--kate-type-primary);
  margin-top: 0.5em;
  margin-right: 0.5em;
}

.modal-close-button:hover {
  color: var(--kate-type-primary);
}

.modal-footer {
  border-top: 0;
  margin-top: 10px;
  padding: 20px 30px;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: scale(1.1);
}

@media (min-width: 768px) and (max-width: 900px) {
  .modal-container {
    width: 70%;
  }
}

@media only screen and (max-width: 767px) {
  .modal-header,
  .modal-body {
    padding: 15px 30px;
  }

  .modal-body {
    margin: 0;
  }

  .modal-container {
    width: 90%;
  }
}

</style>

<script>
export default {
  props: {
    show: {
      type: Boolean,
    },
    closeOnClickAway: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    document.addEventListener('keydown', this.keydownHandler);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    keydownHandler(e) {
      // Close on esc keydown
      if (this.show && e.keyCode === 27) {
        this.close();
      }
    },
    clickAwayClose() {
      // Relevant for situations where dropdowns expand outside of the modal
      if (this.closeOnClickAway) {
        this.close();
      }
    },
  },
};
</script>
