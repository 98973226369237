<template>
  <button id="burger" v-if="showToggle"
      :class="{ 'active' : isToggleActive }"
      class="burger-button"
      title="Sidebar menu toggle" aria-label="Sidebar menu toggle" @click="toggleSidebar">
    <slot>
      <div type="button">
        <span class="burger-bar burger-bar--1"></span>
        <span class="burger-bar burger-bar--2"></span>
        <span class="burger-bar burger-bar--3"></span>
      </div>
    </slot>
    <span data-v-step="3" class="sidebar-toggle-step"></span>
  </button>
</template>

<style scoped>
#burger {
  position: relative;
}

.hidden {
  visibility: hidden;
}

button {
  cursor: pointer;
}

/* remove blue outline */
button:focus {
  outline: 0;
}

.burger-button {
  position: relative;
  height: 30px;
  width: 32px;
  display: block;
  z-index: 999;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  pointer-events: all;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-bar {
  background-color: var(--kate-button-primary);
  position: absolute;
  top: 50%;
  right: 6px;
  left: 6px;
  height: 2px;
  width: auto;
  margin-top: -1px;
  transition:
    transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
    background-color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-bar--1 {
  transform: translateY(-6px);
}

.burger-bar--2 {
  transform-origin: 100% 50%;
  transform: scaleX(0.8);
}

.burger-button:hover .burger-bar--2 {
  transform: scaleX(1);
}

.no-touchevents .burger-bar--2:hover {
  transform: scaleX(1);
}

.burger-bar--3 {
  transform: translateY(6px);
}

#burger.active .burger-button {
  transform: rotate(-180deg);
}

#burger.active .burger-bar--1 {
  transform: rotate(45deg);
}

#burger.active .burger-bar--2 {
  opacity: 0;
}

#burger.active .burger-bar--3 {
  transform: rotate(-45deg);
}

.sidebar-toggle-step {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
</style>

<script>
import useGlobalStore from '@stores/global';
import StorageMixin from '@mixins/storage-mixins';

export default {
  mixins: [StorageMixin],

  data() {
    return {
      store: useGlobalStore(),
    };
  },

  beforeMount() {
    const startingState = this.loadSetting('sidebar-toggle', 'sidebarState');
    if (startingState !== undefined) {
      this.store.setSidebar(startingState);
    }
  },

  computed: {
    isToggleActive() {
      return this.store.isSidebarOpen;
    },

    showToggle() {
      return this.$sidebar.visibility && this.$sidebar.isEnabled(this.$route.name);
    },
  },

  methods: {
    toggleSidebar() {
      this.store.toggleSidebar();
      this.saveSetting('sidebar-toggle', 'sidebarState', this.isToggleActive);
    },
  },
};
</script>
