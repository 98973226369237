/* eslint no-param-reassign: "off" */
import axios from 'axios';
import useGlobalStore from '@stores/global';
import getOrNull from '../modules/get-or-null';
import { uuid } from '../modules/uuid-wrapper';

const WARNING_STATUS_CODES = [
  400,
  401,
  403,
  404,
];

const RETRYABLE_METHODS = ['get', 'head', 'options', 'put'];
const RETRYABLE_STATUS_CODES = [429, 500, 502, 503, 504];
const MAX_RETRY_COUNT = 3;
const RETRY_EXPONENT = 2;

function createCustomAxios() {
  return axios.create({});
}

function delay(seconds) {
  return new Promise(resolve => { setTimeout(resolve, seconds * 1000); });
}

export class KHttp {
  constructor(logger, debug, retryCount) {
    this.customAxios = createCustomAxios();
    this.logger = logger;
    this.createAxiosRequestInterceptor(debug);
    this.store = useGlobalStore();
    this.source = axios.CancelToken.source();
    this.customAxios.defaults.cancelToken = this.source.token;
    this.retryCount = retryCount === undefined ? MAX_RETRY_COUNT : retryCount;
  }

  createAxiosRequestInterceptor(debug) {
    this.customAxios.interceptors.request.use(config => {
      if (!debug) {
        config.params = {
          ...config.params,
          _session_id: this.logger.sessionId,
          _route_id: this.logger.routeId,
          _request_id: uuid(),
        };
      }
      config.khttp = {
        timestamp: new Date(),
        retryCount: getOrNull('khttp.retryCount', config, 0),
      };
      return config;
    });
  }

  cancelAllRequests() {
    this.source.cancel();
    this.source = axios.CancelToken.source();
    this.customAxios.defaults.cancelToken = this.source.token;
  }

  static errStatus(err) {
    return getOrNull('response.status', err);
  }

  static errIn(err, statusCodes) {
    const statusCode = KHttp.errStatus(err);
    return statusCodes.indexOf(statusCode) !== -1;
  }

  isRetryable(err) {
    if (RETRYABLE_METHODS.indexOf(getOrNull('config.method', err)) > -1) {
      if (err.message === 'Network Error' || KHttp.errIn(err, RETRYABLE_STATUS_CODES)) {
        const config = getOrNull('config.khttp', err);
        return config.retryCount < this.retryCount;
      }
    }
    return false;
  }

  static isWarning(err) {
    return err.message === 'Network Error' || axios.isCancel(err) || KHttp.errIn(err, WARNING_STATUS_CODES);
  }

  createAxiosResponseInterceptor() {
    this.customAxios.interceptors.response.use(response => response, error => {
      if (getOrNull('response.data.err', error) === 'maintenance') {
        // maintenance
        this.store.setMaintenance();
      } else if (this.isRetryable(error)) {
        // Attempt retry with exponential backoff
        const { config } = error;
        config.khttp.retryCount += 1;
        return new Promise((resolve, reject) => {
          delay(RETRY_EXPONENT ** config.khttp.retryCount).then(() => {
            this.customAxios(config).then(resolve).catch(reject);
          });
        });
      }
      return Promise.reject(error);
    });
  }
}

export default {
  install(app, { debug, retryCount }) {
    const khttp = new KHttp(app.config.globalProperties.$logger, debug || false, retryCount);
    khttp.createAxiosResponseInterceptor();
    app.config.globalProperties.$httpCancel = () => {
      khttp.cancelAllRequests();
    };
    app.config.globalProperties.$http = {
      get: khttp.customAxios.get,
      post: khttp.customAxios.post,
      put: khttp.customAxios.put,
      delete: khttp.customAxios.delete,
      errIn: KHttp.errIn,
      isWarning: KHttp.isWarning,
      axios: khttp.customAxios,
    };
  },
};
