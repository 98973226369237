<template>
  <div class="nav-tabs-wrapper">
    <ul class="nav nav-tabs">
      <li v-for="tab in tabs" :key="useRouter ?  tab.routeName : tab.key"
        class="tablink-container"
        :class="{ 'active': tabSelected(tab) }"
      >
        <router-link v-if="useRouter"
          :to="{ name: tab.routeName, params: tab.routeParams }"
          class="k-tablink">
          {{ tab.name }}
        </router-link>
        <a v-else @click="$emit('tab-selected', tab.key)">{{ tab.name }}</a>
      </li>
    </ul>
  </div>
</template>

<style scoped>
li.tablink-container a:focus {
  background-color: var(--kate-panel);
  color: var(--kate-type-light) !important;
}
</style>

<script>
export default {
  props: {
    currentTab: {
      type: String,
    },
    tabs: {
      type: Array,
    },
    useRouter: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    tabSelected(tab) {
      return this.useRouter ? this.currentTab === tab.routeName : this.currentTab === tab.key;
    },
  },
};
</script>
