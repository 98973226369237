<template>
  <li v-if="availableProgrammesReady" class="support" :class="{ 'is-open': isOpen }" ref="suppDropdown">
    <button type="button" class="btn-icon support-dropdown-btn" aria-label="Support" title="Support" @click="toggleOpen">
      <i class="fas fa-question-circle"></i>
      <span>Support</span>
      <i class="fas fa-caret-down"></i>
    </button>
    <ul class="dropdown nested-dropdown" :class="{ 'is-open': isOpen }">
      <li v-if="programmeDetails && programmeDetails.knowledge_base_enabled && $route.name !== 'module_knowledge_base'" class="knowledge-base-link">
        <button type="button" v-if="isProgrammePage" @click="openChooseModuleModal">
          Ask technical question
        </button>
        <router-link v-else :to="{ name: 'module_knowledge_base', moduleId }">
          Ask technical question
        </router-link>
      </li>
      <li v-if="programmeDetails && programmeDetails.knowledge_base_enabled" class="knowledge-base-link">
        <router-link :to="{ name: 'profile_questions' }">
          My questions
        </router-link>
      </li>
      <li v-if="hasCoaches && !isDashboard">
        <button type="button" @click="openHelpFor('contact_coach')" :class="{active : helpKey === 'contact_coach'}">
          Contact your Coach
        </button>
      </li>
      <li v-if="hasDataMentors && !isDashboard">
        <button type="button" @click="openHelpFor('contact_data_mentor')" :class="{active : helpKey === 'contact_data_mentor'}">
          Contact your Data Mentor
        </button>
      </li>
      <li v-if="!isDashboard">
        <a href="mailto:help@cambridgespark.com?subject=Contact%20Cambridge%20Spark">
          Contact Cambridge Spark
        </a>
      </li>
      <li>
        <button type="button" @click="openHelpFor('report_bug')" :class="{active : helpKey === 'report_bug'}">
          Report a bug
        </button>
      </li>
      <li v-if="!isDashboard">
        <router-link :to="{ name: 'help' }" target="_blank">Help pages</router-link>
      </li>
      <li v-if="!isDashboard">
        <a href="mailto:safeguarding@cambridgespark.com?subject=Report%20Safeguarding%20Concern">
          Report a Safeguarding Concern
        </a>
      </li>
    </ul>
  </li>
</template>

<style scoped>
.support.is-open .support-dropdown-btn {
  background-color: var(--kate-background-alpha);
  border-radius: 0;
}

.support-dropdown-btn {
  padding: 15px;
}

.support-dropdown-btn span {
  padding: 0 5px;
}

.navbar .support-dropdown ul.dropdown.nested-dropdown {
  width: 200px;
  top: 113px;
  left: 0;
}

.navbar .support-dropdown ul.nested-dropdown.is-open {
  transform: translate(-200px, 0);
}

.navbar .dropdown.nested-dropdown::before {
  display: none;
}

@media (max-width: 767px) {
  .navbar .support-dropdown ul.nested-dropdown {
    position: unset;
    display: none;
  }

  .navbar .support-dropdown ul.nested-dropdown.is-open {
    transform: translate(0, 0);
    display: block;
    width: 100%;
    box-shadow: inset 0 0 5px 3px  rgb(13 12 24 / 0.9) !important;
  }
}
</style>

<script>
import useGlobalStore from '@stores/global';
import ErrorMixin from '@mixins/error-mixins';
import CoachDataMentorMixin from '@mixins/coach-data-mentor-mixin';
import SidebarMixin from '@mixins/sidebar-mixin';
import getOrNull from '../../modules/get-or-null';
import { ASSET_TYPES } from '../../constants';

export default {
  mixins: [ErrorMixin, CoachDataMentorMixin, SidebarMixin],

  props: {
    isOpen: {
      type: Boolean,
    },
  },

  data() {
    return {
      store: useGlobalStore(),
      showHelp: false,
      programmeId: undefined,
      availableProgrammes: [],
      availableProgrammesReady: false,
      helpKey: undefined,
      showChooseModule: false,
      threadName: undefined,
      threadId: undefined,
      moduleAssetName: undefined,
      moduleAssetId: undefined,
      assetId: undefined,
      moduleAssetType: undefined,
      moduleAssetTypePretty: undefined,
    };
  },

  mounted() {
    this.updateProgrammeId();
    this.getAvailableProgrammes();
    document.addEventListener('click', this.handleClickOutside);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  watch: {
    route() {
      // Update info any time the route changes and close the help dropdown (the options may change)
      this.$emit('close');
      this.reset();
      this.updateProgrammeId();
      if (this.availableProgrammes.length < 1) {
        this.getAvailableProgrammes();
      }
    },
    moduleDetails() {
      this.updateProgrammeId();
    },
  },

  computed: {
    isDashboard() {
      return this.store.isDashboard;
    },

    isProgrammePage() {
      return this.$route.matched.some(record => record.path === '/programmes/:programmeId?/:track?');
    },

    route() {
      return this.$route;
    },
    isModulePage() {
      return this.$route.matched[0].path === '/modules/:moduleId';
    },
    programmeDetails() {
      return this.availableProgrammes.filter(x => x.id === this.programmeId)[0];
    },
    programmeName() {
      return getOrNull('name', this.programmeDetails) || getOrNull('programme_name', this.moduleDetails) || undefined;
    },
    moduleId() {
      // Get the module id from the route not the sidebar mixin since this will return the module details of the
      // previous module when not on a module page
      if (!this.$route.params?.moduleId) {
        return undefined;
      }
      return parseInt(this.$route.params?.moduleId, 10);
    },
    moduleName() {
      if (this.moduleId && this.moduleId === this.moduleDetails?.id) {
        return this.moduleDetails?.name;
      }
      return undefined;
    },
    moduleAsset() {
      // We should generalise asset, so instead of using the following we could do this.$route.params.moduleAssetId
      // At the moment moduleAssetId is explicitly modulePakId, moduleQuizId... etc
      const asset = this.assets.find(x => {
        if (x.asset_type) {
          const routeName = ASSET_TYPES[x.asset_type].routeName;
          return x.id === parseInt(this.$route.params[`module${this.pascalCase(routeName)}Id`], 10);
        }
        return false;
      });
      if (!asset) {
        return undefined;
      }
      return asset;
    },
  },

  methods: {
    pascalCase(str) {
      return str.split('_').map(x => x.charAt(0).toUpperCase() + x.slice(1)).join('');
    },
    reset() {
      this.moduleAssetName = undefined;
      this.threadName = undefined;
      this.programmeId = undefined;
      this.assetId = undefined;
      this.moduleAssetId = undefined;
      this.moduleAssetType = undefined;
      this.moduleAssetTypePretty = undefined;
      this.threadId = undefined;
    },
    updateProgrammeId() {
      this.programmeId = undefined;
      if (this.moduleDetails && this.isModulePage) {
        // Have access to data from the sidebar
        this.programmeId = this.moduleDetails.programme_id;
      } else {
        // If on the programme page get the programme ID from the route, otherwise set to undefined
        this.programmeId = this.isProgrammePage ? parseInt(this.$route.params.programmeId, 10) : undefined;
      }
    },
    getAvailableProgrammes() {
      this.availableProgrammesReady = false;
      this.$logger.info('Getting available programmes for navbar support dropdown');
      this.$http.get('/api/curriculum/programmes')
        .then(response => {
          this.availableProgrammes = response.data.programmes;
          this.$logger.info('Got available programmes for navbar support dropdown');
        })
        .catch(err => {
          this.showError(err, true);
        }).then(() => {
          this.availableProgrammesReady = true;
        });
    },
    setAssetInfo() {
      if (this.moduleAsset) {
        this.moduleAssetName = this.moduleAsset.name;
        this.moduleAssetId = this.moduleAsset.id;
        this.moduleAssetType = this.moduleAsset.asset_type;
        this.moduleAssetTypePretty = ASSET_TYPES[this.moduleAssetType].prettyName;
        this.assetId = this.moduleAsset[`${this.moduleAsset.asset_type}_id`];
      }
    },
    setThreadInfo() {
      // The thread crumb is always the last crumb and so doesn't have a path object
      const threadCrumb = this.$crumbs.crumbs?.find(x => (
        (this.$route.name === 'knowledge_thread' && x.active === true)
      ));
      if (threadCrumb) {
        this.threadName = getOrNull('text', threadCrumb);
      }
      if (this.$route.params.threadId) {
        this.threadId = parseInt(this.$route.params.threadId, 10);
      }
    },
    openHelpFor(key) {
      this.setAssetInfo();
      this.setThreadInfo();
      this.helpKey = key;
      this.$emit('open-help-modal', {
        helpKey: this.helpKey,
        moduleName: this.moduleName,
        moduleAssetName: this.moduleAssetName,
        threadName: this.threadName,
        programmeId: this.programmeId,
        moduleId: this.moduleId,
        assetId: this.assetId,
        moduleAssetId: this.moduleAssetId,
        moduleAssetType: this.moduleAssetType,
        moduleAssetTypePretty: this.moduleAssetTypePretty,
        threadId: this.threadId,
      });
      this.$emit('close');
    },

    toggleOpen() {
      if (this.isOpen) {
        this.$emit('close');
      } else {
        this.$emit('open');
      }
    },

    openChooseModuleModal() {
      this.$emit('open-module-modal', {
        programmeId: this.programmeId,
      });
      this.$emit('close');
    },

    handleClickOutside(event) {
      const suppDropdown = this.$refs.suppDropdown;
      if (suppDropdown && !suppDropdown.contains(event.target)) {
        this.$emit('close');
      }
    },
  },
};
</script>
