<template>
  <div id="competencies-track" v-if="programmeId" :class="setModulesLayout ? 'grid-view': 'list-view'">
    <k-panel-competency-card v-for="comp in competencies" :key="comp.id"
      :competency="comp"
      @save-competency="saveCompetency(comp.id, $event)"
    />
  </div>
</template>

<style>
#competencies-track .card-label {
  min-width: 85px;
}

#competencies-track .card-progress-container {
  display: flex;
  flex-direction: column-reverse;
  gap: 15px;
  text-align: right;
}

#competencies-track .panel-card-body {
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
}
</style>

<script>
import TimeMixin from '@mixins/time-mixins';
import KPanelCompetencyCard from './components/k-panel-competency-card.vue';

export default {
  mixins: [TimeMixin],

  components: {
    KPanelCompetencyCard,
  },

  props: {
    programmeId: {
      type: Number,
      required: true,
    },
    competencies: {
      type: Array,
      required: true,
    },
    setModulesLayout: Boolean,
  },

  data() {
    return {
      now: undefined,
    };
  },

  mounted() {
    this.now = new Date();
  },

  methods: {
    saveCompetency(competencyId, score) {
      this.$emit('save-competency', competencyId, score);
    },
  },
};
</script>
