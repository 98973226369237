<template>
  <div v-if="asset">
    <div class="content-with-spacing">
      <div class="heading-container">
        <h1 v-if="asset.name">{{ asset.name }}</h1>
        <module-asset-completion-button :ref="'completionButton'"
          :asset="asset"
          :completionHandler="handleCompletion"
          :completionPayload="completeCalendarEventPayload"
        ></module-asset-completion-button>
      </div>
      <div class="panel panel-default">
        <k-banner>
          <div class="calendar-event-content">
            <h2 v-if="asset.name">{{ asset.name }}</h2>
            <hr>
            <p class="calendar-event-description">{{ asset.description }}</p>
            <div class="calendar-event-details">
              <div class="calendar-event-dates">
                <h3>When</h3>
                <div>
                  <div class="dates-info start-event">
                    From:
                    <template v-if="asset.event_start">
                      <b>{{ parseTimestamp(asset.event_start, undefined, "do MMM yyyy HH:mm") }}</b>
                      <small>({{ Intl.DateTimeFormat().resolvedOptions().timeZone}})</small>
                    </template>
                    <i v-else>To be confirmed</i>
                  </div>
                  <div class="dates-info end-event">
                    Until:
                    <template v-if="asset.event_end">
                      <b>{{ parseTimestamp(asset.event_end, undefined, "do MMM yyyy HH:mm") }}</b>
                      <small>({{ Intl.DateTimeFormat().resolvedOptions().timeZone}})</small>
                    </template>
                    <i v-else>To be confirmed</i>
                  </div>
                </div>
              </div>
              <div class="calendar-event-links">
                <h3>Where</h3>
                <div v-if="hasLiveLink || hasRecordingLinks || hasLocation">
                  <div v-if="hasLiveLink" class="link-info live-event">
                    <span>Live Event:</span>
                    <a v-if="calendarEventEndDate > now"
                      :href="asset.live_event_link"
                      @click="$logger.info('Opening calendar event live link', { assetId }, true)"
                      target="_blank">
                      <b>{{ asset.live_event_link }}</b>
                      <i class="fas fa-external-link-alt"></i>
                    </a>
                    <span v-if="isCalendarEventLive" class="event-live">
                      <span class="live-icon"></span> Live
                    </span>
                    <span v-if="!isCalendarEventLive && calendarEventEndDate < now" class="ended">
                      Event has ended or is no longer available
                    </span>
                  </div>
                  <div v-if="hasLocation" class="link-info location">
                    <span>Location:</span>
                    <div>
                      {{ asset.location }}
                    </div>
                  </div>
                  <div v-if="hasRecordingLinks" class="link-info recorded-event">
                    <span>Recording:</span>
                    <div class="recorded-event-list">
                      <div v-for="recording_link in asset.recording_link">
                        <a :href="recording_link"
                          @click="$logger.info('Opening calendar event recording link', { assetId }, true)"
                          target="_blank">
                          <b>{{ recording_link }}</b>
                          <i class="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="no-link-av">
                  <div class="link-info">
                    <i>No link currently available</i>
                  </div>
                </div>
              </div>
              <div v-if="asset.teaching_team && asset.teaching_team.length" class="calendar-event-teachers">
                <h3>Teaching Team</h3>
                <div v-for="trainer in asset.teaching_team">
                  <div>{{ trainer.trainer_preferred_name || trainer.trainer_name }}</div>
                </div>
              </div>
            </div>
          </div>
        </k-banner>
      </div>
    </div>
    <k-modal v-if="hasMultipleCompletionTypes" role="dialog" @close="closeModal" :show="showModal" class="completion-confirmation-modal">
      <template #header>
        <h3>
          Select method of completion
        </h3>
      </template>
      <template #body>
        <p>Please select below which method you used to view this event:</p>
        <ul class="completion-types">
          <li v-for="(comp, idx) in asset.completion_types" :key="comp">
            <label class="radio">
              <input v-model="selectedCompletionType" :id="idx" name="completion-method" type="radio" :value="comp" checked="">
              <span>{{ completionTypePrettyName(comp) }}</span>
            </label>
          </li>
        </ul>
        <div class="completion-confirmation">
          <label class="k-custom-checkbox">
            <input type="checkbox" id="roles" class="hidden-checkbox" v-model="confirmation">
            <span class="k-custom-checkbox-box light"></span>
            <span class="k-custom-checkbox-text">
              I understand that marking this event as completed indicates that I attended the event as planned or caught up by watching the recording in full.
            </span>
          </label>
        </div>
      </template>
      <template #footer>
        <button class="btn btn-outlined" @click="closeModal">
          Cancel
        </button>
        <button class="btn btn-success" @click="confirmCompletionType" :disabled="!confirmation">
          Confirm
        </button>
      </template>
    </k-modal>
  </div>
</template>

<style scoped>
.heading-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin: 20px auto;
}

.calendar-event-content {
  padding: 40px 15px;
  width: 100%;
  gap: 15px;
}

.calendar-event-content h2 {
  margin-top: 0;
  text-align: center;
}

.calendar-event-content hr {
  margin: 40px 0;
  background-image: var(--right-gradient);
  border-color: transparent;
  height: 1px;
  opacity: 0.5;
}

.calendar-event-content > p {
  text-align: center;
  margin-bottom: 40px;
}

.calendar-event-content h2::first-letter,
.calendar-event-content > p::first-letter {
  text-transform: capitalize;
}

.calendar-event-details {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 15px;
}

.calendar-event-details h3 {
  margin-bottom: 15px;
  font-family: "apercu_monoregular", monospace;
}

.calendar-event-teachers > div,
.calendar-event-dates > div,
.calendar-event-links > div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.dates-info b {
  color: var(--kate-type-light);
}

.dates-info small {
  padding-left: 5px;
}

.link-info,
.link-info a {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.link-info a:hover {
  text-decoration: none;
}

.link-info a:hover b {
  text-decoration: underline;
}

.link-info b {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 15vw;
}

.link-info.recorded-event {
  align-items: flex-start;
}

.link-info.recorded-event .recorded-event-list {
  flex-direction: column;
}

.link-info.location > div {
  max-width: 150px;
}

.link-info.location {
  align-items: flex-start;
}

.completion-types {
  padding: 0;
  margin: 15px 0;
}

.completion-types li {
  list-style: none;
  margin: 10px 0;
}

.completion-types label {
  display: flex;
  align-items: center;
  margin: 0;
  gap: 10px;
  cursor: pointer;
  position: relative;
}

.completion-types label input {
  margin: 0;
}

.k-custom-checkbox-text:hover {
  text-decoration: underline;
}

</style>

<style>
#content .heading-container h1 {
  margin: 0;
}

.completion-confirmation-modal .modal-container {
  min-width: 40vw;
}

.completion-confirmation-modal .modal-body {
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.completion-confirmation-modal .modal-footer {
  display: flex;
  justify-content: space-between;
}

.completion-confirmation-modal .modal-footer::before,
.completion-confirmation-modal .modal-footer::after {
  content: none;
}
</style>

<script>
import ErrorMixin from '@mixins/error-mixins';
import TimeMixin from '@mixins/time-mixins';
import KModal from '@base-components/k-modal.vue';
import KBanner from '../programmes/k-banner.vue';
import ModuleAssetCompletionButton from './module-asset-completion-button.vue';

const COMPLETION_TYPE_MAP = {
  live: 'Live event',
  recorded: 'Recorded video',
};

export default {
  components: {
    KBanner,
    KModal,
    ModuleAssetCompletionButton,
  },

  mixins: [ErrorMixin, TimeMixin],

  props: {
    sidebarReady: {
      type: Boolean,
    },
    asset: {
      type: Object,
    },
  },

  watch: {
    calendarEventCompleted() {
      if (this.calendarEventCompleted) {
        this.confirmation = true;
      } else {
        this.confirmation = false;
      }
    },
  },

  data() {
    return {
      now: undefined,
      showModal: false,
      selectedCompletionType: undefined,
      refresh: undefined,
      confirmation: false,
    };
  },

  beforeMount() {
    this.$Loading.finish();
  },

  mounted() {
    this.now = new Date();
    this.refresh = setInterval(this.updateTime, 1000);
  },

  beforeUnmount() {
    if (this.refresh !== undefined) {
      clearInterval(this.refresh);
    }
  },

  computed: {
    assetId() {
      return this.asset ? this.asset.id : undefined;
    },

    completeCalendarEventPayload() {
      if (this.hasMultipleCompletionTypes) {
        return { completion_type: this.selectedCompletionType };
      }
      return { completion_type: (this.asset?.completion_types || [])[0] };
    },

    calendarEventCompleted() {
      return Boolean(this.asset?.is_complete);
    },

    calendarEventStartDate() {
      return this.getDate(this.asset.event_start);
    },

    calendarEventEndDate() {
      return this.getDate(this.asset.event_end);
    },

    isCalendarEventLive() {
      if (this.calendarEventStartDate <= this.now && this.calendarEventEndDate > this.now) {
        return true;
      }
      return false;
    },

    hasLiveLink() {
      return Boolean(this.asset?.live_event_link);
    },

    hasLocation() {
      return Boolean(this.asset?.location);
    },

    hasRecordingLinks() {
      return Boolean(this.asset?.recording_link?.length);
    },

    hasMultipleCompletionTypes() {
      return Boolean(this.asset?.completion_types?.length > 1);
    },
  },

  methods: {
    updateTime() {
      this.now = new Date();
    },

    openModal() {
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
    },

    confirmCompletionType() {
      this.$refs.completionButton.markComplete();
      this.showModal = false;
    },

    completionTypePrettyName(completionType) {
      return COMPLETION_TYPE_MAP[completionType];
    },

    handleCompletion() {
      if (this.hasMultipleCompletionTypes && !this.asset.is_complete) {
        // reset to default
        this.selectedCompletionType = this.asset.completion_types[0];
        this.openModal();
      } else {
        this.$refs.completionButton.toggleCompletion();
      }
    },
  },
};
</script>
