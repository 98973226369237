/* globals KATE_APP_URL */
/* globals KATE_DASHBOARD_URL */
/* globals KATE_APP_NAME */
/* globals ENVIRONMENT */
/* eslint no-param-reassign: "off" */
import { defineStore } from 'pinia';

function getDefaultState() {
  return {
    currentGroup: undefined,
    username: undefined,
    userId: undefined,
    userFullName: undefined,
    userPreferredName: undefined,
    userCoaches: [],
    userDataMentors: [],
    userMentees: [],
    userPermissions: [],
    appName: KATE_APP_NAME,
    appUrl: KATE_APP_URL,
    dashboardUrl: KATE_DASHBOARD_URL,
    avatarUrl: undefined,
    termsAccepted: false,
    notebookReady: false,
    inMaintenance: false,
    hideReadOnlyNotebookModal: false,
    inScreenshotMode: false,
    screenshotBlob: undefined,
    isSidebarOpen: true,
    sortModulesDescending: false,
    isGridLayout: false,
    environment: ENVIRONMENT,
  };
}

const useGlobalStore = defineStore('global', {
  state: () => getDefaultState(),

  // Identical to getters in vuex
  getters: {
    isApp(state) {
      return state.appName === 'app';
    },
    isDashboard(state) {
      return state.appName === 'dashboard';
    },
    isEmployer(state) {
      return state.userPermissions.includes('view_employer_learners');
    },
    isLoggedIn(state) {
      return Boolean(state.userId);
    },
    sidebarClass(state) {
      return `sidebar-page-content ${state.isSidebarOpen ? 'sidebar-open' : 'sidebar-collapsed'}`;
    },
  },

  // Equivalent to mutations in vuex - but don't need state as an argument for all the action methods
  actions: {
    // TODO: This is not really logging in, this is loading user data into the store...
    logIn(payload) {
      this.username = payload.username;
      this.userId = payload.id;
      this.termsAccepted = Boolean(payload.terms_and_conditions_accepted_on);
      this.userFullName = payload.full_name;
      this.userPreferredName = payload.preferred_name;
      this.userCoaches = payload.coaches;
      this.userDataMentors = payload.data_mentors;
      this.userMentees = payload.mentees;
      this.avatarUrl = payload.avatar_url;
      this.userPermissions = payload.user_permissions;
    },
    logOut() {
      Object.assign(this, getDefaultState());
      localStorage.clear();
    },

    // All the actions below are basically redundant as we can mutate the store state directly
    setMaintenance() {
      this.inMaintenance = true;
    },
    unsetMaintenance() {
      this.inMaintenance = false;
    },
    acceptTerms() {
      this.termsAccepted = true;
    },
    updatePreferredName(payload) {
      this.userPreferredName = payload;
    },
    enterScreenshotMode() {
      this.inScreenshotMode = true;
    },
    exitScreenshotMode() {
      this.inScreenshotMode = false;
    },
    addScreenshotBlob(payload) {
      this.screenshotBlob = payload;
    },
    clearScreenshotBlob() {
      this.screenshotBlob = undefined;
    },
    notebookReadyToggle() {
      this.notebookReady = !this.notebookReady;
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    setSidebar(payload) {
      this.isSidebarOpen = payload;
    },
    toggleModulesSortingOrder() {
      this.sortModulesDescending = !this.sortModulesDescending;
    },
    setModulesSorting(payload) {
      this.sortModulesDescending = payload;
    },
    toggleModulesLayout() {
      this.isGridLayout = !this.isGridLayout;
    },
    setModulesLayout(payload) {
      this.isGridLayout = payload;
    },
  },
});

export default useGlobalStore;
