<template>
  <div>
    <div class="k-heatmap" v-if="domain === 'year'">
      <ul class="k-heatmap-labels" v-if="showDomain">
        <li class="k-heatmap-month" v-for="month in months">{{month}}</li>
      </ul>
      <div class="k-heatmap-week-container">
        <div class="k-heatmap-week">
          <div class="k-week-label" v-for="label in weekLabels">{{label}}</div>
        </div>
        <div class="k-heatmap-week" v-for="week in heatGrid">
          <div :class="cellType(entry)" v-for="entry in week" :title="getHoverText(entry)">
          </div>
        </div>
      </div>
      <br>
      <div align="center" class="k-heatmap-controls">
        <a class="glyphicon glyphicon-menu-left k-clickable" @click="previous" v-if="buttons"></a>
        <span class="k-heatmap-year-label" v-if="showDomain">{{currentYear}}</span>
        <a class="glyphicon glyphicon-menu-right k-clickable" @click="next" v-if="buttons"></a>
      </div>
    </div>

    <div class="k-heatmap" v-if="domain === 'week'">
      <div class="k-heatmap-week-only">
        <div :class="cellType(entry)" v-for="entry in heatGrid" :title="getHoverText(entry)">
      </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.k-heatmap {
  overflow-x: auto;
}

.k-heatmap-controls {
  margin-top: 7pt;
}

.k-heatmap-year-label {
  font-size: 10pt;
  color: var(--kate-type-primary);
}

.k-table-heatmap-week {
  font-size: 0.75em;
  color: var(--kate-type-light);
  letter-spacing: 0.4;
}

.k-table-heatmap .k-heatmap-day.small {
  border: 1px solid var(--kate-heatmap-border);
}

.k-heatmap-week-only {
  display: inline-flex;
}

.k-heatmap-labels {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width: 554pt; /* ((cell width + cell margin) * 53 weeks) + little extra for prettiness */
}

.k-heatmap-month {
  display: inline-block;
}

.k-heatmap-week-container {
  min-width: 800px;
  word-break: keep-all;
}

.k-heatmap-week {
  display: inline-grid;
}

.k-heatmap-day,
.empty {
  height: 9pt;
  width: 9pt;
  margin: 1pt;
}

.k-week-label {
  height: 9pt;
  width: 9pt;
  margin: 1pt;
  font-size: 9pt;
  color: var(--kate-type-primary);
}

.k-heatmap-day.small {
  background-color: var(--kate-background-body);
}

.k-heatmap-day.medium {
  background-color: var(--kate-heatmap-medium);
}

.k-heatmap-day.large {
  background-color: var(--kate-heatmap-large);
}

.k-heatmap-day.huge {
  background-color: var(--kate-heatmap-huge);
}

.k-heatmap-day:hover {
  border: 1px solid var(--kate-background);
}
</style>

<script>
import * as dfs from 'date-fns';
import TimeMixin from '@mixins/time-mixins';
import PluralMixin from '@mixins/plural-mixin';

const defaultThresholds = [3, 5];

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const days = [
  'S',
  'M',
  'T',
  'W',
  'T',
  'F',
  'S',
];

export default {
  mixins: [TimeMixin, PluralMixin],
  props: {
    activities: Object,
    offset: {
      default: 0,
      type: Number,
    },
    domain: {
      default: 'year',
      type: String,
    },
    showDomain: {
      default: false,
      type: Boolean,
    },
    thresholds: {
      default: () => defaultThresholds,
      type: Array,
    },
    buttons: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      refinedActivities: undefined,
      firstDateOfDomain: undefined,
      startDate: undefined,
      heatGrid: undefined,
      today: undefined,
      currentOffset: 0,
    };
  },
  mounted() {
    this.today = new Date();
    if (this.domain === 'year') {
      this.startDate = dfs.startOfYear(this.today);
    } else if (this.domain === 'week') {
      // start week on monday
      this.startDate = dfs.startOfWeek(this.today, { weekStartsOn: 1 });
    }
    this.firstDateOfDomain = this.startDate;
    this.currentOffset = this.offset;
    this.genTable();
  },
  watch: {
    activities() {
      this.genTable();
    },
    offset() {
      this.currentOffset = this.offset;
    },
    currentOffset() {
      this.changeCurrentDate();
      this.genTable();
    },
  },
  computed: {
    weekLabels() {
      return days;
    },
    months() {
      return months;
    },
    dates() {
      return Object.keys(this.activities).sort();
    },
    currentYear() {
      if (this.domain !== 'year' || !this.startDate) {
        return undefined;
      }
      return this.startDate.getFullYear() + this.currentOffset;
    },
  },
  methods: {
    next() {
      this.currentOffset += 1;
    },
    previous() {
      this.currentOffset -= 1;
    },
    changeCurrentDate() {
      const domainKey = `${this.domain}s`;
      const options = {};
      options[domainKey] = this.currentOffset;
      this.firstDateOfDomain = dfs.add(this.startDate, options);
    },
    cellType(entry) {
      if (entry === undefined) {
        return 'empty';
      }
      if (entry.count === 0) {
        return 'k-heatmap-day small';
      }
      if (entry.count <= this.thresholds[0]) {
        return 'k-heatmap-day medium';
      }
      if (entry.count <= this.thresholds[1]) {
        return 'k-heatmap-day large';
      }
      return 'k-heatmap-day huge';
    },
    getHoverText(entry) {
      if (entry === undefined) {
        return '';
      }
      return `${this.pluralize('submission', entry.count, true)} on ${entry.date}`;
    },
    getBlankYearArray(year) {
      // Heatmap grid needs to be shifted
      // Day of the week the year starts and ends in
      // js day starts at sunday
      let start = new Date(`${year}-01-01`);
      const offset = dfs.getDay(start);
      const endOffset = dfs.getDay(dfs.endOfYear(start));
      const output = [[]];
      // First week
      for (let i = 0; i < offset; i++) {
        output[0].push(undefined);
      }
      for (let i = offset; i < 7; i++) {
        output[0].push({
          count: 0,
          date: this.parseTimestamp(start, undefined, 'do MMM yyyy'),
        });
        start = dfs.add(start, { days: 1 });
      }
      // Middle weeks
      // Rounds to 53 weeks regardless of leap year status
      for (let w = 1; w < 52; w++) {
        output.push([]);
        for (let d = 0; d < 7; d++) {
          output[w].push({
            count: 0,
            date: this.parseTimestamp(start, undefined, 'do MMM yyyy'),
          });
          start = dfs.add(start, { days: 1 });
        }
      }
      // last week
      output.push([]);
      for (let i = 0; i < endOffset + 1; i++) {
        output[52].push({
          count: 0,
          date: this.parseTimestamp(start, undefined, 'do MMM yyyy'),
        });
        start = dfs.add(start, { days: 1 });
      }
      // Fill the rest with undefined
      for (let i = output[52].length; i < 7; i++) {
        output[52].push(undefined);
      }
      return output;
    },
    genYearTable() {
      // Get blank 2d array for year
      const heatgrid = this.getBlankYearArray(this.currentYear);
      const yearOffset = dfs.getDay(this.firstDateOfDomain);
      let dt;
      let currentWeek;
      let dayInWeek;
      // Loop through activities
      for (let i = 0; i < this.dates.length; i++) {
        if (this.dates[i].slice(0, 4) == this.currentYear) { // eslint-disable-line eqeqeq
          dt = this.getDtFromTimestamp(this.dates[i]);
          currentWeek = Math.floor((dfs.getDayOfYear(dt) - 1 + yearOffset) / 7);
          dayInWeek = dfs.getDay(dt);
          heatgrid[currentWeek][dayInWeek].count = this.activities[this.dates[i]];
        }
      }
      this.heatGrid = heatgrid;
    },
    genWeekTable() {
      const heatgrid = [];
      // Set to sunday
      let start = new Date(this.firstDateOfDomain);
      for (let i = 0; i < 7; i++) {
        heatgrid.push({
          count: this.activities[dfs.format(start, 'yyyyMMdd')] || 0,
          date: dfs.format(start, 'do MMM yyyy'),
        });
        start = dfs.add(start, { days: 1 });
      }
      this.heatGrid = heatgrid;
    },
    genTable() {
      if (this.domain === 'year') {
        this.genYearTable();
      } else if (this.domain === 'week') {
        this.genWeekTable();
      }
    },
    getDtFromTimestamp(stamp) {
      return this.getDate(stamp, 'yyyyMMdd');
    },
  },
};
</script>
