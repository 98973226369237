<template>
  <section v-if="ready" class="content-with-spacing">
    <empty-placeholder v-if="verificationFailed"
      title="Verification Failed"
      :info="verificationFailedMessage"
      faIcon="fas fa-user-magnifying-glass"
    >
    </empty-placeholder>
    <template v-else>
      <h2><i class="fas fa-lock"></i> Change Password</h2>
      <change-password :token="verificationToken"></change-password>
    </template>
  </section>
</template>

<style scoped>
.content-with-spacing {
  padding-top: 50px;
}
</style>

<script>
import EmptyPlaceholder from '@base-components/empty-placeholder.vue';
import PageReadyMixin from '@mixins/page-ready-mixin';
import ChangePassword from './change-password.vue';

export default {
  mixins: [PageReadyMixin],

  components: {
    ChangePassword,
    EmptyPlaceholder,
  },

  data() {
    return {
      ready: false,
      verificationFailed: false,
    };
  },

  beforeMount() {
    this.verifyToken();
  },

  computed: {
    verificationToken() {
      return this.$route.query?.verify_token;
    },
    verificationFailedMessage() {
      return 'The password change request token is invalid or has expired. Please request a new token.';
    },
  },

  methods: {
    verifyToken() {
      this.$Loading.start();
      this.$logger.info('Verifying password change request token', undefined, true);
      this.$http.post('/api/auth/user/password/change/verify', {
        token: this.verificationToken,
      }).then(() => {
        this.$logger.info('Password change request token verified');
        this.$ktoast.success('Password change request verified. You can now change your password.');
      }).catch(err => {
        this.$logger.autowarn('Password change request token verification failed', undefined, err);
        this.verificationFailed = true;
      }).then(() => {
        this.ready = true;
      });
    },
  },
};
</script>
