<template>
  <div class="k-search-dropdown-menu">
    <div class="k-search-dropdown-field">
      <button type="button"
        class="k-search-dropdown-button btn btn-outlined"
        :class="buttonClass"
        :disabled="disabled"
        aria-label="Toggle menu" title="Toggle menu" @click="toggleMenu"
        @blur="handleClickAwayFromDropdown"
        ref="kdropdown">
        {{selectedOptionName || placeholder}} <i class="fas fa-caret-down"></i>
      </button>
    </div>
    <div class="k-search-dropdown-adv-content" v-show="showMenu">
      <div v-if="showSearch" class="search-input">
        <i class="fa-duotone fa-magnifying-glass input-icon "></i>
        <!-- eslint-disable-next-line label-has-for -->
        <input class="k-search-dropdown-adv"
          id="dropdown-search-input"
          title="search"
          v-model="filterText"
          :disabled="disabled"
          :class="dropdownClass"
          :placeholder="searchPlaceholder || placeholder"
          @focus="handleSearchFocus"
          @blur="handleClickAwayFromSearch"
          ref="kinput">
      </div>
        <button type="button" class="clear-btn btn" v-if="clearOption" @mousedown="clearSelectedOption" title="Clear selection" aria-label="Clear selection">Clear selection</button>
      <ul class="k-search-dropdown-options">
        <li class="k-search-dropdown-label"
          :class="selectedOptionName === option.name ? 'selected-option' : ''"
          v-for="option in filteredOptions"
          :key="option.id">
          <button type="button" class="btn-icon" v-html="option.displayName || option.name || option.id"  @mousedown="selectOption(option)"></button>
          <button type="button" class="clear-icon-btn" v-if="selectedOptionName === option.name && clearOption"
            @mousedown="clearSelectedOption()"
            title="Clear selection"
            aria-label="Clear selection">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.search-input {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.k-search-dropdown-menu {
  position: relative;
}

.k-search-dropdown-options {
  max-height: 20em;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
  list-style: none;
  padding: 0;
}

.btn-outlined {
  width: 100%;
  padding: 12px;
}

.k-search-dropdown-label {
  color: var(--kate-type-light);
  margin-bottom: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.k-search-dropdown-label button {
  padding: 8px;
  width: 100%;
}

.k-search-dropdown-label:hover button {
  text-decoration: underline;
  color: var(--kate-type-accent);
}

.k-search-dropdown-label:only-child {
  margin-bottom: 0;
}

.k-search-dropdown-label:hover {
  background-color: var(--kate-panel-alt);
}

.k-search-dropdown-label.selected-option {
  background-color: var(--kate-panel-alt);
  color: var(--kate-type-accent);
}

.k-search-dropdown-adv {
  width: 100%;
  padding: 8px 10px 8px 30px;
  background-color: var(--input-background);
  border: var(--input-border);
}

.k-search-dropdown-adv:focus {
  outline: var(--input-border-focus);
}

.k-search-dropdown-adv-content {
  position: absolute;
  padding: 5px;
  width: 100%;
  background-color: var(--kate-background-alt);
  border: 1px solid var(--kate-background-alpha);
  z-index: 99;
  box-shadow: var(--box-shadow);
}

.dropdown-slim .k-search-dropdown-field .k-search-dropdown-button {
  padding: 1px;
}

button.clear-btn {
  width: 100%;
  background-color: var(--kate-button-tertiary);
  color: var(--kate-type-light);
  box-shadow: var(--box-shadow);
  margin-bottom: 5px;
}

button.clear-btn:hover {
  background-color: var(--kate-type-primary);
}

.clear-icon-btn {
  background-color: transparent;
  color: var(--kate-danger);
}

.clear-icon-btn:hover {
  background-color: transparent;
  color: var(--kate-danger-light);
}
</style>

<script>
import stringMatch from '../modules/string-match';

export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Select',
    },
    searchPlaceholder: {
      type: String,
      default: 'Search...',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [Number, String, Object, Boolean],
      default: undefined,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    clearOption: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      showMenu: false,
      filterText: '',
    };
  },

  watch: {
    showMenu() {
      // Focus the search bar when opening the menu, if search enabled
      if (this.showMenu) {
        this.$nextTick(() => {
          if (this.showSearch) {
            this.$refs.kinput.focus();
          } else {
            this.$refs.kdropdown.focus();
          }
        });
      }
    },
  },

  computed: {
    buttonClass() {
      return this.type === 'alt' ? 'dropdown-border-alt' : '';
    },
    dropdownClass() {
      if (!this.modelValue || this.showMenu) {
        return '';
      }
      return 'selected';
    },

    refinedOptions() {
      return this.options.map(x => (typeof x === 'object' ? x : { id: x, name: x }));
    },

    selectedOptionName() {
      const item = this.refinedOptions.find(x => x.id === this.modelValue);
      return item ? item.name : undefined;
    },

    filteredOptions() {
      if (!this.filterText) {
        return this.refinedOptions;
      }
      return this.refinedOptions.filter(x => stringMatch(x.name, this.filterText) || stringMatch(x.key, this.filterText));
    },

  },

  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    selectOption(option) {
      this.$emit('update:modelValue', option.id);
      this.showMenu = false;
    },
    clearSelectedOption() {
      this.$emit('update:modelValue', undefined);
      this.showMenu = false;
    },
    handleClickAwayFromDropdown(event) {
      const isClickingSearch = event.relatedTarget && event.relatedTarget === this.$refs.kinput;
      if (!isClickingSearch && this.showMenu) {
        this.showMenu = false;
      }
    },
    handleClickAwayFromSearch(event) {
      const isClickingDropdown = event.relatedTarget && event.relatedTarget === this.$refs.kdropdown;
      if (!isClickingDropdown && this.showMenu) {
        this.showMenu = false;
      }
    },
    handleSearchFocus() {
      if (!this.disabled) {
        this.filterText = '';
      }
    },
  },
};
</script>
