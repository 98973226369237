<template>
  <li class="scored-asset">
    <i class="k-asset-icon" :class="getAssetIcon('questionnaire')"></i>
    <div class="asset-details">
      <router-link :to="{ name: 'module_questionnaire', params: { moduleId: moduleId, moduleQuestionnaireId: questionnaire.id }}" class="router-link">
        {{ questionnaire.name }}
      </router-link>
      <k-asset-tags :asset="questionnaire"></k-asset-tags>
    </div>
    <i v-if="questionnaire.is_complete" class="fas fa-check"></i>
    <div class="overall-score">
      <k-progress shape="circle" size="xsmall" :percentage="progressPercent" ariaLabel="Questionnaire Progress"></k-progress>
    </div>
    <router-link class="view-content btn btn-outlined" :to="{ name: 'module_questionnaire', params: { moduleId: moduleId, moduleQuestionnaireId: questionnaire.id }}">
      View content <i class="fas fa-long-arrow-alt-right"></i>
    </router-link>
  </li>
</template>

<script>
import KProgress from '@base-components/k-progress.vue';
import KAssetTags from '@base-components/k-asset-tags.vue';
import TimeMixin from '@mixins/time-mixins';
import AssetIconMixin from '@mixins/asset-icon-mixin';

export default {
  mixins: [TimeMixin, AssetIconMixin],

  props: {
    moduleId: {
      type: Number,
      required: true,
    },
    questionnaire: {
      type: Object,
      required: true,
    },
  },

  components: {
    KProgress,
    KAssetTags,
  },

  computed: {
    progressPercent() {
      return this.questionnaire.latest_score || 0;
    },
  },
};
</script>
