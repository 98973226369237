<template>
  <k-tooltip class="bottom"
    text="Open this material in the learning environment to review it and write code directly in the browser."
  >
    <button class="kloud-launch-button btn btn-primary"
      aria-label="Open learning environment modal"
      title="Open learning environment modal" @click="$emit('open')">
      <i class="fas fa-cloud"></i> Open Learning Environment
    </button>
  </k-tooltip>
</template>

<script>
import kTooltip from '@base-components/k-tooltip.vue';

export default {
  components: {
    kTooltip,
  },
};
</script>
