<template>
  <li class="nav-group" :class="`${toggled ? 'opened-dropdown': 'closed-dropdown'}`">
    <button class="nav-group-title" aria-label="Toggle dropdown" title="Toggle dropdown" @click="toggled = !toggled">
      <template v-if="defaultChapter">
        <i class="k-asset-icon fas" :class="getDefaultChapterIcon(chapterDetails.name)"></i>
        <span>{{ chapterDetails.name }}</span>
      </template>
      <template v-else>
        <i class="chapter-number">{{ chapterDetails.number }}.</i>
        <div>
          <span v-if="chapterDetails.name">
            {{ chapterDetails.name }}
          </span>
          <span v-else>Chapter</span>
          <k-label v-if="chapterDetails.label" :label="chapterDetails.label"></k-label>
        </div>
      </template>
      <i class="fas fa-angle-down"></i>
    </button>
    <ul>
      <li>
        <k-progress
          v-if="showProgress"
          class="squared"
          shape="bar"
          :percentage="chapterProgress"
          :precision="1"
          :show-bar-text="true"
          :valueMax="chapterAssets.length"
          customText="Completed"
          ariaLabel="Overall Chapter Progress"
          size="small"
        ></k-progress>
      </li>
      <chapter-dropdown-item
        v-for="asset in chapterAssets"
        :key="asset.id"
        :asset="asset"
        :defaultChapterAsset="defaultChapter">
      </chapter-dropdown-item>
    </ul>
  </li>
</template>

<style>
.sidebar-panel li i.chapter-number {
  position: relative;
  font-family: "apercu_monoregular", monospace;
  min-width: 50px;
  text-align: center;
  font-style: normal;
}

.sidebar-panel.sidebar-panel.collapsed li i.chapter-number {
  opacity: 1;
}

.sidebar-panel.collapsed span.label {
  display: none;
}

.sidebar-panel.collapsed:hover span.label {
  display: block;
}

</style>

<style scoped>
.nav-group-title > span {
  display: flex;
  gap: 5px;
}

.nav-group-title span.label {
  margin-top: 5px;
}

.nav-group-title div {
  display: flex;
  flex-direction: column;
  margin-right: auto;
}
</style>

<script>
import ErrorMixin from '@mixins/error-mixins';
import AssetIconMixin from '@mixins/asset-icon-mixin';
import KProgress from '@base-components/k-progress.vue';
import KLabel from '@base-components/k-label.vue';
import ChapterDropdownItem from './chapter-dropdown-item.vue';

export default {
  components: {
    ChapterDropdownItem,
    KProgress,
    KLabel,
  },

  mixins: [ErrorMixin, AssetIconMixin],

  props: {
    defaultChapter: {
      type: Boolean,
      default: false,
    },
    chapterDetails: {
      type: Object,
      required: true,
    },
    chapterAssets: {
      type: Array,
      required: true,
    },
    chapterProgress: {
      type: Number,
    },
  },

  data() {
    return {
      toggled: false,
    };
  },

  computed: {
    isLearningUnits() {
      return this.defaultChapter && this.chapterDetails.name === 'Learning Units';
    },
    isAssignments() {
      return this.defaultChapter && this.chapterDetails.name === 'Assignments';
    },
    showProgress() {
      const hasMandatoryAssets = this.chapterAssets.some(asset => asset.is_mandatory);
      return this.chapterProgress !== null && hasMandatoryAssets;
    },
  },
};
</script>
