<template>
  <div id="module-overview" :class="[store.sidebarClass, hasScroll ? 'has-scroll' : '']">
    <k-assets-nav v-if="isAssetPage" :nextAsset="nextAssetLink" :prevAsset="prevAssetLink"></k-assets-nav>
    <router-view :key="currentPage" v-bind="pageProps"></router-view>
    <k-feedback-form v-if="isAssetPage && pageProps.asset" :asset="pageProps.asset" :moduleId="moduleId"></k-feedback-form>
    <k-chat-box v-if="moduleDetails && $featureFlags.isEnabled('kate-bot', 'module-page') && moduleDetails.chatbot_enabled"
      :moduleId="moduleId"
      :programmeId="moduleDetails.programme_id">
    </k-chat-box>
  </div>
</template>

<style scoped>
.button-container {
  float: right;
}

.overview-step {
  position: absolute;
  left: 0;
  right: 0;
}
</style>

<style>
/* Breadcrumb fitting if breacrumb present, for all except landing page */
.breadcrumb-container + #module-overview #module-video,
.breadcrumb-container + #module-overview #module-thread,
.breadcrumb-container + #module-overview #module-lu,
.breadcrumb-container + #module-overview #project-overview {
  margin-top: 45px;
}

@media (max-width: 768px) {
  /* Breadcrumb fitting if breacrumb present, for all except landing page */
  .breadcrumb-container + #module-overview #module-video,
  .breadcrumb-container + #module-overview #module-thread,
  .breadcrumb-container + #module-overview #module-lu,
  .breadcrumb-container + #module-overview #quiz-page,
  .breadcrumb-container + #module-overview #project-overview {
    margin-top: 0;
  }

  /* Breadcrumb fitting if breacrumb present, for all except landing page */
  #module-overview #module-video .heading-container,
  #module-overview #module-lu .heading-container,
  #module-overview #quiz-page .heading-container,
  #module-overview #project-overview .heading-container {
    margin-top: 10px !important;
  }
}

#module-overview a.btn.btn-outline.prev-btn,
#module-overview a.btn.btn-outline.next-btn {
  color: var(--kate-type-light);
  border: 1px solid;
}

#module-overview a.btn.btn-outline.prev-btn:hover,
#module-overview a.btn.btn-outline.next-btn:hover {
  color: var(--kate-type-dark);
  border: 1px solid var(--kate-button-outlined-hover-border);
  background-color: var(--kate-button-outlined-hover);
}

</style>

<script>
import useGlobalStore from '@stores/global';
import ErrorMixin from '@mixins/error-mixins';
import SidebarMixin from '@mixins/sidebar-mixin';
import KAssetsNav from '@base-components/k-assets-navigation.vue';
import KFeedbackForm from '@base-components/k-feedback-form.vue';
import KChatBox from '@base-components/chatbot/k-chat-box.vue';

const ASSET_ROUTES = [
  'module_quiz',
  'module_video',
  'module_calendar_event',
  'module_markdown_page',
  'module_meeting',
  'module_lu',
  'module_slides',
  'module_questionnaire',
];

export default {

  mixins: [ErrorMixin, SidebarMixin],

  components: {
    KAssetsNav,
    KFeedbackForm,
    KChatBox,
  },

  data() {
    return {
      store: useGlobalStore(),
      updatingToast: undefined,
      hasScroll: false,
    };
  },

  mounted() {
    window.addEventListener('resize', this.checkScroll);
    const contentWrapper = document.getElementById('module-overview');
    if (contentWrapper) {
      const observer = new MutationObserver(() => {
        this.checkScroll();
      });
      observer.observe(contentWrapper, { childList: true, subtree: true });
      this.observer = observer;
    }
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.checkScroll);
  },

  watch: {
    routeQuery() {
      if (this.routeQuery && this.routeQuery.threadDeleted) {
        this.$sidebar.refreshThreads();
      }
    },
    pageProps() {
      if (this.pageProps && this.isAssetPage && this.sidebarReady) {
        if (!this.pageProps.asset) {
          this.$router.push({ name: '404' });
        }
      }
    },
  },

  computed: {
    moduleId() {
      return Number(this.$route.params.moduleId);
    },

    currentPage() {
      if (this.$route.meta.moduleKey) {
        return this.$route.meta.moduleKey(this.$route);
      }
      return this.$route.path;
    },

    isModuleLanding() {
      return this.$route.name === 'module_landing';
    },

    isKnowledgeBase() {
      return ['module_knowledge_base', 'knowledge_thread'].indexOf(this.$route.name) > -1;
    },

    routeQuery() {
      return this.$route.query;
    },

    projectDetails() {
      if (this.$route.params && this.$route.params.modulePakId) {
        return this.paks.find(x => x.id === parseInt(this.$route.params.modulePakId, 10));
      }
      return {};
    },

    learningOutcomes() {
      return this.moduleDetails ? this.moduleDetails.learning_outcomes : [];
    },

    isAssetPage() {
      return ASSET_ROUTES.indexOf(this.$route.name) > -1 || this.$route.name?.startsWith('pak_ov');
    },

    pageProps() {
      if (this.$route.name === 'module_landing') {
        return {
          learningOutcomes: this.learningOutcomes,
        };
      }
      if (this.$route.name === 'module_quiz') {
        const assetId = parseInt(this.$route.params.moduleQuizId, 10);
        return {
          moduleDetails: this.moduleDetails,
          sidebarReady: this.sidebarReady,
          asset: this.quizzes.find(x => x.id === assetId),
        };
      }
      if (this.$route.name === 'module_video') {
        const assetId = parseInt(this.$route.params.moduleVideoId, 10);
        return {
          moduleDetails: this.moduleDetails,
          sidebarReady: this.sidebarReady,
          asset: this.videos.find(x => x.id === assetId),
        };
      }
      if (this.$route.name === 'module_slides') {
        const assetId = parseInt(this.$route.params.moduleSlidesId, 10);
        return {
          moduleDetails: this.moduleDetails,
          sidebarReady: this.sidebarReady,
          asset: this.slides.find(x => x.id === assetId),
        };
      }
      if (this.$route.name === 'module_lu') {
        const assetId = parseInt(this.$route.params.moduleLuId, 10);
        return {
          moduleDetails: this.moduleDetails,
          sidebarReady: this.sidebarReady,
          asset: this.lus.find(x => x.id === assetId),
        };
      }
      if (this.$route.name === 'module_calendar_event') {
        const assetId = parseInt(this.$route.params.moduleCalendarEventId, 10);
        return {
          moduleDetails: this.moduleDetails,
          sidebarReady: this.sidebarReady,
          asset: this.events.find(x => x.id === assetId),
        };
      }
      if (this.$route.name === 'module_meeting') {
        const assetId = parseInt(this.$route.params.moduleMeetingId, 10);
        return {
          moduleDetails: this.moduleDetails,
          sidebarReady: this.sidebarReady,
          asset: this.meetings.find(x => x.id === assetId),
        };
      }
      if (this.$route.name === 'module_markdown_page') {
        const assetId = parseInt(this.$route.params.moduleMarkdownPageId, 10);
        return {
          moduleDetails: this.moduleDetails,
          sidebarReady: this.sidebarReady,
          asset: this.pages.find(x => x.id === assetId),
        };
      }
      if (this.$route.name === 'module_questionnaire') {
        const assetId = parseInt(this.$route.params.moduleQuestionnaireId, 10);
        return {
          moduleDetails: this.moduleDetails,
          sidebarReady: this.sidebarReady,
          asset: this.questionnaires.find(x => x.id === assetId),
        };
      }
      if (this.$route.name === 'module_knowledge_base') {
        return {
          moduleDetails: this.moduleDetails,
          sidebarReady: this.sidebarReady,
        };
      }
      if (this.$route.name && this.$route.name.startsWith('pak_ov_')) {
        const assetId = parseInt(this.$route.params.modulePakId, 10);
        return {
          // ASSET TODO: Not clear that the projectDetails here are different from the asset
          projectDetails: this.projectDetails,
          moduleDetails: this.moduleDetails,
          sidebarReady: this.sidebarReady,
          asset: this.paks.find(x => x.id === assetId),
        };
      }
      return {
        projectDetails: this.projectDetails,
        moduleDetails: this.moduleDetails,
        sidebarReady: this.sidebarReady,
      };
    },

    nextAsset() {
      const obj = this.getCurrentAssetDetailsFromRoute();
      const index = this.assets.findIndex(x => Number(obj.id) === x.id && obj.asset_type === x.asset_type);

      if (index < 0) {
        return undefined;
      }
      return this.assets[index + 1];
    },

    nextAssetLink() {
      if (!this.moduleDetails || !this.nextAsset) {
        return undefined;
      }
      return {
        name: this.nextAsset.name || this.nextAsset.title,
        route: this.getRouteLinkFromAsset(this.nextAsset),
      };
    },

    prevAsset() {
      const obj = this.getCurrentAssetDetailsFromRoute();
      const index = this.assets.findIndex(x => Number(obj.id) === x.id && obj.asset_type === x.asset_type);

      if (index < 0) {
        return undefined;
      }
      return this.assets[index - 1];
    },

    prevAssetLink() {
      if (!this.moduleDetails || !this.prevAsset) {
        return undefined;
      }
      return {
        name: this.prevAsset.name || this.prevAsset.title,
        route: this.getRouteLinkFromAsset(this.prevAsset),
      };
    },
  },

  methods: {
    checkScroll() {
      const contentWrapper = document.getElementById('module-overview');
      const contentHeight = contentWrapper.scrollHeight;
      const windowHeight = window.innerHeight;
      this.hasScroll = contentHeight > windowHeight;
    },

    // ASSET TODO: Refactor this and the relevant routes so
    // they don't need to be hardcoded for each asset type
    getCurrentAssetDetailsFromRoute() {
      if (this.$route.name === 'module_quiz') {
        return {
          asset_type: 'quiz',
          id: this.$route.params.moduleQuizId,
        };
      }
      if (this.$route.name === 'module_video') {
        return {
          asset_type: 'video',
          id: this.$route.params.moduleVideoId,
        };
      }
      if (this.$route.name === 'module_slides') {
        return {
          asset_type: 'slide_deck',
          id: this.$route.params.moduleSlidesId,
        };
      }
      if (this.$route.name === 'module_lu') {
        return {
          asset_type: 'learning_unit',
          id: this.$route.params.moduleLuId,
        };
      }
      if (this.$route.name === 'module_calendar_event') {
        return {
          asset_type: 'calendar_event',
          id: this.$route.params.moduleCalendarEventId,
        };
      }
      if (this.$route.name === 'module_meeting') {
        return {
          asset_type: 'meeting',
          id: this.$route.params.moduleMeetingId,
        };
      }
      if (this.$route.name === 'module_markdown_page') {
        return {
          asset_type: 'markdown_page',
          id: this.$route.params.moduleMarkdownPageId,
        };
      }
      if (this.$route.name === 'module_questionnaire') {
        return {
          asset_type: 'questionnaire',
          id: this.$route.params.moduleQuestionnaireId,
        };
      }
      if (this.$route.name && this.$route.name.startsWith('pak_ov_')) {
        return {
          asset_type: 'pak',
          id: this.$route.params.modulePakId,
        };
      }
      return {};
    },

    getRouteLinkFromAsset(asset) {
      if (asset.asset_type === 'quiz') {
        return {
          name: 'module_quiz',
          params: { moduleId: this.moduleDetails.id, moduleQuizId: asset.id },
        };
      }
      if (asset.asset_type === 'video') {
        return {
          name: 'module_video',
          params: { moduleId: this.moduleDetails.id, moduleVideoId: asset.id },
        };
      }
      if (asset.asset_type === 'slide_deck') {
        return {
          name: 'module_slides',
          params: { moduleId: this.moduleDetails.id, moduleSlidesId: asset.id },
        };
      }
      if (asset.asset_type === 'learning_unit') {
        return {
          name: 'module_lu',
          params: { moduleId: this.moduleDetails.id, moduleLuId: asset.id },
        };
      }
      if (asset.asset_type === 'calendar_event') {
        return {
          name: 'module_calendar_event',
          params: { moduleId: this.moduleDetails.id, moduleCalendarEventId: asset.id },
        };
      }
      if (asset.asset_type === 'meeting') {
        return {
          name: 'module_meeting',
          params: { moduleId: this.moduleDetails.id, moduleMeetingId: asset.id },
        };
      }
      if (asset.asset_type === 'pak') {
        return {
          name: 'pak_ov_instructions',
          params: { moduleId: this.moduleDetails.id, modulePakId: asset.id },
        };
      }
      if (asset.asset_type === 'markdown_page') {
        return {
          name: 'module_markdown_page',
          params: { moduleId: this.moduleDetails.id, moduleMarkdownPageId: asset.id },
        };
      }
      if (asset.asset_type === 'questionnaire') {
        return {
          name: 'module_questionnaire',
          params: { moduleId: this.moduleDetails.id, moduleQuestionnaireId: asset.id },
        };
      }
      return {};
    },
  },
};
</script>
