<template>
  <div>
    <div class="evaluation-title">
      <h2 class="feedback-panel-title">
        <i :class="iconClass"></i> {{title}}
      </h2>
      <div v-if="displayScoringIndicator" class="scoring-indicator">
        <span class="scoring-container">
          <k-tooltip class="success" :text="`<b>${successes}</b>/${total} tests <b>passed</b>`">
            <i class="fas fa-check-circle"></i>
            <span class="success-scoring" :class="{ disabled: successes === 0 }">{{successes}}</span>
          </k-tooltip>
        </span>
        <span class="scoring-container">
          <k-tooltip class="failure" :text="`<b>${failures}</b>/${total} tests <b>failed</b>`">
            <i class="fas fa-exclamation-circle"></i>
            <span class="failure-scoring" :class="{ disabled: failures === 0 }">{{failures}}</span>
          </k-tooltip>
        </span>
      </div>
    </div>
    <div class="feedback-block-filter-title" v-if="filterString">
      <i class="fas fa-filter"></i>
      <span class="filter-string">{{filterString}}</span>
      <button class="btn-icon clear-filter-button" @click="$emit('clear-filter')" title="Clear filter">
        <i class="fas fa-times" title="Clear filter"></i>
      </button>
    </div>
  </div>
</template>

<script>
import KTooltip from '@base-components/k-tooltip.vue';

export default {
  name: 'FeedbackHeader',

  components: {
    KTooltip,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
      required: true,
    },
    displayScoringIndicator: {
      type: Boolean,
      default: true,
    },
    successes: {
      type: Number,
      required: true,
    },
    failures: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    filterString: {
      type: String,
      default: '',
    },
  },

  emits: ['clear-filter'],
};
</script>

<style scoped>
.evaluation-title {
  min-height: unset;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.feedback-panel-title {
  font-size: 1.25em;
}

.scoring-indicator > span {
  position: relative;
  margin: 0 20px;
}

.scoring-indicator span .failure-scoring,
.scoring-indicator span .success-scoring {
  padding: 1px 7px;
  font-size: 12px;
  border-radius: 50px;
  position: absolute;
  left: 12px;
  top: -6px;
  opacity: 1;
}

.failure-scoring {
  background-color: var(--kate-danger);
  color: var(--kate-type-light);
}

.success-scoring {
  background-color: var(--kate-success);
  color: var(--kate-type-dark);
}

.success-scoring.disabled,
.failure-scoring.disabled {
  background-color: var(--kate-disabled);
  color: var(--kate-type-light);
}

.feedback-block-filter-title {
  color: var(--kate-type-primary);
  font-family: monospace;
  font-size: smaller;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}

.feedback-block-filter-title .filter-string {
  padding: 0 10px 0 5px;
}

.clear-filter-button i {
  padding: 2px 3px;
  border-radius: 2px;
  color: var(--kate-type-dark);
  font-size: 11px;
  background-color: var(--kate-disabled);
}

.clear-filter-button:hover i {
  color: var(--kate-type-white);
  background-color: var(--kate-danger);
}
</style>
