<template>
  <div class="panel achievement-award-container" :class="displayClass">
    <div class="achievement-image">
      <img :src="getAchievementImage(achievement)" alt="Achievement award">
    </div>
    <div class="achievement-meta">
      <div>
        <h3>{{ achievement.name }}</h3>
        <p>{{ achievement.description }}</p>
      </div>
      <div>
        <div v-if="hasBeenAwarded" class="achievement-awarded">Awarded: {{ dateOfIssue }}</div>
        <div v-else class="achievement-not-awarded">Not yet achieved</div>
        <div v-if="hasBeenAwarded && enableShareOnLinkedIn">
          <k-tooltip :text="tooltipText">
            <a v-bind:href="achievement.linkedin_url" target="_blank" @click="shareOnLinkedIn" class="linkedin-link">
              <i class="fa-brands fa-linkedin"></i> Add {{ achievementType }}
            </a>
          </k-tooltip>
        </div>
        <div v-else>
          <span v-if="hasBeenAwarded && achievement.shared_on_linked_in">{{ achievementTypePretty }} added to
            <i class="fa-brands fa-linkedin"></i>
          </span>
        </div>
      </div>
      <div v-if="revocable && hasBeenAwarded && achievementType === 'badge' && this.$permissions.hasPermission('revoke_badge')">
        <button class="btn btn-danger revoke-badge-button"
                aria-label="Revoke badge" title="Revoke badge"
                @click="$emit('revoke-achievement', achievement.programme_badge_id, achievementType)">Revoke</button>
      </div>
      <div v-else-if="revocable && hasBeenAwarded && this.$permissions.hasPermission('revoke_programme_certificate')">
        <button aria-label="Revoke" title="Revoke"
                class="btn btn-danger revoke-certificate-button"
                @click="$emit('revoke-achievement', achievement.programme_id, achievementType)">Revoke</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.achievement-award-container,
.achievement-image,
.achievement-meta {
  display: flex;
  gap: 15px;
  align-items: center;
  position: relative;
  justify-content: space-between;
}

.achievement-award-container {
  padding: 5px 20px 5px 5px;
  min-height: 110px;
}

.achievement-image {
  width: 100px;
}

.achievement-image > img {
  width: 100px;
}

.achievement-meta {
  width: calc(100% - 120px);
  display: flex;
}

.achievement-meta div:nth-child(2) {
  display: flex;
  flex-direction: column;
}

.achievement-meta div:nth-child(2) > div {
  align-items: flex-end;
}

.achievement-meta div:nth-child(2) > button {
  background-color: var(--kate-button-primary);
  color: var(--kate-type-light);
}
</style>

<script>
import TimeMixin from '@mixins/time-mixins';
import KTooltip from '@base-components/k-tooltip.vue';
import { GOOGLE_CLOUD_STORAGE } from '../../constants';

export default {
  components: { KTooltip },
  mixins: [TimeMixin],
  props: {
    achievement: {
      type: Object,
      required: true,
    },
    achievementType: {
      type: String,
      default: 'badge',
    },
    enableShareOnLinkedIn: {
      type: Boolean,
      default: false,
    },
    revocable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasBeenAwarded() {
      return this.achievement.has_been_awarded;
    },
    displayClass() {
      return this.hasBeenAwarded ? '' : 'disabled';
    },
    dateOfIssue() {
      if (this.hasBeenAwarded) {
        return this.parseTimestamp(this.achievement.date_issued, undefined, 'MMM d, yyyy');
      }
      return null;
    },
    programmeAchievementId() {
      if (this.achievementType === 'badge') {
        return this.achievement.programme_badge_id;
      }
      return this.achievement.programme_id;
    },
    tooltipText() {
      return `Add ${this.achievementType} to your LinkedIn profile`;
    },
    achievementTypePretty() {
      return this.achievementType === 'badge' ? 'Badge' : 'Certificate';
    },
  },
  methods: {
    getAchievementImage(achievement) {
      return `${GOOGLE_CLOUD_STORAGE}${achievement.resource_link}`;
    },
    shareOnLinkedIn() {
      this.$logger.info(`Sharing ${this.achievementType}s on Linkedin`, { programmeAchievementId: this.programmeAchievementId });
      this.$http.put(`/api/profile/programme/${this.achievementType}/${this.programmeAchievementId}/linkedin`).then(() => {
        this.$logger.info(`Shared ${this.achievementType} on LinkedIn`, { programmeAchievementId: this.programmeAchievementId });
      }).catch(err => {
        this.$logger.autowarn(`Error sharing ${this.achievementType}s on Linkedin`, { programmeAchievementId: this.programmeAchievementId }, err);
      });
    },
  },
};
</script>
