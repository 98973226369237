<template>
  <div>
    <div class="panel panel-default">
      <div class="panel-body">
        <div class="notebook-header">
          <span v-if="notebookFilename">
            <i class="fas fa-book"></i>{{ notebookFilename }}
          </span>
          <div>
            <span v-if="submissionDate">
              Latest Submission:
              {{ parseTimestamp(submissionDate) }}
            </span>
            <button class="btn btn-primary notebook-download-button" v-if="notebookActual" @click="downloadCurrentNotebookAsIpynb(notebookActual, notebookFilename)">
              <i class="fas fa-download"></i> {{ downloadButtonText }}
            </button>
          </div>
        </div>
        <div>
          <div v-if="notebook" class="panel panel-default instruction-panel">
            <template v-if="validFormat">
              This notebook is <b>read-only.</b><br>
              To modify the code you will need to edit your notebook locally and re-submit it.
            </template>
            <template v-else>
              <i class="fas fa-exclamation-triangle"></i>
              The notebook appears to be formatted incorrectly and could not be parsed
            </template>
          </div>
          <ipynb
          :show-cell-numbers="showCellNumbers"
          v-if="notebookActual && validFormat"
          :ipynb="notebookActual"
          @clickevent="toggleReadOnlyModal"></ipynb>
        </div>
      </div>
    </div>

    <k-modal v-bind:show="showReadOnlyModal" @close="toggleReadOnlyModal">
      <template #header>
        <h2>This notebook is read-only.</h2>
      </template>
      <template #body>
        To modify the code you will need to edit your notebook locally and re-submit it.
      </template>
      <template #footer>
        <button class="modal-default-button btn btn-outlined" @click="saveDontShowAgain">
          Don't show again
        </button>
        <button class="modal-default-button btn btn-danger" aria-label="Toggle" title="Toggle" @click="toggleReadOnlyModal">
          Close
        </button>
      </template>
    </k-modal>
  </div>
</template>

<style scoped>
.notebook-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.notebook-header > div > span {
  padding-right: 20px;
}

.notebook-header > span > i {
  padding-right: 8px;
}

</style>

<script>
// import moment from 'moment-timezone';
import ErrorMixin from '@mixins/error-mixins';
import FileDownloadMixin from '@mixins/file-download-mixin';
import NotebookMixin from '@mixins/notebook-mixin';
import KModal from '@base-components/k-modal.vue';
import TimeMixin from '@mixins/time-mixins';
import StorageMixin from '@mixins/storage-mixins';
import NotebookRenderer from './notebook-renderer.vue';

export default {
  components: {
    ipynb: NotebookRenderer,
    'k-modal': KModal,
  },
  mixins: [ErrorMixin, FileDownloadMixin, NotebookMixin, TimeMixin, StorageMixin],
  props: {
    projectMeta: {
      type: Object,
      required: true,
    },
    notebook: {
      type: Object,
    },
    submissionDate: {
      type: String,
    },
    isSolution: {
      type: Boolean,
      default: false,
    },
    isDashboard: {
      type: Boolean,
      default: false,
    },
    studentId: {
      type: Number,
    },
  },
  data() {
    return {
      loading: false,
      downloadedNotebook: undefined,
      branch: 'master',
      readOnlyModalIsVisible: false,
      showCellNumbers: false,
    };
  },
  beforeMount() {
    if (this.isSolution && !this.hasAccessToSolution) {
      this.$logger.warn('Attempted to access unreleased solution - route to 404', { modulePakId: this.modulePakId });
      this.$router.push('/404');
    }
    if (this.notebook) {
      this.$logger.pageReady();
    } else {
      this.$Loading.minimal();
      this.downloadNotebook().then(() => {
        this.$Loading.finish();
        this.$logger.pageReady();
      });
    }
  },

  watch: {
    loading(loading) {
      if (loading) {
        this.$Loading.minimal();
      } else {
        this.$Loading.finish();
      }
    },
  },

  computed: {
    modulePakId() {
      return this.projectMeta ? this.projectMeta.id : undefined;
    },
    notebookCells() {
      return this.notebookActual.cells || [];
    },
    validFormat() {
      if (this.notebookCells.find(x => x.source && !Array.isArray(x.source))) {
        return false;
      }
      return true;
    },
    hasAccessToSolution() {
      if (this.projectMeta) {
        return this.projectMeta.solution_released || this.$permissions.hasPermission('view_solutions_before_release');
      }
      return false;
    },
    notebookActual() {
      return this.notebook || this.currentNotebook;
    },
    showReadOnlyModal() {
      if (this.loadSetting('pak_ov_notebook', 'hideReadOnlyNotebookModal')) {
        return false;
      }
      return this.readOnlyModalIsVisible;
    },
    downloadButtonText() {
      return `Download ${this.isSolution ? 'solution' : 'latest'} notebook`;
    },
  },

  methods: {
    toggleCellNumbers() {
      this.showCellNumbers = !this.showCellNumbers;
    },
    toggleReadOnlyModal() {
      this.readOnlyModalIsVisible = !this.readOnlyModalIsVisible;
    },
    saveDontShowAgain() {
      this.saveSetting('pak_ov_notebook', 'hideReadOnlyNotebookModal', true);
      this.toggleReadOnlyModal();
    },
  },
};
</script>
