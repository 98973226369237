<template>
  <section class="profile-section content-with-spacing">
    <h2><i class="fas fa-at"></i> Email Address</h2>
    <email-settings @emails-ready="ready = true"></email-settings>
  </section>
</template>

<script>
import PageReadyMixin from '@mixins/page-ready-mixin';
import Email from './email-settings.vue';

export default {
  mixins: [PageReadyMixin],

  components: {
    'email-settings': Email,
  },

  beforeMount() {
    this.$Loading.start();
  },

  data() {
    return {
      ready: false,
    };
  },
};
</script>
