/* eslint no-param-reassign: "off" */
/* eslint no-underscore-dangle: "off" */
/* globals FORMBRICKS_ENVIRONMENT_ID */
import formbricks from '@formbricks/js/app'; // Note: have to use 'app' if survey is on a product type 'app'
import useGlobalStore from '@stores/global';

class KSurvey {
  constructor(logger) {
    this._client = formbricks; // Easier to test if formbricks is available through _client
    this.store = useGlobalStore();
    this.logger = logger;
  }

  init() {
    this._client.init({
      environmentId: FORMBRICKS_ENVIRONMENT_ID,
      apiHost: 'https://app.formbricks.com',
      userId: String(this.store.userId), // Identifies user to Formbricks so we know who saw survey / responded
    }).then(res => {
      this.logger.info('Formbricks client initialized successfully', res);
    }).catch(err => {
      this.logger.error('Failed to initialize Formbricks client - forms will not display', undefined, err, true);
    });
  }

  trackAction(key) {
    // Use to track 'Code' actions to trigger surveys - can be more precise than using CSS selectors
    // and allows additional logic to be incorporated
    this._client.track(key);
  }

  endSession() {
    // Can be used to manually end a 'session' in Formbricks - session starts can be used as survey triggers
    this._client.logout();
  }
}

export default {
  install(app) {
    const survey = new KSurvey(app.config.globalProperties.$logger);
    app.config.globalProperties.$survey = survey;
  },
};
