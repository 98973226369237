<template>
  <li :class="{'completed': video.is_complete}">
    <i class="k-asset-icon" :class="getAssetIcon('video')"></i>
    <router-link :to="{ name: 'module_video', params: { moduleId: moduleId, moduleVideoId: video.id }}" class="router-link">
      {{ video.name }}
    </router-link>
    <i v-if="video.is_complete" class="fas fa-check"></i>
    <router-link class="view-content btn btn-outlined" :to="{ name: 'module_video', params: { moduleId: moduleId, moduleVideoId: video.id }}">
      View content <i class="fas fa-long-arrow-alt-right"></i>
    </router-link>
  </li>
</template>

<script>
import AssetIconMixin from '@mixins/asset-icon-mixin';

export default {
  mixins: [AssetIconMixin],
  props: {
    video: {
      type: Object,
      required: true,
    },
    moduleId: {
      type: Number,
      required: true,
    },
  },
};
</script>
