<template>
  <div id="k-panel-competency-card" class="panel card-container flex-this">
    <div class="card-label">
      <span>{{ competency.label || '-' }}</span>
    </div>
    <div class="panel-body panel-card-container row">
      <div class="competency-card-info">
        <k-label :label="competency.category" />
        <p v-if="competency.description">{{ competency.description }}</p>
        <div class="completion-date">
          <span>Last Updated:
            <b v-if="competency.latestScore">{{ parseTimestamp(competency.latestScore)}}, ({{ Intl.DateTimeFormat().resolvedOptions().timeZone}})</b>
            <b v-else>No scores available</b>
          </span>
        </div>
      </div>
      <div class="competency-card-mapping">
        <k-label :class="proficiencyLabelClass" :label="getScoreDescription(getLatestScore(competency.scores).score)" />
      </div>
      <div class="competency-card-container">
        <div class="competency-card-actions">
          <button v-if="competency.scores.length > 1 && !editMode" class="btn btn-outlined" @click="openModal">View History</button>
          <button v-if="!editMode" class="btn btn-primary" @click="editCompetency">Submit New</button>
          <button v-if="editMode" class="btn btn-outlined" @click="cancelEdit">Cancel</button>
          <button v-if="editMode" class="btn btn-success" @click="saveCompetency">Save Changes</button>
        </div>
        <div v-if="editMode" class="edit-mode-container">
          <p>
            Please select a score
            <button class="btn btn-link" @click="openScoringDetails" title="Scoring Details">Scoring Details<i class="fa-duotone fa-arrow-up-right-from-square"></i></button>
          </p>
          <k-number-choice v-model="score" :value-max="9" size="small" :mapping="proficiencyScale" />
        </div>
        <template v-else>
          <k-chart-bar v-if="competency.scores.length > 0" class="chart-bar"
            :yaxisMax="9"
            :seriesOne="[getLatestScore(competency.scores).score]"
            seriesOneTitle="Latest Scores"
            :xaxisData="[]"
            :tickAmount="9"
            :height="100"
            :showYAxisLabels="false"
          />
          <span class="no-scores-available" v-else>No scores available</span>
        </template>
      </div>
    </div>

    <k-modal v-if="showModal" @close="closeModal" :show="showModal">
      <template #header>
        <h2>{{ competency.label }} Score History</h2>
      </template>
      <template #body>
        <k-chart-line
          :yaxisMax="9"
          :xaxisData="competency.scores.map(score => parseTimestamp(score.submitted_at, undefined, 'do MMM yy')).reverse()"
          :seriesOne="competency.scores.map(score => score.score).reverse()"
          :seriesOneTitle="`${competency.label} Scores`"
          :stepSize="2"
          :height="300"
        />
      </template>
    </k-modal>

    <k-modal id="scoring-details-modal" v-if="showScoringDetails" @close="closeScoringDetails" :show="showScoringDetails">
      <template #header>
        <h2>Proficiency Scale (0-9)</h2>
      </template>
      <template #body>
        <ol class="scoring-details-list">
          <li v-for="item in proficiencyScale" :key="item.score">
            <span>{{ item.score }}</span>
            <span>{{ item.label }}</span><br>
            <p>{{ item.description }}</p>
          </li>
        </ol>
      </template>
    </k-modal>
  </div>
</template>

<style>
#k-panel-competency-card .modal-body {
  min-height: 350px;
}

#k-panel-competency-card .chart-bar .apexcharts-inner.apexcharts-graphical {
  transform: translate(10px, 30px);
}

/* Grid view */
#competencies-track.grid-view .panel-card-container.row {
  flex-direction: column;
}

#competencies-track.grid-view .completion-date {
  margin: 0;
  max-width: unset;
}

#competencies-track.grid-view .competency-card-container {
  width: 100%;
  align-items: center;
}

#competencies-track.grid-view .no-scores-available {
  text-align: center;
}

#scoring-details-modal .modal-container {
  max-width: 80vw;
}

#k-panel-competency-card .label.beginner-label {
  background-color: var(--kate-button-secondary);
}

#k-panel-competency-card .label.intermediate-label {
  background-color: var(--kate-button-primary);
}

#k-panel-competency-card .label.confident-label {
  background-color: var(--kate-success);
}

#k-panel-competency-card .label.beginner-label,
#k-panel-competency-card .label.intermediate-label,
#k-panel-competency-card .label.confident-label {
  color: var(--kate-type-dark);
}
</style>

<style scoped>
.no-scores-available {
  color: var(--kate-type-alt-dark);
  font-size: 1.2em;
  display: block;
  margin-top: 15px;
}

.panel-card-container.row {
  gap: 15px;
  align-items: flex-start;
}

.edit-mode-container p,
.competency-card-info,
.competency-card-actions,
.edit-mode-container {
  display: flex;
  gap: 15px;
}

.competency-card-mapping {
  text-align: center;
  min-width: 200px;
}

.edit-mode-container p,
.competency-card-actions {
  justify-content: space-between;
  align-items: center;
}

.competency-card-actions:has(:only-child) {
  justify-content: flex-end;
}

.competency-card-info {
  flex: 1;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.competency-card-container {
  min-width: 340px;
}

.completion-date {
  margin: 0;
}

.edit-mode-container {
  flex-direction: column;
  gap: 5px;
  padding-top: 10px;
}

.edit-mode-container p {
  margin: 0;
}

.edit-mode-container p button i {
  margin-left: 5px;
}

.edit-mode-container p button:focus {
  border: 0;
  outline: 0;
}

.scoring-details-list {
  list-style: none;
  padding: 0;
}

.scoring-details-list li {
  gap: 10px;
  padding: 15px 0;
}

.scoring-details-list li span {
  padding: 0 5px;
  font-family: hk-grotesk-semibold, sans-serif;
}

.scoring-details-list li span:first-child {
  padding-left: 0;
}

.scoring-details-list li p {
  margin: 0;
}

@media screen and (max-width: 920px) {
  .competency-card-info {
    height: unset;
    justify-content: space-between;
  }

  .competency-card-mapping {
    min-width: unset;
  }
}

@media screen and (max-width: 795px) {
  .competency-card-mapping {
    width: 100%;
    text-align: left;
  }

  .competency-card-info p {
    margin: 0;
  }

  .competency-card-container {
    width: 100%;
    min-width: unset;
  }

  .panel-card-container.row {
    gap: 15px;
  }
}
</style>

<script>
import TimeMixin from '@mixins/time-mixins';
import KChartLine from '@base-components/charts/k-chart-line.vue';
import KChartBar from '@base-components/charts/k-chart-bar.vue';
import KNumberChoice from '@base-components/k-number-choice.vue';
import KModal from '@base-components/k-modal.vue';
import KLabel from '@base-components/k-label.vue';

const PROFICIENCY_SCALE = [
  {
    score: 0,
    label: 'No Exposure',
    description: 'Has never encountered the concept, skill, or behaviour. No awareness or experience.',
  },
  {
    score: 1,
    label: 'No Ability',
    description: 'Has been exposed but demonstrates no ability to apply the knowledge, skill, or behaviour.',
  },
  {
    score: 2,
    label: 'Beginner - No Understanding',
    description: 'Very limited exposure. Lacks understanding and cannot apply the knowledge, skill, or behaviour.',
  },
  {
    score: 3,
    label: 'Beginner - With Understanding',
    description: 'Basic awareness or understanding is developing. Attempts to apply knowledge, skills, or behaviours but performance is inconsistent and limited.',
  },
  {
    score: 4,
    label: 'Requires Support',
    description: 'Can apply the knowledge, skill, or behaviour in simple situations but needs regular support or guidance to succeed.',
  },
  {
    score: 5,
    label: 'Intermediate',
    description: 'Demonstrates a working understanding and can apply knowledge, skills, or behaviours with some independence. Occasional errors or gaps may still be present.',
  },
  {
    score: 6,
    label: 'Capable',
    description: 'Applies knowledge, skills, or behaviours effectively in familiar settings. Some support may be needed in more complex or unfamiliar situations.',
  },
  {
    score: 7,
    label: 'Confident',
    description: 'Shows consistent and self-directed use of knowledge, skills, or behaviours in typical situations. Beginning to adapt and perform independently in more challenging contexts.',
  },
  {
    score: 8,
    label: 'Competent',
    description: 'Demonstrates independent, consistent, and effective application across a range of situations. Adapts appropriately and requires little to no support.',
  },
  {
    score: 9,
    label: 'Expert',
    description: 'Mastery level. Can apply knowledge, skills, or behaviours in complex, unfamiliar, or high-stakes situations. Able to coach, model, and innovate in this area.',
  },
];

export default {
  components: {
    KChartLine,
    KChartBar,
    KNumberChoice,
    KModal,
    KLabel,
  },

  mixins: [TimeMixin],

  props: {
    competency: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showModal: false,
      inEditMode: false,
      score: null,
      showScoringDetails: false,
      proficiencyScale: PROFICIENCY_SCALE,
    };
  },

  watch: {
    score(newScore) {
      this.getScoreDescription(newScore);
    },
  },

  computed: {
    editMode() {
      return this.inEditMode;
    },

    proficiencyLabelClass() {
      const latestScore = this.getLatestScore(this.competency.scores);
      if (latestScore.score >= 0 && latestScore.score <= 4) {
        return 'beginner-label';
      }
      if (latestScore.score >= 5 && latestScore.score <= 6) {
        return 'intermediate-label';
      }
      if (latestScore.score >= 7 && latestScore.score <= 9) {
        return 'confident-label';
      }
      return '';
    },
  },

  methods: {
    getScoreDescription(score) {
      if (this.getLatestScore(this.competency.scores)?.score === score) {
        return this.proficiencyScale.find(item => item.score === score)?.label;
      }
      return 'No Scores Available';
    },

    getLatestScore(scores) {
      if (!scores.length) return { score: 0, submitted_at: '' };
      return scores.reduce((latest, score) => {
        if (!latest || score.submitted_at > latest.submitted_at) {
          return score;
        }
        return latest;
      });
    },

    openModal() {
      this.showModal = true;
    },

    openScoringDetails() {
      this.showScoringDetails = true;
    },

    closeModal() {
      this.showModal = false;
    },

    closeScoringDetails() {
      this.showScoringDetails = false;
    },

    editCompetency() {
      this.score = this.competency.scores[0]?.score || 0;
      this.inEditMode = true;
    },

    cancelEdit() {
      this.inEditMode = false;
    },

    saveCompetency() {
      this.$emit('save-competency', this.score);
      this.inEditMode = false;
    },
  },
};
</script>
