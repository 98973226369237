<template>
  <div class="k-banner">
    <div :class="overlay" class="cover-container">
      <div>
        <figure class="cover-image">
          <img v-if="imagePath" :src="imagePath" alt="">
        </figure>
      </div>
    </div>
    <div class="content-container">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>
.k-banner {
  border-radius: inherit;
  position: relative;
}

.content-container {
  position: relative;
  z-index: 1;
}

.cover-container {
  pointer-events: none;
  position: absolute;
  border-radius: inherit;
  left: 0;
  right: 0;
  z-index: 0;
  overflow: hidden;
  height: 100%;
}

.cover-image {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 50%;
  z-index: -3;
  transform: translateY(-50%);
}

.cover-image img {
  min-width: 100%;
  min-height: 100%;
}

.cover-container.cover-overlay,
.cover-container.gradient-overlay {
  background-image: linear-gradient(90deg, var(--kate-background-body) 0%, var(--kate-panel-alt) 100%);
  mask-image: linear-gradient(to top, transparent 10%, var(--kate-background));
  mask-image: linear-gradient(to top, transparent 0%, var(--kate-background));
}

.cover-container.cover-overlay::before,
.cover-container.gradient-overlay::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.cover-container.cover-overlay::before {
  background-color: rgb(0 0 0 / 0.75);
}

.cover-container.gradient-overlay::before {
  background-image: linear-gradient(90deg, rgb(0 0 0 / 0.9) 40%, rgb(0 0 0 / 0) 100%);
}
</style>

<script>
import useThemeStore from '@stores/theme';
import { importImage } from '../modules/dynamic-image-importer';

export default {
  props: {
    coverImage: {
      type: String,
    },
  },

  data: () => ({
    themeStore: useThemeStore(),
  }),

  computed: {
    defaultCoverImage() {
      return this.themeStore.theme === 'legacy' ? 'neural-net.png' : '';
    },
    image() {
      return this.coverImage || this.defaultCoverImage;
    },
    imagePath() {
      if (this.image) {
        try {
          return importImage(`cover-images/${this.image}`);
        } catch (err) {
          this.$logger.error('Error finding cover image', { coverImage: this.image }, err);
          return undefined;
        }
      }
      return undefined;
    },
    overlay() {
      if (this.image === this.defaultCoverImage) {
        return 'gradient-overlay';
      }
      if (this.imagePath) {
        return 'cover-overlay';
      }
      return '';
    },
  },
};
</script>
