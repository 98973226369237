<template>
  <div id="profile-overview" :class="store.sidebarClass">
    <router-view :key="currentPage"></router-view>
  </div>
</template>

<style>
#profile-overview section h2 {
  margin: 20px 0;
  padding: 15px 0;
}

#profile-overview section.user-header h2 {
  padding: 0 0 5px;
  margin: 0;
}

#profile-overview .k-profile-page-hr {
  margin: 30px 0;
  border-top: 1px solid var(--kate-type-primary);
  width: 100%;
}
</style>

<script>
import useGlobalStore from '@stores/global';

export default {
  data() {
    return {
      store: useGlobalStore(),
    };
  },

  computed: {
    currentPage() {
      return this.$route.path;
    },
  },
};
</script>
