import { createApp } from 'vue';
import { createPinia } from 'pinia';
import VueToasted from '@hoppscotch/vue-toasted';
import VCalendar from 'v-calendar';
import DetectBrowser from 'vue-detect-browser';
import VueApexCharts from 'vue3-apexcharts';

// Router and store

// Plugins
import KHttpPlugin from '@plugins/k-http';

// Plugins
import KLoading from '@plugins/k-loading/k-loading';
import KLsCache from '@plugins/k-lscache';
import Krumb from '@plugins/crumbs-registrar';
import KLogger from '@plugins/k-logger';
import Sidebar from '@plugins/sidebar-handler';
import PermissionsHandler from '@plugins/permissions-handler';
import KToast from '@plugins/k-toast';
import KPurify from '@plugins/k-purify';
import Posthog from '@plugins/posthog';
import KFeatureFlags from '@plugins/k-feature-flag';
import KSurvey from '@plugins/k-survey';
import router from './router';

// App
import App from './app.vue';

// Store - pinia
const pinia = createPinia();
const app = createApp(App);
app.use(pinia);
app.use(KLoading);
app.use(KLsCache);
app.use(router);
app.use(Krumb);
app.use(KPurify);
app.use(VCalendar, {});
app.use(DetectBrowser);
app.use(Sidebar);
app.use(KLogger, { router });
app.use(KHttpPlugin, {});
app.use(PermissionsHandler);
app.use(VueToasted, { iconPack: 'fontawesome', router });
app.use(KToast, { VueToasted });
app.use(VueApexCharts);
app.use(Posthog);
app.use(KFeatureFlags);
app.use(KSurvey);

// At this point only the moduleChapterStore and themeStore are available in pinia.use(...)
// as those are the only stores that are not required by any of the plugins. Once a store has
// been instantiated it cannot have plugins added to it
pinia.use(({ store }) => {
  // Give themeStore access to feature flags plugin and logger
  if (store.$id === 'theme') {
    // eslint-disable-next-line no-param-reassign
    store.$featureFlags = app.config.globalProperties.$featureFlags;
    // eslint-disable-next-line no-param-reassign
    store.$logger = app.config.globalProperties.$logger;
  }
  // Give moduleChapterStore access to http and logger
  if (store.$id === 'moduleChapterStore') {
    // eslint-disable-next-line no-param-reassign
    store.$http = app.config.globalProperties.$http;
    // eslint-disable-next-line no-param-reassign
    store.$logger = app.config.globalProperties.$logger;
  }
});

router.afterEach(to => {
  document.title = to.meta?.title || 'KATE';
});

router.isReady().then(() => app.mount('#app-container'));
