<template>
  <div class="error-help-section">
    <button class="error-help-toggle" @click="showErrorHelp = !showErrorHelp">
      Why did your score drop to 0% when previous questions may be correct? <i class="fas" :class="showErrorHelp ? 'fa-angle-up' : 'fa-angle-down'"></i>
    </button>
    <div v-show="showErrorHelp" class="error-help-content">
      <div class="modal-text">
        <p>There are two types of problems that can occur when running code:</p>
        <ul>
          <li><b>Logical problems:</b> your code runs but is providing a wrong answer. This is represented as a "failure" or "issues" on KATE</li>
          <li>
            <b>Errors:</b> this is a Python exception and when raised the code can't continue to run.
          </li>
        </ul>
        <p>
          In this scenario, KATE was unable to evaluate your code because your code raised an <code>Error</code> and is unable to run. <br />
          <br/>
          KATE evaluates a notebook by running all the lines of codes in one go - irrespective of individual exercises.
          If any cell in the notebook raises an Error, then the notebook can not carry on being evaluated and all exercises will appear as an "Error" submission.
          As a result, you will not be able to see if any exercises are correctly implemented and the score will show as 0%.\
          <br/>
          <br/>
          If you need help, try using the KATE Chatbot button below to get an explanation of the error.
          <br/>
          <br/>
          You can also ask questions directly to the KATE Chatbot by clicking the chatbot icon in the bottom right of the screen.
          <br/>
          <br/>
          For further help, please contact your Coach or Mentor using the support menu in the top right of the screen.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorHelpSection',
  data() {
    return {
      showErrorHelp: false,
    };
  },
};
</script>

<style scoped>
.error-help-section {
  margin-top: 20px;
  border-top: 1px solid var(--kate-border-color);
}

.error-help-toggle {
  width: 100%;
  text-align: left;
  padding: 15px 0;
  background: none;
  border: none;
  color: var(--kate-type-primary);
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error-help-toggle:hover {
  color: var(--kate-secondary);
}

.error-help-content {
  padding: 0 15px 15px;
}

.error-help-content h5 {
  color: var(--kate-secondary);
  font-size: 1.1em;
  margin-bottom: 15px;
}

.error-help-content ul {
  padding-left: 20px;
  margin: 10px 0;
}

.error-help-content li {
  margin: 5px 0;
}
</style>
