import { markedHighlight } from 'marked-highlight';
import hljs from 'highlight.js';
import Purify from 'dompurify';
import katex from 'katex';
import { marked, Renderer } from './marked-wrapper';
import replaceAll from './replace-all';

const renderer = new Renderer();
renderer.text = str => (str ? replaceAll(str, '\n', '<br>') : '');

marked.setOptions({
  gfm: true,
  smartypants: true,
  smartLists: true,
  renderer,
});

marked.use(markedHighlight({
  langPrefix: 'hljs language-',
  highlight(code, lang) {
    const language = hljs.getLanguage(lang) ? lang : 'plaintext';
    return hljs.highlight(code, { language }).value;
  },
}));

// IMPORTANT: Safari infamously refuses to support negative look behinds
const MATHMODE_REGEX = /((\${2}(?![\d])[\s\S]+?(?=\${1,2})\${1,2}(?![\d]))|(\${1,2}\d+(?=\${1,2})\${2}))|((\${1}(?![\d])[ \t\f\v\S]+?(?=\${1,2})\${1,2}(?![\d]))|(\${1,2}\d+(?=\${1,2})\${1}))/gm;

const HIDDEN_INNER_PLACEHOLDER = 'HIDDEN_INNER_PLACEHOLDER';
const NOTE_PLACEHOLDER = 'NOTE_PLACEHOLDER';
const MATHS_PLACEHOLDER = 'MATHS_PLACEHOLDER';

function renderMaths(str) {
  // Reverse the string so we can use look-aheads in place of look-behinds. Only replace unescaped $ characters
  const sanitisedString = str.split('').reverse().join('').replace(/\$(?!(\\))/g, '')
    .split('')
    .reverse()
    .join('');
  try {
    return katex.renderToString(sanitisedString);
  } catch (err) {
    // Some things can only be rendered in display mode
    if (err.name === 'ParseError' && err.message && err.message.includes('can be used only in display mode')) {
      return katex.renderToString(sanitisedString, { displayMode: true, throwOnError: false });
    }
    // Render it anyway but if there's an error it will not throw an error, just render it in red
    return katex.renderToString(sanitisedString, { throwOnError: false });
  }
}

function processContent(source, placeholder, regex, contentProcessor) {
  if (!source) {
    return { finalSource: source, processedContent: [] };
  }

  let processedIndex = 0;
  const processedContent = [];
  const finalSource = source.replace(regex, (match, ...groups) => {
    const placeholderWithIndex = `${placeholder}_${processedIndex++}`;
    const { renderedContent, placeholderContent } = contentProcessor(match, groups, placeholderWithIndex);
    processedContent.push(renderedContent);
    return placeholderContent;
  });

  return { finalSource, processedContent };
}

function processMathContent(source) {
  const { finalSource, processedContent } = processContent(
    source,
    MATHS_PLACEHOLDER,
    MATHMODE_REGEX,
    (match, groups, placeholder) => {
      const renderedContent = renderMaths(match);
      const placeholderContent = `${placeholder}`;
      return { renderedContent, placeholderContent };
    },
  );

  return { sourceWithMathPlaceholders: finalSource, mathContent: processedContent };
}

function processHiddenContent(source) {
  const { finalSource, processedContent } = processContent(
    source,
    HIDDEN_INNER_PLACEHOLDER,
    /<div class="hidden hidden-inner" id="([^"]*)">([\s\S]*?)<\/div>/g,
    (match, groups, placeholder) => {
      const renderedContent = marked(groups[1]);
      const placeholderContent = `<div class="hidden hidden-inner" id="${groups[0]}">${placeholder}</div>`;
      return { renderedContent, placeholderContent }; // Keep original ID
    },
  );
  return { sourceWithHiddenPlaceholders: finalSource, hiddenContent: processedContent };
}

function processNoteContent(source) {
  const { finalSource, processedContent } = processContent(
    source,
    NOTE_PLACEHOLDER,
    /<div class="kate-note">([\s\S]*?)<\/div>/g,
    (match, groups, placeholder) => {
      const renderedContent = marked(groups[0]);
      const placeholderContent = `<div class="kate-note">${placeholder}</div>`;
      return { renderedContent, placeholderContent };
    },
  );
  return { sourceWithNotePlaceholders: finalSource, noteContent: processedContent };
}

function replacePlaceholders(source, contentArray, placeholder) {
  if (!source) {
    return source;
  }
  return contentArray.reduce((currentSource, content, index) => currentSource.replace(`${placeholder}_${index}`, content), source);
}

export function renderMarkdown(source) {
  if (!source) {
    return '';
  }

  // Process deepest nested content first, currently maths inside notes, hidden content etc.
  const { sourceWithMathPlaceholders, mathContent } = processMathContent(source);
  const { sourceWithNotePlaceholders, noteContent } = processNoteContent(sourceWithMathPlaceholders);
  const { sourceWithHiddenPlaceholders, hiddenContent } = processHiddenContent(sourceWithNotePlaceholders);

  let outputString = marked(sourceWithHiddenPlaceholders || '');

  // Replace placeholders with actual content in reverse order of processing to ensure deepest levels processed correctly
  outputString = replacePlaceholders(outputString, hiddenContent, HIDDEN_INNER_PLACEHOLDER);
  outputString = replacePlaceholders(outputString, noteContent, NOTE_PLACEHOLDER);
  outputString = replacePlaceholders(outputString, mathContent, MATHS_PLACEHOLDER);

  return Purify.sanitize(outputString);
}

export const markdown = source => renderMarkdown(source); // eslint-disable-line import/prefer-default-export
