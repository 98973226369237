<template>
  <div class="milestones-cards-container" :class="setModulesLayout ? 'grid-view': 'list-view'">
    <k-panel-card
      v-for="mod in orderedModules"
      :key="mod.name"
      :item="mod">
    </k-panel-card>
  </div>
</template>

<script>
import KPanelCard from './components/k-panel-card.vue';

export default {
  components: {
    KPanelCard,
  },
  props: {
    programmeId: {
      type: Number,
      required: true,
    },
    orderedModules: {
      type: Array,
      required: true,
    },
    setModulesLayout: Boolean,
  },
};
</script>
