<template>
  <div>
    <form enctype="multipart/form-data" novalidate ref="dropform">
      <div class="k-dropbox">
        <input id="dropfield-input" ref="dropforminput" type="file" name="file" :disabled="isSaving || isSuccess || disableDropfield" @change="filesChange($event.target.files)"
              :accept="accept" class="k-input-file">
        <div class="file-preview" v-if="enablePreviews && fileData && isImage">
          <figure>
            <img :src="getImageSource()" alt="Image preview">
            <figcaption>{{ fileData[0].name }}</figcaption>
          </figure>
        </div>
        <label for="dropfield-input" v-html="boxMessage"></label>
      </div>
      <div class="k-dropbox-legacy-upload">
        <button class="btn btn-outlined"
          title="Upload file"
          aria-label="Upload file"
          @click.prevent="openUploadDiag"
          :disabled="disableDropfield">
          <i class="fas fa-upload"></i> Browse
        </button>
      </div>
    </form>
  </div>
</template>

<style scoped>
.k-dropbox-legacy-upload {
  margin: 15px 0;
}

.k-dropbox {
  margin-top: 3px;
  outline: 2px dashed var(--kate-type-primary);
  color: var(--kate-type-primary);
  min-height: 200px;
  position: relative;
  cursor: pointer;
}

.k-input-file {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.k-input-file[disabled] {
  cursor: not-allowed;
}

.k-dropbox:hover {
  background-color: var(--kate-background-body-alpha);
  transition: all 0.3s ease;
}

.k-dropbox:hover label {
  color: var(--kate-type-alt-dark);
  transition: all 0.3s ease;
}

.k-dropbox label {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
}

.k-dropbox:hover .file-preview {
  filter: opacity(0.5);
}

.file-preview + label {
  display: none;
}

.file-preview figure {
  text-align: center;
}

.file-preview img {
  max-width: 100%;
  max-height: 350px;
}

.file-preview figcaption {
  padding-top: 5px;
  text-align: center;
  color: var(--kate-type-light);
}

</style>

<script>
import ErrorMixin from '@mixins/error-mixins';

const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;
const STATUS_FILE_SELECTED = 4;

export default {
  mixins: [ErrorMixin],
  props: {
    toast: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: 'file',
    },
    url: String,
    accept: String,
    immediatePost: {
      type: Boolean,
      default: true,
    },
    customMessage: {
      type: String,
      default: 'Click to upload or drag file here',
    },
    disableDropfield: {
      type: Boolean,
      default: false,
    },
    enablePreviews: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      selectedFile: null,
      fileData: null,
    };
  },
  computed: {
    errorMessage() {
      if (!this.uploadError) {
        return 'Error';
      }
      return this.uploadError.data;
    },
    boxMessage() {
      switch (this.currentStatus) {
        case STATUS_SAVING:
          return 'Upload in progress... please wait';
        case STATUS_SUCCESS:
          return 'Success';
        case STATUS_FAILED:
          return this.$kpurify.sanitise(this.errorMessage);
        case STATUS_FILE_SELECTED:
          return this.selectedFile;
        default:
          return this.customMessage;
      }
    },
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    isFileSelected() {
      return this.currentStatus === STATUS_FILE_SELECTED;
    },
    isImage() {
      if (this.fileData[0].type) {
        return this.fileData[0].type.slice(0, 5) === 'image';
      }
      return false;
    },
  },
  methods: {
    getImageSource() {
      return URL.createObjectURL(this.fileData[0]);
    },

    openUploadDiag() {
      this.$refs.dropforminput.click();
    },
    validFile(file) {
      if (!this.accept) {
        return true;
      }
      const acceptedTypes = this.accept.split(',');
      return (file.type.match(acceptedTypes.join('|'))
        || acceptedTypes.indexOf(`.${file.name.split('.').pop()}`) !== -1);
    },
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
      this.$refs.dropform.reset();
    },
    toastit(stat, message) {
      if (!this.toast) {
        return;
      }
      const mess = `${stat} ${message || ''}`;
      this.$ktoast.success(mess);
    },
    save(formData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING;
      this.$logger.info('Uploading file', { url: this.url }, true);

      this.$http.post(this.url, formData)
        .then(() => {
          this.$logger.info('Uploaded file', { url: this.url }, true);
          this.$emit('uploadsuccess');
          this.$parent.$emit('uploadsuccess');
          this.currentStatus = STATUS_SUCCESS;
          this.toastit('Upload Successful');
        })
        .catch(err => {
          this.$logger.error('Error uploading file', { url: this.url }, err);
          this.uploadError = err.response;
          this.currentStatus = STATUS_FAILED;
          this.$emit('uploadfailure', err);
          this.toastit('Upload Failed:', err.response.data);
        });
    },
    filesChange(file) {
      if (!this.validFile(file[0])) {
        this.showError('Invalid file format');
        return;
      }
      this.$logger.info('Staged file for upload', { filename: file[0].name });
      this.selectedFile = file[0].name;
      if (this.immediatePost) {
        const formData = new FormData();
        formData.append(this.name, file[0], file[0].name);
        this.upload(formData);
      } else {
        this.fileData = file;
        this.currentStatus = STATUS_FILE_SELECTED;
        this.$emit('file', file);
      }
    },
    formSetup(file) {
      const formData = new FormData();
      formData.append(this.name, file[0], file[0].name);
      this.selectedFile = file[0].name;
      return formData;
    },
    uploadFile() {
      if (this.fileData !== null) {
        const form = this.formSetup(this.fileData);
        this.upload(form);
      }
    },
    upload(formData) {
      this.save(formData);
    },
  },
  mounted() {
    this.reset();
  },
};
</script>
