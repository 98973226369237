<template>
  <li :class="{'completed': learningUnit.is_complete}">
    <i class="k-asset-icon" :class="getAssetIcon('learning_unit')"></i>
    <router-link :to="{ name: 'module_lu', params: { moduleId: moduleId, moduleLuId: learningUnit.id }}">
      {{ learningUnit.name }}
    </router-link>
    <i v-if="learningUnit.is_complete" class="fas fa-check"></i>
    <router-link class="view-content btn btn-outlined" :to="{ name: 'module_lu', params: { moduleId: moduleId, moduleLuId: learningUnit.id }}">
      View content <i class="fas fa-long-arrow-alt-right"></i>
    </router-link>
    <div class="download-button">
      <k-tooltip text="Download learning unit">
        <a type="button" :href="luDownloadUrl" class="btn btn-primary" aria-label="Download learning unit" title="Download learning unit">
          <i class="fas fa-cloud-download-alt"></i>
        </a>
      </k-tooltip>
    </div>

  </li>
</template>

<script>
import AssetIconMixin from '@mixins/asset-icon-mixin';
import KTooltip from '@base-components/k-tooltip.vue';

export default {
  mixins: [AssetIconMixin],
  components: {
    'k-tooltip': KTooltip,
  },

  props: {
    learningUnit: {
      type: Object,
      required: true,
    },
    moduleId: {
      type: Number,
      required: true,
    },
  },

  computed: {
    luDownloadUrl() {
      return `/api/curriculum/learning_unit/${this.learningUnit.id}/zip`;
    },
  },
};
</script>
