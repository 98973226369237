<template>
  <div class="k-table-container">
    <div class="k-table-panel" :class="{'panel panel-default': panel}">
      <div class="table-btn-container" :class="{ 'hidden' : !hideable }">
        <button class="btn btn-primary toggle-filters-btn"
          aria-label="Toggle columns"
          title="Toggle columns"
          @click="toggleSidemenu"
          v-if="hideable">Columns <i class="fas fa-columns-3"></i>
        </button>
        <button id="download-button"
          v-if="downloadable"
          type="button"
          class="btn btn-outlined"
          aria-label="Download .csv"
          @click="downloadCsv"> Download (.csv) <i class="fas fa-download"></i></button>
      </div>
      <slot name="header"></slot>
      <div class="k-table-filters-container" v-show="showSidemenu" v-if="hideable">
        <ul v-if="colFilterOptions.length > 0" class="k-table-special-filters">
          <div>Special filters</div>
          <li v-for="(colFilter, index) in colFilterOptions" :key="colFilter.text">
            <label class="k-custom-checkbox">
              <input type="checkbox" :value="index" v-model="activeColFilters" class="hidden-checkbox"/>
              <span class="k-custom-checkbox-box"></span>
              <span class="k-custom-checkbox-text">{{ colFilter.text }}</span>
            </label>
          </li>
        </ul>
        <div v-if="colFilterOptions.length > 0" class="dropdown-divider"><hr></div>
        <ul>
          <li v-for="(h, k) in hideableHeaders">
            <label class="k-custom-checkbox">
              <input type="checkbox" :value="k" v-model="rawShownKeys" class="hidden-checkbox"/>
              <span class="k-custom-checkbox-box"></span>
              <span class="k-custom-checkbox-text">{{h.name}}</span>
            </label>
          </li>
        </ul>
      </div>
      <div class="k-table-legend" v-if="showLegend">
        <span><i class="fas fa-square optional"></i> Optional</span>
      </div>
      <div :class="{'panel-body': panel}" class="k-table-main-container">
        <table class="table table-hoverable">
          <thead>
            <tr v-if="superHeaders">
              <th v-for="key in superHeaderKeysIterator"
                class="k-table-header super-header"
                scope="col"
                :key="key"
                :colspan="getSuperHeaderColSpan(superHeaders[key])"
              >
                {{superHeaders[key].name}}
              </th>
            </tr>
            <tr>
              <th v-for="k in columnOrder"
                class="k-table-header"
                scope="col"
                :class="`k-table-header-${k}`"
                :key="k">
                <div class="k-table-header-content">
                  <template v-if="niceHeaders[k].hasMultiSelectDropdown">
                    <k-dropdown-multi-select class="dropdown dark"
                        :clearOption="true"
                        :options="uniqueDropdownOptions[k]"
                        :placeholder="niceHeaders[k].name"
                        v-model="dropdownFilters[k]"
                    >
                    </k-dropdown-multi-select>
                  </template>
                  <template v-else-if="niceHeaders[k].hasDropdown">
                      <k-dropdown class="dropdown dark"
                        :showSearch="false"
                        :options="uniqueDropdownOptions[k]"
                        :placeholder="niceHeaders[k].name"
                        v-model="dropdownFilters[k]"
                      >
                      </k-dropdown>
                  </template>
                  <template v-else-if="niceHeaders[k].trim">
                    <k-tooltip
                      :text="`${niceHeaders[k].name} ${getHeaderClasses(niceHeaders[k]) != '' ? '-' : ''} ${getHeaderClasses(niceHeaders[k])}`">
                      <div :class="getHeaderClasses(niceHeaders[k])">
                        <span class="k-table-header-text" v-html="niceHeaders[k].displayName || niceHeaders[k].name"></span>
                      </div>
                    </k-tooltip>
                  </template>
                  <template v-else>
                    <span class="k-table-header-text" v-html="niceHeaders[k].displayName || niceHeaders[k].name"></span>
                  </template>
                  <k-tooltip v-if="niceHeaders[k].options.tooltip" :text="niceHeaders[k].options.tooltipText" text-align="large-tooltip">
                      <i class="fas fa-question-circle header-tooltip-icon"></i>
                  </k-tooltip>
                  <div class="k-table-header-button-block">
                    <template v-if="niceHeaders[k].filterable">
                      <div class="table-search">
                        <label for="table-search-input">
                          <input id="table-search-input" type="text" class="form-control search-input" v-model="searchFilters[k]">
                        </label>
                        <button type="submit" aria-label="Submit" title="Submit">
                          <span class="fa fa-search"></span>
                        </button>
                      </div>
                    </template>
                    <template v-if="niceHeaders[k].sortable">
                      <span class="k-table-button">
                        <button class="btn btn-primary btn-sm" :class="`k-table-sort-${k}`" @click="sortCol(k)" aria-label="Sort" title="Sort">
                          <span class="fas fa-sort"></span>
                        </button>
                      </span>
                    </template>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in paginatedRows" :class="{'active-row': row.index === currentRow} && classFunc(row)" class="k-table-row" @click="emitRow(row.index)">
              <template v-for="k in columnOrder">
                <td v-if="niceHeaders[k].type=='date'"
                    class="k-table-date"
                    :class="`k-table-key-${k}`">{{parseTimestamp(row[k], dateMask, dateFormat)}}
                </td>
                <td v-else-if="niceHeaders[k].type=='timestamp'"
                    class="k-table-timestamp"
                    :class="`k-table-key-${k}`">{{parseTimestamp(row[k], timestampMask, timestampFormat)}}
                </td>
                <td v-else-if="niceHeaders[k].type=='rag_status'"
                    class="k-table-string"
                    :class="[`k-table-key-${k}`, getRagStatusRowClasses(niceHeaders[k], row[k])]" v-html="row[k]">
                </td>
                <td v-else-if="niceHeaders[k].type=='percentage'"
                    class="k-table-percentage"
                    :class="[`k-table-key-${k}`, getRowClasses(niceHeaders[k], row[k])]">
                  {{formatNumber(row[k], niceHeaders[k].options.format || '0.00%')}}
                </td>
                <td v-else-if="niceHeaders[k].type=='number'"
                    class="k-table-number"
                    :class="[`k-table-key-${k}`, getRowClasses(niceHeaders[k], row[k])]">
                    {{formatNumber(row[k], niceHeaders[k].options.format)}}
                </td>
                <td v-else-if="niceHeaders[k].type=='int'"
                    class="k-table-number"
                    :class="[`k-table-key-${k}`, getRowClasses(niceHeaders[k], row[k])]">
                    {{formatNumber(row[k], '0')}}
                </td>
                <td v-else-if="niceHeaders[k].type=='url' && row[k]"
                    class="k-table-url"
                    :class="`k-table-key-${k}`">
                  <router-link :to="row[k].path" :tag="row[k].tag">
                    <div v-html="row[k].text"></div>
                  </router-link>
                </td>
                <td v-else-if="niceHeaders[k].type=='function'"
                    class="k-table-function"
                    :class="`k-table-key-${k}`">
                  <div v-html="row[k]"></div>
                </td>
                <td v-else-if="niceHeaders[k].type=='action'"
                    class="k-table-action clickable"
                    @click="emitAction(row[k].key, row.index, row, k)"
                    :class="`k-table-key-${k}`">
                  <div v-html="row[k].text"></div>
                </td>
                <td v-else-if="niceHeaders[k].type=='tags'"
                    class="k-table-tags"
                    :class="`k-table-key-${k}`">
                  <span v-for="t in row[k].tags" class="badge">{{t}}</span>
                </td>
                <td v-else-if="niceHeaders[k].type=='boolean'"
                    class="k-table-boolean"
                    :class="`k-table-key-${k}`">
                  <div :key="row[k]" v-if="row[k]" :class="`k-table-key-${k}-true`" v-html="niceHeaders[k].options.true || 'True'"></div>
                  <div v-else :key="row[k]" :class="`k-table-key-${k}-false`" v-html="niceHeaders[k].options.false || 'False'"></div>
                </td>
                <td v-else class="k-table-string" :class="`k-table-key-${k}`" v-html="row[k]"></td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination v-if="numOfPages" v-model="currentPage" :count="numOfPages" :total-entries="rows.length" :displayed-entries="paginatedRows.length"></pagination>
    </div>

  </div>
</template>

<style>
.large-tooltip {
  min-width: 250px !important;
  margin-left: 20px;
}

/* || Table search input */
.table-search {
  padding: 5px 5px 5px 7px;
  border-radius: 5px;
  display: flex;
  margin-left: 10px;
  border: var(--input-border-alt);
}

.table-search input.form-control.search-input {
  margin: 0;
  border: 0;
  font-size: inherit;
  transition: width 0.2s;
  padding: 0 4px 0 0;
  width: 15px;
  height: 1.2em;
  box-shadow: none;
  border-radius: 0;
  border-bottom: var(--input-border-alt);
  background-color: transparent;
}

.table-search input.form-control:focus {
  width: 150px;
  transition: width 0.2s;
}

.table-search button[type="submit"] {
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
}

.table-search span {
  margin-left: 0;
  border: none;
  font-size: 1em;
  padding-top: 2px;
  padding-bottom: 2px;
}
</style>

<style scoped>
.k-table-main-container {
  position: inherit;
  overflow-x: auto;
  overflow-y: hidden;
}

.k-table-action:hover {
  background-color: var(--kate-background-alt-alpha);
}

.k-table-url {
  white-space: nowrap;
}

.table-btn-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 15px 0;
}

.table-btn-container.hidden:empty {
  display: none;
}

.k-table-legend {
  display: flex;
  gap: 15px;
}

/* >> Filters */
.k-table-filters-container {
  position: absolute;
  top: 65px;
  background-color: var(--kate-background-alt);
  border-radius: 5px;
  z-index: 3;
  box-shadow: var(--box-shadow);
}

.k-table-filters-container ul li label {
  padding: 5px;
}

.dropdown-divider hr {
  margin: 0;
}

.k-table-special-filters > div {
  font-size: 0.89em;
  padding: 0 15px 5px;
}

.k-table-filters-container ul {
  padding: 15px 0;
  margin: 0;
  list-style: none;
}

.k-table-filters-container ul li,
.k-table-filters-container label {
  cursor: pointer;
}

.k-table-filters-container ul li {
  padding: 4px 15px;
}

.k-table-filters-container ul li:hover {
  background-color: var(--kate-background-body-alpha);
}

.k-table-filters-container label {
  color: var(--kate-type-light);
  margin-bottom: 0;
}

.k-table-filters-container ul li:hover label {
  color: var(--kate-type-primary);
}

/* >> End of form control */
.k-table-container thead tr,
.k-table-button button,
.k-table-header .prev-domain,
.k-table-header .next-domain {
  color: var(--kate-type-light);
}

.k-table-header .prev-domain,
.k-table-header .next-domain {
  padding: 0 5px;
}

.k-table-header .prev-domain:hover,
.k-table-header .next-domain:hover {
  color: var(--kate-secondary);
}

.k-table-container {
  position: relative;
}

.k-table-container .k-table-panel {
  padding-bottom: 30px;
}

.k-table-container th {
  white-space: nowrap;
  min-width: 150px;
  vertical-align: middle !important;
}

.k-table-container input {
  display: inline-block;
  width: auto;
}

.k-table-container thead tr {
  background-color: var(--kate-background-body);
}

.k-table-container tbody tr:nth-child(2n) {
  background-color: var(--kate-panel-alt);
}

.k-table-button button {
  background-color: transparent;
  border: 0;
  padding: 12px;
}

.k-table-header {
  text-transform: uppercase;
}

.k-table-header small {
  text-transform: capitalize;
}

.k-table-header.super-header {
  text-align: center;
  padding: 8px 24px;
}

.k-table-header-content {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.k-table-header-text {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  text-transform: capitalize;
  font-size: 1.1em;
  font-weight: bold;
}

.k-success {
  background-color: var(--kate-table-success-bg);
  color: var(--kate-table-success-fg);
}

.k-warning {
  background-color: var(--kate-table-warning-bg);
  color: var(--kate-table-warning-fg);
}

.k-poor-score {
  background-color: var(--kate-table-poor-score-bg);
  color: var(--kate-type-light);
}

.header-tooltip-icon {
  padding-left: 5px;
  color: var(--kate-type-blue);
}

.filler-row {
  height: 2em;
  background-color: pink;
}

.filler-row > th:first-child {
  border-radius: 15px 0 0;
}

.filler-row > th:last-child {
  border-radius: 0 15px 0 0;
}

.k-table-header-content .dropdown {
  display: inline-block;
  min-width: 200px;
  font-size: 11px;
  text-transform: none;
}

</style>

<script>
import format from 'number-format.js';
import TimeMixins from '@mixins/time-mixins';
import CsvMixins from '@mixins/csv-mixins';
import getOrNull from '../modules/get-or-null';
import stringMatch from '../modules/string-match';
import Pagination from './k-pagination.vue';
import KTooltip from './k-tooltip.vue';
import KDropdown from './k-dropdown.vue';
import KDropdownMultiSelect from './k-dropdown-multi-select.vue';

const HEADER_TEMPLATE = {
  name: '',
  displayName: undefined,
  type: 'string',
  filterable: false,
  sortable: false,
  trim: false,
  hideable: true,
  hasMultiSelectDropdown: false,
  hasDropdown: false,
  options: {},
};

export default {
  props: {
    rows: Array,
    headers: Object,
    superHeaders: {
      type: Object,
      default: undefined,
    },
    subHeaderSortFunction: {
      type: Function,
    },
    downloadable: Boolean,
    filename: String,
    hideable: {
      type: Boolean,
      default: true,
    },
    showLegend: {
      type: Boolean,
      default: false,
    },
    panel: {
      type: Boolean,
      default: true,
    },
    max: Number,
    orderBy: {
      type: String,
      note: 'key from header',
    },
    headerOrder: Array,
    dateMask: {
      type: String,
      default: undefined,
    },
    dateFormat: {
      type: String,
      default: undefined,
    },
    timestampMask: {
      type: String,
      default: undefined,
    },
    timestampFormat: {
      type: String,
      default: undefined,
    },
    classFunc: {
      type: Function,
      default: () => '',
    },
    colFilterOptions: {
      type: Array,
      default: () => [],
    },
    defaultHeaders: {
      type: Array,
    },
  },
  mixins: [TimeMixins, CsvMixins],
  components: {
    pagination: Pagination,
    KTooltip,
    KDropdown,
    KDropdownMultiSelect,
  },

  data() {
    return {
      currentPage: 0,
      searchFilters: {},
      currentSortKey: null,
      sortAscending: true,
      pageOffset: 0,
      showSidemenu: false,
      currentRow: undefined,
      activeColFilters: [],
      rawShownKeys: [],
      dropdownFilters: {},
    };
  },
  beforeMount() {
    this.resetRawShownKeys();
  },

  mounted() {
    if (this.defaultHeaders) {
      this.rawShownKeys = this.defaultHeaders;
    }
  },

  watch: {
    numOfPages() {
      this.currentPage = 0;
    },
    headers() {
      this.resetRawShownKeys();
    },

    defaultHeaders() {
      this.rawShownKeys = this.defaultHeaders;
    },
  },

  computed: {
    rawHeaderKeys() {
      return Object.keys(this.headers);
    },

    shownKeys() {
      const output = [];
      for (let h = 0; h < this.rawShownKeys.length; h++) {
        for (let i = 0; i < this.activeColFilters.length; i++) {
          if (!this.colFilterOptions[this.activeColFilters[i]].func(this.fullHeaders[this.rawShownKeys[h]])) {
            output.push(this.rawShownKeys[h]);
          }
        }
      }
      return this.rawShownKeys.filter(x => output.indexOf(x) === -1);
    },

    headerKeys() {
      return Object.keys(this.niceHeaders);
    },

    superHeaderKeys() {
      if (!this.superHeaders) {
        return undefined;
      }
      return Object.keys(this.superHeaders).sort();
    },

    superHeaderKeysIterator() {
      return this.superHeaderKeys.filter(key => this.getSuperHeaderColSpan(this.superHeaders[key]) > 0);
    },

    niceHeadersKeys() {
      if (this.hasSuperHeaders) {
        let output = [];
        for (let j = 0; j < this.superHeaderKeys.length; j++) {
          const k = this.superHeaderKeys[j];
          const subHeaderKeys = this.rawHeaderKeys.filter(x => this.superHeaders[k].subHeaderKeys.includes(x));
          const subHeaders = [];
          for (let i = 0; i < subHeaderKeys.length; i++) {
            const newSubHeader = this.headers[subHeaderKeys[i]];
            newSubHeader.headerKey = subHeaderKeys[i];
            subHeaders.push(newSubHeader);
          }
          output = output.concat(subHeaders.sort(this.subHeaderSortFunction).map(x => x.headerKey));
        }
        return output;
      }
      return this.headerOrder || this.rawHeaderKeys;
    },

    uniqueDropdownOptions() {
      const options = {};
      this.headerKeys.forEach(key => {
        if (this.niceHeaders[key].hasMultiSelectDropdown || this.niceHeaders[key].hasDropdown) {
          const values = this.rows.map(row => row[key]).filter(value => value !== null && value !== undefined);
          options[key] = [...new Set(values)];
        }
      });
      return options;
    },

    indexedRows() {
      return this.rows.map((val, index) => Object.assign(val, {
        index,
      }));
    },

    numOfPages() {
      if (!this.max || this.niceRows.length <= this.max) {
        return undefined;
      }
      return Math.ceil(this.niceRows.length / this.max);
    },

    paginatedRows() {
      if (!this.numOfPages) {
        return this.niceRows;
      }
      const output = [];
      const startingIndex = this.max * this.currentPage;
      const endingIndex = Math.min(this.max + startingIndex, this.niceRows.length);
      for (let i = startingIndex; i < endingIndex; i++) {
        output.push(this.niceRows[i]);
      }
      return output;
    },

    niceRows() {
      const output = [];
      let key;
      let filtered;
      let valToSort;
      let valToAnalyse;
      let currentRow;
      for (let i = 0; i < this.indexedRows.length; i++) { // Loop through the given rows array
        filtered = false;
        currentRow = this.indexedRows[i];
        for (let k = 0; k < this.headerKeys.length; k++) { // Loop through the columns
          key = this.headerKeys[k];
          if (this.niceHeaders[key].type === 'function') { // If the header is a function, call it
            valToAnalyse = this.niceHeaders[key].options.callback(currentRow, i, this.niceHeaders);
            currentRow[key] = valToAnalyse; // evaluate the function and get the return
          } else {
            valToAnalyse = currentRow[key];
          }
          if (this.dropdownFilters[key]) { // check if dropdown is selected or not
            if (!this.dropdownFilters[key].includes(valToAnalyse)) {
              filtered = true;
              break; // Ignore the whole row
            }
          }
          if (this.searchFilters[key]) { // Is there a filter active?
            valToSort = this.getSortableTest(valToAnalyse);
            if (!stringMatch(valToSort, this.searchFilters[key])) {
              filtered = true;
              break; // The entire row is shit, move onto next row, forget other columns
            }
          }
        }
        if (!filtered) {
          output.push(currentRow); // Not filtered? Add it to the output
        }
      }
      // Dont sort it if no one pressed a button
      if (!this.currentSortKey) {
        return output;
      }
      // Switching between ascending or descending
      // Sort the output
      return output.sort(this.sortingFunction);
    },

    fullHeaders() {
      const output = {};
      const keys = this.niceHeadersKeys;
      for (let i = 0; i < keys.length; i++) {
        output[keys[i]] = this.getHeader(this.headers[keys[i]]);
      }
      return output;
    },

    hideableHeaders() {
      const output = {};
      const keys = Object.keys(this.fullHeaders);
      for (let i = 0; i < keys.length; i++) {
        if (this.fullHeaders[keys[i]].hideable) {
          output[keys[i]] = this.fullHeaders[keys[i]];
        }
      }
      return output;
    },

    columnOrder() {
      return this.niceHeadersKeys.filter(x => this.shownKeys.indexOf(x) !== -1);
    },

    niceHeaders() {
      const output = {};
      const keys = this.columnOrder;
      for (let i = 0; i < keys.length; i++) {
        if (this.shownKeys.indexOf(keys[i]) !== -1) {
          output[keys[i]] = this.fullHeaders[keys[i]];
        }
      }
      return output;
    },

    sortMultiplier() {
      return this.sortAscending ? 1 : -1;
    },

    hasSuperHeaders() {
      return this.superHeaders !== undefined;
    },
  },

  methods: {
    resetRawShownKeys() {
      this.rawShownKeys = Object.keys(this.headers);
      this.activeColFilters = [];
      if (this.rawHeaderKeys.indexOf(this.orderBy) !== -1) {
        this.currentSortKey = this.orderBy;
      }
    },

    toggleSidemenu() {
      this.showSidemenu = !this.showSidemenu;
    },

    emitAction(key, index, row, header) {
      this.$emit('clicked', key, index, row, header);
    },

    emitRow(index) {
      this.currentRow = index;
      this.$emit('rowclicked', index);
    },

    nextPage() {
      this.pageOffset += 1;
    },

    prevPage() {
      this.pageOffset -= 1;
    },

    downloadCsv() {
      if (!this.downloadable) {
        return;
      }
      this.downloadCsvFromTable(this.niceHeaders, this.niceRows, this.filename);
    },

    getSortableTest(obj) {
      let output = obj;
      if (typeof obj === 'object' && obj !== null) {
        if ('sortBy' in obj) {
          output = obj.sortBy;
        } else {
          output = obj.text;
        }
      }
      return typeof output === 'string' ? output.toLowerCase() : output;
    },

    sortingFunction(a, b) {
      const aObj = a[this.currentSortKey];
      const bObj = b[this.currentSortKey];
      const A = this.getSortableTest(aObj);
      const B = this.getSortableTest(bObj);
      // Put entries will nulls at the bottom regardless of sort order
      if (A == null) {
        return 1;
      }
      if (B == null) {
        return -1;
      }
      if (A < B) {
        return -1 * this.sortMultiplier;
      }
      if (A > B) {
        return 1 * this.sortMultiplier;
      }
      return 0;
    },

    formatNumber(num, fmt) {
      if (num === null || num === undefined) {
        return '';
      }
      return format(fmt || '0.00', num);
    },

    getRowClasses(header, cell) {
      const output = [];
      if (header.options.color) {
        if (cell >= 70) {
          output.push('k-success');
        } else if (cell > 30) {
          output.push('k-warning');
        } else if (cell >= 0 && cell != null) {
          output.push('k-poor-score');
        }
      }
      return output.join(' ');
    },

    getRagStatusRowClasses(header, cell) {
      const output = [];
      if (header.options.color) {
        if (cell === 'Green') {
          output.push('k-success');
        } else if (cell === 'Amber') {
          output.push('k-warning');
        } else if (cell === 'Red' && cell != null) {
          output.push('k-poor-score');
        }
      }
      return output.join(' ');
    },

    getHeaderClasses(header) {
      return getOrNull('options.classes', header) || [];
    },

    sortCol(key) {
      if (this.currentSortKey === key) {
        this.sortAscending = !this.sortAscending;
      } else {
        this.sortAscending = true;
        this.currentSortKey = key;
      }
    },

    getHeader(header) {
      const headerOptions = Object.keys(HEADER_TEMPLATE);
      const output = {};
      for (let i = 0; i < headerOptions.length; i++) {
        if (headerOptions[i] in header) {
          output[headerOptions[i]] = header[headerOptions[i]];
        } else {
          output[headerOptions[i]] = HEADER_TEMPLATE[headerOptions[i]];
        }
      }
      return output;
    },

    getSuperHeaderColSpan(superHeader) {
      return superHeader.subHeaderKeys.filter(x => this.shownKeys.includes(x)).length;
    },
  },
};
</script>
