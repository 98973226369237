<template>
  <div class="vote-container">
    <k-tooltip :text="tooltipText">
      <button @click="vote" class="upvote-btn" :class="isUpvoted ? 'upvoted' : 'no-votes'">
          <i class="fas fa-caret-up"></i>
      </button>
      <span><b>{{ thread.votes }}</b><br />Votes</span>
    </k-tooltip>
  </div>
</template>

<style>
.vote-container .tooltip {
  text-align: center;
}
</style>

<style scoped>
.vote-container {
  background-color: var(--kate-background-body-alpha);
  padding: 5px;
  border-radius: 4px;
  gap: 5px;
  display: flex;
  text-align: center;
  flex-direction: column;
  min-width: 70px;
}

.vote-container button {
  font-size: 2.3em;
  background-color: transparent;
  width: 100%;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.vote-container button.upvoted {
  color: var(--kate-success);
}

.vote-container button.no-votes {
  color: var(--kate-type-alt-dark);
}

.vote-container button.upvote-btn:hover {
  color: var(--kate-success-alt);
  background-color: var(--kate-background-alpha);
}

</style>

<script>
import KTooltip from '@base-components/k-tooltip.vue';

export default {
  components: {
    KTooltip,
  },

  props: {
    thread: {
      type: Object,
      required: true,
    },
    userVotes: {
      type: Array,
    },
  },

  data() {
    return {
      updatingUserVotes: false,
    };
  },

  watch: {
    userVotes() {
      if (this.updatingUserVotes) {
        this.updatingUserVotes = false;
      }
    },
  },

  computed: {
    isUpvoted() {
      if (this.userVotes.length > 0) {
        const thread = this.userVotes.find(x => x.id === this.thread.id);
        return thread ? thread.votes === 1 : false;
      }
      return false;
    },

    tooltipText() {
      if (!this.isUpvoted && this.thread.root_parent_id === null) {
        return 'Upvote question';
      }
      if (!this.isUpvoted && this.thread.root_parent_id !== null) {
        return 'Upvote Answer';
      }
      return 'Undo vote';
    },
  },

  methods: {
    vote() {
      if (!this.updatingUserVotes) {
        this.updatingUserVotes = true;
        let endpoint;

        if (this.isUpvoted) {
          this.$emit('unvote');
          endpoint = `/api/curriculum/knowledge/threads/${this.thread.id}/unvote`;
        } else {
          this.$emit('upvote');
          endpoint = `/api/curriculum/knowledge/threads/${this.thread.id}/upvote`;
        }

        this.$http.put(endpoint).then(() => {
          this.$logger.info('Voting on thread', { threadId: this.thread.id }, true);
          this.$emit('voted');
        }).catch(err => {
          this.$logger.error('Error voting on thread', { threadId: this.thread.id }, err);
          this.showError(err);
        });
      }
    },

  },
};
</script>
