<template>
  <div id="chart-bar" class="chart-container">
    <apexchart :height="`${height}px`" type="bar" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<style>
  #chart-bar .apexcharts-legend:empty {
    display: none;
  }
</style>

<script>
export default {
  props: {
    yaxisMax: Number,
    xaxisMax: Number,
    xaxisData: Array,
    tickAmount: Number,
    seriesOneTitle: String,
    seriesOne: {
      type: [String, Number, Array],
      default: '',
    },
    showYAxisLabels: {
      type: Boolean,
      default: true,
    },
    height: {
      type: Number,
      default: 150,
    },
  },

  data() {
    return {
      series: [
        {
          name: this.seriesOneTitle,
          data: this.seriesOne,
        },
      ],
      chartOptions: {
        chart: {
          height: this.height,
          type: 'bar',
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
        },
        dropShadow: {
          enabled: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
          curve: 'smooth',
        },
        colors: ['#47d7ff', '#4b4cc6'],
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 5,
            borderRadiusApplication: 'end', // "around" / "end"
            barHeight: '40%',
          },
        },
        noData: {
          text: 'No data available',
          align: 'center',
          verticalAlign: 'middle',
          style: {
            fontSize: '20px',
            color: 'rgba(255, 255, 255, .5)',
          },
        },
        legend: {
          show: false,
        },
        grid: {
          borderColor: 'var(--kate-background-alpha)',
          xaxis: {
            lines: {
              show: true,
              borderType: 'dotted',
            },
          },
          yaxis: {
            lines: {
              show: true,
              borderType: 'dotted',
            },
          },
        },
        responsive: [{
          breakpoint: 1000,
          options: {},
        }],
        xaxis: {
          tickAmount: this.tickAmount,
          categories: this.xaxisData,
          showForNullSeries: false,
          max: this.yaxisMax,
          axisBorder: {
            show: true,
            color: 'var(--kate-background-alpha)',
          },
          labels: {
            show: true,
            style: {
              colors: 'var(--kate-type-alt-dark)',
              fontSize: '12px',
            },
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: 'var(--kate-background-alpha)',
          },
          crosshairs: {
            show: true,
            stroke: {
              color: 'var(--kate-background-alpha)',
              width: 1,
            },
          },
        },
        yaxis: {
          min: 0,
          max: this.yaxisMax,
          showForNullSeries: false,
          tickAmount: this.yaxisMax <= 5 ? this.yaxisMax : 5,
          labels: {
            show: this.showYAxisLabels,
            style: {
              colors: 'var(--kate-type-alt-dark)',
            },
          },
        },
        fill: {
          type: 'solid',
          colors: ['#47d7ff'],
          opacity: 1,
        },
        // Markers on the lines
        markers: {
          size: 4,
          strokeColors: '#0e0d1a',
          strokeWidth: 1,
          colors: ['#008ffb'],
          strokeOpacity: 0.9,
          shape: 'circle',
          radius: 2,
          hover: {
            sizeOffset: 2,
          },
        },
        tooltip: {
          theme: 'dark',
          enabledOnSeries: false,
          fillSeriesColor: false,
          marker: {
            show: false,
          },
          style: {
            fontSize: '12px',
          },
          y: {
            formatter: val => val,
          },
        },
      },
    };
  },

  watch: {
    seriesOne() {
      this.updateSeriesData();
    },
  },

  methods: {
    updateSeriesData() {
      this.series = [
        {
          name: this.seriesOneTitle,
          data: this.seriesOne,
        },
      ];
    },
  },
};
</script>
