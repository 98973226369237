<template>
  <div id="user-not-in-programme-container">
    <div class="message-box">
      <p>You do not appear to be a member of any programme on KATE, please
        <a href="mailto:help@cambridgespark.com?subject=Contact%20Cambridge%20Spark">
          Contact Cambridge Spark.
        </a>
      </p>
    </div>
  </div>
</template>

<style scoped>
#user-not-in-programme-container {
  font-family: hk-grotesk-light, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 232px);
}

.message-box p {
  font-size: large;
  max-width: 1000px;
}
</style>

<script>
export default {
  mounted() {
    this.$Loading.finish();
    this.$logger.pageReady();
  },
};
</script>
