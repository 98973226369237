<template>
  <div class="public-keys-notice content-with-spacing">
    <div class="panel panel-default">
      <div class="panel-body">
        <h2>EDUKATE.AI's ssh fingerprints</h2>
        <table v-if="keys !== undefined" class="ssh-table">
          <tr>
            <th></th>
            <th>MD5</th>
            <th>SHA256</th>
          </tr>
          <tr v-for="(obj, key) in keys">
            <td>{{key}}</td>
            <td>{{obj.md5}}</td>
            <td>{{obj.sha256}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>
.public-keys-notice .panel {
  background-color: var(--input-background);
  overflow-x: auto;
}

.public-keys-notice h2 {
  margin-bottom: 1em;
}

.ssh-table {
  border-collapse: collapse;
  width: 100%;
}

.ssh-table td,
.ssh-table th {
  border: 1px solid var(--kate-mono-0);
  padding: 1em;
  color: var(--kate-type-light);
  font-family: "apercu_monoregular", monospace;
}

.ssh-table td {
  text-transform: uppercase;
}

</style>

<script>
import ErrorMixin from '@mixins/error-mixins';

export default {
  mixins: [ErrorMixin],
  data() {
    return {
      keys: undefined,
    };
  },
  beforeMount() {
    this.getKeys();
  },
  methods: {
    getKeys() {
      this.$http.get('/api/profile/meta/ssh_fingerprints').then(res => {
        this.keys = res.data;
      }).catch(err => {
        this.showError(err, true);
      }).then(() => {
        this.$logger.pageReady();
        this.$Loading.finish();
      });
    },
  },
};
</script>
