<template>
  <k-modal v-if="programmeModulesReady" :show="show" @close="close">
    <template #header>
      <h2>Select Module</h2>
    </template>
    <template #body>
      <ul class="module-list">
        <li v-for="(mod, index) in orderedModules" :key="mod.name">
          <router-link :to="modulePageTo(index)">{{ mod.name }}</router-link>
        </li>
      </ul>
    </template>
    <template #footer>
      <button @click="close" class="btn btn-danger">Cancel</button>
    </template>
  </k-modal>
</template>

<style>
.module-list li a.router-link-active {
  background-color: var(--kate-background-body);
  text-decoration: underline;
}

.module-list li a:hover {
  text-decoration: underline;
}
</style>

<style scoped>
ul.module-list {
  padding: 0;
  list-style: none;
}

ul.module-list li {
  border-bottom: 1px solid rgb(0 38 47 / 0.4);
}

ul.module-list li:last-child {
  border-bottom: 0;
}

.module-list li a {
  display: block;
  padding: 10px;
}
</style>

<script>
import KModal from '@base-components/k-modal.vue';
import ErrorMixin from '@mixins/error-mixins';
import { genericSort } from '../../modules/sort-by-object-property';

export default {
  mixins: [ErrorMixin],
  components: {
    'k-modal': KModal,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    programmeId: {
      type: Number,
      default: undefined,
    },
  },

  data() {
    return {
      programmeModulesReady: false,
      programmeModules: [],
    };
  },

  beforeMount() {
    this.getProgrammeModules();
  },

  watch: {
    $route() {
      this.$emit('close');
    },
  },

  computed: {
    orderedModules() {
      return this.programmeModules.slice().sort(this.sortModuleContent);
    },
  },

  methods: {
    getProgrammeModules() {
      if (!this.programmeId) {
        return;
      }
      this.$logger.info('Getting modules for programme', {
        programmeId: this.programmeId,
      }, true);
      this.$http.get(`/api/curriculum/programmes/${this.programmeId}/modules`)
        .then(response => {
          this.programmeModules = response.data.modules;
          this.programmeModulesReady = true;
          this.$logger.info('Got modules for programme', {
            programmeId: this.programmeId,
          });
        }).catch(err => {
          this.$logger.autowarn('Error while getting modules for programme', {
            programmeId: this.programmeId,
          }, err);
          this.showError(err);
        });
    },

    modulePageTo(index) {
      const moduleRouteId = this.orderedModules[index].id;
      return {
        path: `/modules/${moduleRouteId}/`,
        name: 'module_knowledge_base',
        params: { moduleId: moduleRouteId },
      };
    },

    close() {
      this.$emit('close');
    },

    // Sort first by number, the unnumbered to show after.
    sortModuleContent(a, b) {
      if (a.number && b.number) {
        return genericSort(a.number, b.number);
      }
      if (!a.number && !b.number) {
        return genericSort(a.name, b.name);
      }
      if (a.number && !b.number) {
        return -1;
      }
      // if !a.number && b.number
      return 1;
    },
  },
};
</script>
