/* eslint no-param-reassign: "off" */
/* eslint no-console: off */
/* eslint no-underscore-dangle: ["error", { "allow": ["_log", "_data", "_getPayload"] }] */
import axios from 'axios';
import useGlobalStore from '@stores/global';
import { uuid } from '../modules/uuid-wrapper';
import { KHttp } from './k-http';
import TracebackMapper from '../modules/traceback-mapper';

const LOGS_UPLOAD_INTERVAL = 1000 * 30; // 30 seconds
const SEVERITY = {
  info: { console: 'info', gcp: 'INFO' },
  debug: { console: 'debug', gcp: 'DEBUG' },
  warning: { console: 'warn', gcp: 'WARNING' },
  error: { console: 'error', gcp: 'ERROR' },
  critical: { console: 'error', gcp: 'CRITICAL' },
};
// Taken from https://vuejs.org/error-reference/ - codes here are used in production builds only.
// In non-production builds (e.g. running locally), the string is provided directly
const VUE_ERROR_CODES = {
  0: 'setup function',
  1: 'render function',
  2: 'watcher getter',
  3: 'watcher callback',
  4: 'watcher cleanup function',
  5: 'native event handler',
  6: 'component event handler',
  7: 'vnode hook',
  8: 'directive hook',
  9: 'transition hook',
  10: 'app errorHandler',
  11: 'app warnHandler',
  12: 'ref function',
  13: 'async component loader',
  14: 'scheduler flush',
  15: 'component update',
  16: 'app unmount cleanup function',
  sp: 'serverPrefetch hook',
  bc: 'beforeCreate hook',
  c: 'created hook',
  bm: 'beforeMount hook',
  m: 'mounted hook',
  bu: 'beforeUpdate hook',
  u: 'updated',
  bum: 'beforeUnmount hook',
  um: 'unmounted hook',
  a: 'activated hook',
  da: 'deactivated hook',
  ec: 'errorCaptured hook',
  rtc: 'renderTracked hook',
  rtg: 'renderTriggered hook',
};
const QUEUE = [];

function clean(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function addToQueue(message, payload, severity, addToEvents = false) {
  QUEUE.push({
    message,
    payload,
    severity,
    is_event: addToEvents,
  });
}

function uploadLogs() {
  if (QUEUE.length === 0) {
    return;
  }
  const batch = [];
  while (QUEUE.length > 0) {
    batch.push(QUEUE.shift());
  }
  axios.post('/api/support/frontend-logs', {
    logs: batch,
  });
}

class KLogger {
  constructor(store, router) {
    this.store = store;
    this.router = router;
    this.sessionId = uuid();
    this.routeId = uuid();
    this.mapper = new TracebackMapper();
    if (process.env.NODE_ENV === 'production') {
      this.interval = setInterval(uploadLogs, LOGS_UPLOAD_INTERVAL);
    }
  }

  static getCurrentTimestamp() {
    return new Date().getTime() / 1000;
  }

  resetRouteId() {
    this.routeId = uuid();
  }

  getRoute() {
    const routeData = this.router.currentRoute.value;
    return {
      path: routeData?.path,
      query: routeData?.query,
      params: routeData?.params,
      name: routeData?.name,
    };
  }

  async _getPayload(level, msg, args, error) {
    const route = this.getRoute();
    const stateInfo = {
      appName: this.store.appName,
      userId: this.store.userId,
    };
    const payload = {
      ...args,
      stateInfo,
      route,
      session_id: this.sessionId,
      route_id: this.routeId,
      timestamp: KLogger.getCurrentTimestamp(),
      browser: navigator.userAgent,
    };
    if (error) {
      let traceback;
      try {
        if (process.env.NODE_ENV === 'production') {
          traceback = await this.mapper.getRefinedTraceback(error.stack);
        } else {
          traceback = error.stack;
        }
      } catch (errorInError) {
        traceback = error.stack;
      }
      payload.error = {
        name: error.name,
        message: error.message,
        traceback,
      };
    }
    return payload;
  }

  _log(level, msg, args, error, addToEvents = false) {
    if (typeof addToEvents !== 'boolean') {
      throw new Error('addToEvents must be a boolean');
    }
    this._getPayload(level, msg, args, error).then(payload => {
      if (process.env.NODE_ENV === 'production') {
        addToQueue(msg, clean(payload), SEVERITY[level].gcp, addToEvents);
      } else {
        console[SEVERITY[level].console](msg, clean(payload));
      }
    });
  }

  async info(msg, args, addToEvents = false) {
    this._log('info', msg, args, undefined, addToEvents);
  }

  async debug(msg, args, addToEvents = false) {
    this._log('debug', msg, args, undefined, addToEvents);
  }

  async warn(msg, args, error, addToEvents = false) {
    this._log('warning', msg, args, error, addToEvents);
  }

  async error(msg, args, error, addToEvents = false) {
    if (axios.isCancel(error)) {
      return;
    }
    this._log('error', msg, args, error, addToEvents);
  }

  async critical(msg, args, error, addToEvents = false) {
    this._log('critical', msg, args, error, addToEvents);
  }

  async autowarn(msg, args, error, addToEvents = false) {
    if (KHttp.isWarning(error)) {
      this.warn(msg, args, error, addToEvents);
    } else {
      this.error(msg, args, error, addToEvents);
    }
  }

  pageReady() {
    this._log('info', 'Page ready');
  }

  errorHandler(err, vm, info) {
    this.error('Unhandled exception', { info: VUE_ERROR_CODES[info] || info }, err);
  }
}

export default {
  install(app, options) {
    const { router } = options;
    const store = useGlobalStore();
    const logger = new KLogger(store, router);
    app.config.globalProperties.$logger = logger;
    app.config.globalProperties.$routeId = logger.routeId;
    app.config.errorHandler = logger.errorHandler.bind(logger);
  },
};
