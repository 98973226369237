<template>
  <div class="profile-sidebar">
    <ul class="sidebar-panel-nav">
      <li class="nav-group nav-group-title profile-overview">
        <router-link :to="{ name: 'profile_landing' }">
          <i class="fas fa-user-cog"></i><span>Profile Overview</span>
        </router-link>
      </li>
      <user-details-dropdown></user-details-dropdown>
      <li class="nav-group nav-group-title profile-badges">
        <router-link :to="{ name: 'profile_achievements' }">
          <i class="k-asset-icon fas fa-certificate"></i><span>Achievements</span>
        </router-link>
      </li>
      <li class="nav-group nav-group-title knowledge-base">
        <router-link :to="{ name: 'profile_questions' }">
          <i class="fas fa-comments"></i><span>My Questions</span>
        </router-link>
      </li>
      <li class="nav-group nav-group-title profile-keys">
        <router-link :to="{ name: 'profile_git_settings' }">
          <i class="fas fa-code-branch"></i><span>Git Settings</span>
        </router-link>
      </li>
      <li v-if="$featureFlags.isEnabled('kod-learning-environment')" class="nav-group nav-group-title profile-kods">
        <router-link :to="{ name: 'profile_manage_kods_page' }">
          <i class="fas fa-cloud"></i><span>Learning Environments</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import useGlobalStore from '@stores/global';
import StorageMixin from '@mixins/storage-mixins';
import userDetailsDropdown from './user-details-dropdown.vue';

export default {
  components: {
    'user-details-dropdown': userDetailsDropdown,
  },

  mixins: [StorageMixin],

  data() {
    return {
      store: useGlobalStore(),
    };
  },

  beforeMount() {
    if (!this.store.isSidebarOpen) {
      this.toggleSidebar();
    }
  },

  mounted() {
    this.$sidebar.setVisibility(true);
    this.$sidebar.ready = true;
  },

  methods: {
    toggleSidebar() {
      this.store.toggleSidebar();
      this.saveSetting('sidebar-toggle', 'sidebarState', this.store.isSidebarOpen);
    },
  },
};
</script>
