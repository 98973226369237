<template>
  <div v-if="asset" class="feedback-form-container" :class="showFeedback ? 'expanded' : ''">
    <button class="btn btn-primary feedback-btn" @click="closeFeedback" title="Feedback" aria-label="Feedback">
      Rate this Content
      <i class="fad" :class="showFeedback ? 'fa-chevron-double-down' : 'fa-chevron-double-up'"></i>
    </button>
    <div class="feedback-form">
      <h2>How did you find {{ asset.name }}?</h2>
      <form>
        <fieldset class="form-group form-entry">
          <span>Rate this content</span>
          <div class="rating-icons">
            <button class="upvote"
              :class="rating ? '' : 'greyed'"
              title="Thumbs up" aria-label="Thumbs up"
              @click.prevent="updateRating(true)">
              <i :class="rating !== true  ? 'fa-regular' : 'fa-solid' " class=" fa-thumbs-up"></i>
            </button>
            <button class="downvote"
            :class="rating === false ? '' : 'greyed'"
            title="Thumbs down" aria-label="Thumbs down"
            @click.prevent="updateRating(false)">
            <i :class="rating !== false ? 'fa-regular' : 'fa-solid'" class=" fa-thumbs-down"></i>
          </button>
          </div>
        </fieldset>
        <fieldset class="form-group form-entry">
          <label for="feedback-form-input">Tell us more <small><i>(optional)</i></small></label>
          <textarea id="feedback-form-input" class="form-control"
            v-model="comment"
            type="textarea"
            rows="3"
            placeholder="Tell us more about what you thought">
          </textarea>
          <small>If you have questions about this content or would like support from the Cambridge Spark team then please add a question to the
            <router-link :to="{ name: 'module_knowledge_base', moduleId }">Knowledge Base</router-link>
          </small>
        </fieldset>
        <button :disabled="!validForm" type="submit" title="Submit feedback" aria-label="Submit feedback" class="btn btn-primary submit-button" @click.prevent="submitForm">Submit</button>
      </form>
    </div>
  </div>
</template>

<style scoped>
.feedback-form-container {
  position: fixed;
  right: -60px;
  top: 50%;
  transition: transform 0.2s ease-in-out;
  z-index: 9999;
  transform: translateX(0);
}

.feedback-form-container.expanded {
  transition: transform 0.2s ease-in-out;
  transform: translateX(-300px);
}

.feedback-form-container .feedback-form {
  background-color: var(--kate-background);
  border-radius: 4px;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 93px;
  width: 300px;
}

/* NOTE: Box shadow special case */
.feedback-form-container.expanded .feedback-form {
  pointer-events: auto;
  box-shadow: var(--box-shadow-highlight);
}

.feedback-form-container.expanded .feedback-form h2 {
  border-bottom: 1px solid var(--kate-primary-dark);
}

.feedback-form-container .feedback-form h2 {
  padding: 25px 15px;
  margin: 0;
  font-size: 1.2em;
}

.feedback-form-container form {
  padding: 15px;
}

.feedback-form-container fieldset {
  color: var(--kate-type-light);
}

.feedback-form textarea {
  resize: vertical;
  min-height: 75px;
  max-height: 40vh;
}

.feedback-form-container fieldset small {
  font-size: 0.85em;
  color: var(--kate-type-primary);
}

.feedback-form-container .rating-icons {
  display: flex;
  justify-content: space-around;
}

.feedback-form-container .feedback-btn {
  transform: rotate(-90deg) translateX(20%);
  color: var(--kate-type-dark);
  box-shadow: -2px 2px 3px rgb(0 0 0 / 0.5);
  padding: 5px 8px;
  position: relative;
  z-index: 1;
}

.feedback-form-container .feedback-btn:focus {
  color: var(--kate-type-light);
}

.feedback-form-container .feedback-btn i {
  margin-left: 5px;
}

@media (max-width: 767px) {
  .feedback-form-container {
    right: -56px;
  }

  .feedback-form-container .feedback-btn {
    padding: 8px;
  }

  .feedback-form-container .feedback-form {
    margin-left: 96px;
  }
}

@media screen and (max-height: 700px) {
  .feedback-form-container {
    right: -46px;
  }

  .feedback-form-container .feedback-form {
    margin-left: 105px;
  }

  .feedback-form-container.expanded .feedback-form {
    margin-left: 90px;
  }
}
</style>

<style>
.has-scroll .feedback-form-container {
  right: -46px;
}

.has-scroll .feedback-form-container .feedback-form {
  margin-left: 105px;
}

.has-scroll .feedback-form-container.expanded .feedback-form {
  margin-left: 90px;
}
</style>
<script>
import ErrorMixin from '@mixins/error-mixins';

export default {
  mixins: [ErrorMixin],

  props: {
    asset: {
      type: Object,
      required: true,
    },
    moduleId: {
      type: Number,
    },
  },

  data() {
    return {
      showFeedback: false,
      comment: '',
      rating: null,
    };
  },

  computed: {
    validForm() {
      return Boolean(this.comment || this.rating != null);
    },
  },

  methods: {
    updateRating(value) {
      if (value === this.rating) {
        this.rating = null;
      } else {
        this.rating = value;
      }
    },

    submitForm() {
      if (!this.validForm) {
        return;
      }
      this.postFeedback();
      this.showFeedback = false;
      this.clearFields();
    },

    clearFields() {
      this.comment = '';
      this.rating = null;
    },

    closeFeedback() {
      this.showFeedback = !this.showFeedback;
    },

    postFeedback() {
      const jsonPayload = {
        comment: this.comment,
        rating: this.rating,
      };
      this.$http.post(`/api/curriculum/feedback/${this.asset.asset_type}/${this.asset.id}`, jsonPayload)
        .then(() => {
          this.$ktoast.info('Thank you for your feedback!', { goAway: 3500 });
          this.clearFields();
        }).catch(err => {
          this.showError(err, true);
        }).then(() => {
          this.showFeedback = false;
        });
    },
  },
};
</script>
