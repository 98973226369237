<template>
  <div :class="isDashboard ? store.sidebarClass : null">
    <div v-if="ready && questionnaire" id="questionnaire-page" class="content-with-spacing">
      <section class="heading-container" :class="{ dashboard: isDashboard }">
        <div class="heading-content">
          <div class="title-container">
            <h1>{{ questionnaire.name }}</h1>
            <p class="description" v-html="getMarkdown(questionnaire.description)"></p>
          </div>
          <div class="heading-details">
            <k-progress v-if="latestSubmission" class="score" shape="circle" size="small" :percentage="overallScore" :precision="0" ariaLabel="Questionnaire completion percentage"></k-progress>
            <p v-if="latestSubmission" class="submission-date">Last submission: <b>{{parseTimestamp(latestSubmission.date_timestamp)}}</b></p>
            <div v-if="!isDashboard" class="reset-form-button">
              <button @click.prevent="resetQuestionnaire" class="btn btn-secondary reset-button" type="button" title="Reset responses">Reset</button>
            </div>
          </div>
        </div>
      </section>
      <section>
        <empty-placeholder v-if="!latestSubmission && isDashboard" info="The student has not made a submission yet"></empty-placeholder>
        <k-questionnaire v-else
          ref="questionnaire"
          :questionnaire="questionnaire"
          :latest-submission="latestSubmission"
          :read-only="isDashboard"
          :disable-text-toolbar="isDashboard"
          @submit="submitQuestionnaire"
          >
        </k-questionnaire>
      </section>
    </div>
  </div>
</template>

<style scoped>
.heading-container {
  display: flex;
  flex-direction: column;
  margin: 20px auto !important;
}

.heading-container.dashboard {
  margin-top: 40px !important;
}

.heading-content {
  display: flex;
  gap: 15px;
  width: 100%;
  margin: 0 auto !important;
  justify-content: space-between;
}

.heading-container .heading-details {
  flex: 1 1 20%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  text-align: right;
}

.title-container {
  align-self: unset;
}

#content .title-container h1 {
  margin: 0 !important;
  align-self: unset;
  padding: 10px 0;
  width: 100%;
}

.heading-details .score {
  width: 100%;
}

.reset-form-button {
  position: relative;
  display: inline-block;
  width: 100%;
}

.reset-form-button button {
  margin-top: 10px;
  padding: 10px 25px;
}

@media (max-width: 1024px) {
  .heading-container {
    display: block;
  }

  .heading-container .title-container {
    flex: 1 1 100%;
  }

  #content .heading-container .title-container h1 {
    font-size: 1.8em;
    text-align: left;
  }

  .heading-container .heading-details {
    text-align: center;
  }
}
</style>

<style>
#k-text-editor .ql-editor.ql-blank::before {
  color: var(--kate-type-primary);
  opacity: 0.5;
  left: 7px;
}
</style>

<script>
import useGlobalStore from '@stores/global';
import TimeMixins from '@mixins/time-mixins';
import ErrorMixin from '@mixins/error-mixins';
import KProgress from '@base-components/k-progress.vue';
import EmptyPlaceholder from '@base-components/empty-placeholder.vue';
import { markdown } from '../modules/maths-markdown';
import KQuestionnaire from './k-questionnaire.vue';

export default {
  mixins: [TimeMixins, ErrorMixin],

  components: {
    KQuestionnaire,
    KProgress,
    EmptyPlaceholder,
  },

  data() {
    return {
      store: useGlobalStore(),
      questionnaireReady: false,
      questionnaire: undefined,
      latestSubmissionReady: false,
      latestSubmission: undefined,
      isSubmitting: false,
      studentName: undefined,
      disableSubmission: true,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getLatestSubmission();
    this.getQuestionnaire();
    this.getUserProfile();
  },

  watch: {
    ready(val) {
      if (val) {
        this.$Loading.finish();
        this.$nextTick(() => {
          this.disableSubmission = false;
        });
      } else {
        this.$Loading.start();
      }
    },
    isSubmitting(val) {
      if (val) {
        this.$Loading.minimal();
      } else {
        this.$Loading.finish();
      }
    },
  },

  computed: {
    ready() {
      return this.questionnaireReady && this.latestSubmissionReady;
    },
    isDashboard() {
      return this.store.isDashboard;
    },
    moduleQuestionnaireId() {
      return parseInt(this.$route.params.moduleQuestionnaireId, 10);
    },
    studentId() {
      return this.$route.params.studentId;
    },
    overallScore() {
      return this.latestSubmission?.overall_score || 0;
    },
    feedbackEndpoint() {
      if (this.isDashboard) {
        return `/api/stats/students/${this.studentId}/feedback/questionnaire/${this.moduleQuestionnaireId}`;
      }
      return `/api/stats/questionnaire/${this.moduleQuestionnaireId}/feedback`;
    },
  },

  methods: {
    registerCrumbs() {
      let crumbs;
      if (this.isDashboard) {
        crumbs = [
          {
            text: this.studentName,
            path: {
              name: 'dashboard_students',
              params: { studentId: this.studentId },
            },
          },
          { text: this.questionnaire.name, active: true },
        ];
      }
      this.$crumbs.register(crumbs);
    },
    getUserProfile() {
      if (!this.isDashboard) {
        return;
      }
      this.$http.get(`/api/profile/users/${this.studentId}`).then(res => {
        this.studentName = res.data.full_name;
        this.registerCrumbs();
      }).catch(() => {});
    },
    getQuestionnaire() {
      this.$logger.info('Getting questionnaire info', { moduleQuestionnaireId: this.moduleQuestionnaireId }, true);
      this.$http.get(`/api/curriculum/questionnaire/${this.moduleQuestionnaireId}`).then(response => {
        this.$logger.info('Got questionnaire info', { moduleQuestionnaireId: this.moduleQuestionnaireId });
        this.questionnaire = response.data;
        this.questionnaireReady = true;
        this.registerCrumbs();
      }).catch(err => {
        if (this.$http.isWarning(err)) {
          this.$logger.warn('Questionnaire not found', { moduleQuestionnaireId: this.moduleQuestionnaireId }, err);
        } else {
          this.$logger.error('Error getting questionnaire', { moduleQuestionnaireId: this.moduleQuestionnaireId }, err);
          this.showError(err, true);
        }
      });
    },
    getLatestSubmission(updateSidebar) {
      this.$logger.info('Getting latest questionnaire submission', { moduleQuestionnaireId: this.moduleQuestionnaireId }, true);
      this.$http.get(this.feedbackEndpoint).then(response => {
        this.$logger.info('Got latest questionnaire submission', { moduleQuestionnaireId: this.moduleQuestionnaireId });
        this.latestSubmission = response.data.feedback;
        this.previousResponses = this.latestSubmission?.responses;
        // Update the sidebar
        if (updateSidebar) {
          this.$sidebar.updateAssetScore('questionnaire', this.moduleQuestionnaireId, this.latestSubmission.overall_score);
          this.$sidebar.updateCompletionStatus('questionnaire', this.moduleQuestionnaireId, this.latestSubmission.overall_score === 100);
        }
      }).catch(err => {
        if (this.$http.isWarning(err)) {
          this.$logger.warn('Latest questionnaire submission not found', { moduleQuestionnaireId: this.moduleQuestionnaireId }, err);
        } else {
          this.$logger.error('Error getting latest questionnaire submission', { moduleQuestionnaireId: this.moduleQuestionnaireId }, err);
          this.showError(err, true);
        }
      }).then(() => {
        this.latestSubmissionReady = true;
      });
    },
    submitQuestionnaire(payload) {
      if (this.isDashboard || this.disableSubmission) {
        return;
      }
      this.isSubmitting = true;
      this.$logger.info('Submitting questionnaire', { moduleQuestionnaireId: this.questionnaire.questionnaire_id }, true);
      this.$http.post(`/api/curriculum/questionnaire/${this.moduleQuestionnaireId}/submit`, payload)
        .then(res => {
          if (res.data.badges_achieved) {
            this.$ktoast.showBadgesAchieved(res.data.badges_achieved);
          } else {
            this.$ktoast.success('Questionnaire submitted');
          }
          this.getLatestSubmission(true);
        }).catch(err => {
          // Show submission error even if it is a warning HTTP code
          this.$logger.error('Error submitting questionnaire', { moduleQuestionnaireId: this.moduleQuestionnaireId }, err);
          this.showError(err, true);
        }).then(() => {
          this.isSubmitting = false;
        });
    },
    clearResponses() {
      this.disableSubmission = true;
      this.$refs.questionnaire.clearResponses();
      this.$nextTick(() => {
        this.disableSubmission = false;
      });
    },
    resetQuestionnaire() {
      this.$emit('reset');
      this.clearResponses();
    },
    getMarkdown(str) {
      return markdown(str);
    },
  },
};
</script>
