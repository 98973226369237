<template>
  <li v-if="$permissions.hasPermission('manage_programmes')" class="nav-group nav-group-title">
    <router-link :to="{ name: 'programmes_overview'}">
      <i class="fa-duotone fa-diagram-project k-asset-icon"></i><span>Programmes</span>
    </router-link>
  </li>
</template>

<script>
import SidebarEntryMixin from '@mixins/sidebar-entry-mixin';

export default {
  mixins: [SidebarEntryMixin],

  data() {
    return {
      toggled: false,
    };
  },

  computed: {
    isVisible() {
      return this.$permissions.hasPermission('manage_programmes');
    },
  },
};
</script>
