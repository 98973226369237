<template>
  <div v-if="item" id="panel-card" class="panel card-container flex-this">
    <div class="card-label">
      <template v-if="showLabel">
        <span>{{item.number || cardLabel || '-'}}</span>
      </template>
    </div>
    <div class="panel-body panel-card-container" :class="cardContentDirection">
      <slot name="header">
        <div class="card-header">
          <h2 v-if="item.name" class="card-title">{{ item.name }}</h2>
          <div class="completion-date" v-if="item.expected_completion_date">
            <span>Due: <b>{{ parseTimestamp(item.expected_completion_date)}}, ({{ Intl.DateTimeFormat().resolvedOptions().timeZone}})</b></span>
          </div>
        </div>
      </slot>

      <div class="panel-card-body">
        <slot name="chart">
          <k-progress v-if="showProgressBar"
          shape="bar"
          :percentage="item.percentage"
          :precision="1"
          :show-bar-text="true"
          size="small"
          customText="Completed"
          ariaLabel="Overall Module Completion"
        ></k-progress>
        </slot>

        <slot name="actions">
          <div class="panel-card-actions">
            <div v-if="moduleDescription" v-html="moduleDescription" class="row card-description"></div>
            <router-link class="btn btn-module btn-primary" :to="modulePageTo">
              View Module <i class="fas fa-arrow-circle-right"></i>
            </router-link>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<style>
/* Constants */
#k-panel-competency-card .panel-body,
#panel-card .panel-body {
  position: relative;
  width: 100%;
}

#k-panel-competency-card .panel-card-container,
#panel-card .panel-card-container,
#panel-card .panel-card-actions {
  display: flex;
  justify-content: space-between;
}

/* Module Card Column */
#panel-card .panel-card-container.column {
  height: 100%;
  flex-direction: column;
}

/* Module Card Row */
#k-panel-competency-card .panel-card-container.row,
#panel-card .panel-card-container.row {
  margin-right: unset;
  margin-left: unset;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
}

#k-panel-competency-card .panel-card-container.row .card-header,
#panel-card .panel-card-container.row .card-header {
  flex: 1 1 100%;
}

.btn-module {
  max-width: 200px;
  max-height: 38px;
}

#k-panel-competency-card .card-header,
#panel-card .card-header {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
  width: 100%;
}

#k-panel-competency-card .module-details,
#panel-card .module-details {
  padding: 0 0 5px;
}

#panel-card .module-details span {
  font-weight: bold;
  color: var(--kate-secondary);
  font-size: 1.1em;
}

#panel-card .module-title {
  color: var(--kate-type-light);
  font-size: 1.4em;
  padding: 0;
  margin: 0;
}

#panel-card .panel-card-body {
  margin-bottom: 20px;
}

#panel-card .project-description ul li {
  word-break: break-word;
  hyphens: auto;
  color: var(--kate-type-primary);
}

#panel-card .btn-block {
  box-shadow: var(--box-shadow);
}

#panel-card .panel-card-actions {
  padding: 15px 0 10px;
  gap: 15px;
  align-items: center;
}

#panel-card .panel-card-actions:has(a:only-child) {
  justify-content: flex-end;
}

#k-panel-competency-card .card-label,
#panel-card .card-label {
  display: flex;
  align-items: center;
  background-color: rgb(92 255 243 / 0.5);
  justify-content: center;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  min-width: 100px;
  padding: 0 5px;
}

#k-panel-competency-card .card-label span,
#panel-card .card-label span {
  color: var(--kate-mono-0);
  font-size: 2.5em;
  font-weight: bold;
  font-family: "apercu_monoregular", monospace;
}

/* Responsive */
@media (max-width: 768px) {
  #panel-card .card-container.panel {
    min-height: unset;
    height: auto;
    width: 100%;
  }

  #panel-card .card-label {
    justify-content: center;
    height: auto;
  }

  #panel-card .card-label span {
    font-size: 2em;
  }

  #panel-card .panel-body {
    min-height: unset;
  }

  #panel-card .panel-card-container {
    text-align: center;
    margin-bottom: 0;
  }

  #panel-card .card-header,
  #panel-card .panel-card-actions {
    flex-direction: column;
  }

  #panel-card .card-description {
    text-align: left;
  }
}
</style>

<script>
import TimeMixin from '@mixins/time-mixins';
import ModuleMixin from '@mixins/module-mixin';
import KProgress from '@base-components/k-progress.vue';

export default {
  components: {
    KProgress,
  },

  mixins: [TimeMixin, ModuleMixin],

  props: {
    item: {
      type: Object,
      required: true,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    cardContentDirection: {
      type: String,
      default: 'column', // column or row
    },
    cardLabel: String,
    showProgressBar: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    modulePageTo() {
      return {
        name: 'module_landing',
        params: { moduleId: this.item.id },
      };
    },

    moduleDescription() {
      if (this.item.short_description || this.item.description) {
        return this.getTruncatedModuleDescription(this.item);
      }
      return undefined;
    },
  },
};
</script>
