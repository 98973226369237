<template>
  <div class="evaluation-summary">
    <ul>
      <li class="summary-list" :class="{'not-clickable': displayRunnerKeys }">
        <button @click="$emit('open-details')" class="btn-link function-details">
          <span v-if="displayRunnerKeys" class="runner-key">{{entry.runner_key}}</span>
          <span class="function-name">
            {{trimDesc(entry.test_description) || entry.test_name || entry.info}}
            <br />
          </span>
          <span class="line-number" v-if="entry.cell_number > -1">
            cell number: {{ entry.cell_number + 1 }}
            <br />
          </span>
          <span class="line-number" v-if="entry.line_number > -1 && displayRunnerKeys">
            Line: {{ entry.line_number }}
            <br />
          </span>
          <span class="trim-description">
            {{entry.function_tested__name || entry.file_path}}
            <br />
          </span>
          <pre v-if="displayDescription && !displayRunnerKeys">{{entry.info}}</pre>
        </button>
        <div v-if="!displayRunnerKeys" class="error-details">
          <span v-if="entry.score" class="extra-points-score">{{entry.score}}</span>
          <span v-if="displayResultTypes" class="label" :class="`k-error-label-${entry.result_type}`">{{entry.result_type}}</span>
          <button v-if="entry.details || displayRunnerKeys" class="open-modal-icon btn-icon" title="Open Details" @click="$emit('open-details')" aria-label="Open Details">
            <i class="fas fa-external-link-alt"></i>
          </button>
        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.evaluation-summary {
  border: 0;
}

.summary-list,
.error-details,
.summary-list button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.summary-list {
  cursor: pointer;
  list-style: none;
  padding: 0 10px;
  border-bottom: 1px solid var(--kate-type-light);
}

.error-details,
.summary-list button {
  padding: 10px 0;
  text-decoration: none;
  color: var(--kate-type-primary);
}

.summary-list:hover {
  background-color: rgb(0 38 47 / 0.5);
}

.summary-list button:hover {
  background-color: transparent;
}

.summary-list.not-clickable {
  pointer-events: none;
}

.trim-description {
  flex: none;
}

.function-name {
  text-decoration: underline;
  color: var(--kate-type-link);
}

.summary-list:hover button.open-modal-icon,
.summary-list:hover .function-name {
  color: var(--kate-type-link-hover);
}

.line-number {
  color: var(--kate-type-light);
}

.runner-key {
  display: block;
  color: var(--kate-logo-lime-green);
}

.btn-link {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  padding: 8px 0;
  text-decoration: none;
}

.k-error-label-error {
  padding: 5px 15px;
  color: var(--kate-type-light);
  background-color: var(--kate-danger);
  border-radius: 4px;
}

.k-error-label-failure {
  padding: 5px 15px;
  color: var(--kate-type-dark);
  background-color: var(--kate-secondary);
  border-radius: 4px;
}

.extra-points-score {
  font-weight: bold;
  color: var(--kate-type-primary);
}
</style>

<script>
export default {
  name: 'FeedbackEntry',

  props: {
    entry: {
      type: Object,
      required: true,
    },
    displayRunnerKeys: {
      type: Boolean,
      default: false,
    },
    displayResultTypes: {
      type: Boolean,
      default: true,
    },
    displayDescription: {
      type: Boolean,
      default: false,
    },
    showExplanations: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['open-details'],

  computed: {
    isNotImplemented() {
      if (this.entry.details) {
        return this.entry.details === 'Not Implemented'
          || this.entry.details.includes('NotImplementedError');
      }
      return false;
    },
  },

  methods: {
    trimDesc(str) {
      if (!str) {
        return '';
      }
      return str.trim().split('\n')[0];
    },
  },
};
</script>
