<template>
  <k-modal
    class="function-error-modal"
    :show="show"
    @close="$emit('close')"
  >
    <template #header>
      <div class="header-container">
        <div class="header-details">
          <h4 v-if="entry.function_tested__name || entry.test_description">
            {{entry.function_tested__name || entry.test_description}}
          </h4>
          <span class="line-number" v-if="entry.cell_number > -1">
            cell number: {{ entry.cell_number + 1 }}
            <br />
          </span>
          <span class="line-number" v-else-if="entry.line_number > -1">
            Line: {{entry.line_number}}
            <br />
          </span>
          <span class="code-quality-function" v-if="entry.function_name">function: {{entry.function_name}}</span>
        </div>
      </div>
    </template>

    <template #body>
      <button v-if="showNavigation" class="direction-arrow arrow-left" @click="$emit('previous')" aria-label="Previous" title="Previous">
        <i class="fas fa-angle-left"></i>
      </button>

      <div class="full-desc" v-if="entry.test_description" v-html="$kpurify.sanitise(markedTestDescription)"></div>
      <pre class="alert alert-danger" v-if="entry.details">{{getPrettyText(entry.details)}}</pre>
      <pre class="alert alert-danger" v-else-if="entry.info">{{getPrettyText(entry.info)}}</pre>

      <k-editor
          class="notebook-cell"
          v-if="notebook && entry.cell_number > -1 && !isNotImplemented && cellContents"
          :read-only="true"
          syntax="python"
          :line-numbers="false"
          :text="cellContents"></k-editor>

      <pre class="full-desc" v-if="entry.returned">{{entry.returned}}</pre>
      <pre v-if="displayDescription">{{entry.details}}</pre>

      <exception-renderer v-if="entry.render" :base="entry.render" />

      <error-help-section v-if="entry.test_name === 'file_error'" />

      <feedback-explanation
        v-if="(isExplaining || explanation || explanationError) && showExplanations"
        :is-loading="isExplaining"
        :error="explanationError"
        :explanation="explanation"
        :rating="explanationRating"
        :is-rating-disabled="isRatingDisabled"
        @update-rating="$emit('update-explanation-rating', $event)"
      />
      <button v-if="showNavigation" class="direction-arrow arrow-right" @click="$emit('next')" aria-label="Next" title="Next">
        <i class="fas fa-angle-right"></i>
      </button>
    </template>

    <template #footer>
      <div class="modal-footer-content">
        <div class="left-buttons">
          <button v-if="!isExplaining && !explanation && !isNotImplemented && showExplanations && $featureFlags.isEnabled('ai-feedback-explanation') && notebookOnly"
                  class="modal-default-button btn btn-outlined explain-modal-button"
                  @click="$emit('explain', entry)">
            <img :src="chatbotImage" class="chatbot-icon-small" alt="KATE bot avatar"/>
            Explain with KATE
          </button>
        </div>
        <div class="right-buttons">
          <template v-if="showNavigation">
            <button class="modal-default-button btn btn-outlined" @click="$emit('previous')">
              Prev
            </button>
            <button class="modal-default-button btn btn-outlined" @click="$emit('next')">
              Next
            </button>
          </template>
          <button class="modal-default-button btn btn-danger" @click="$emit('close')">
            Close
          </button>
        </div>
      </div>
    </template>
  </k-modal>
</template>

<style scoped>
.function-error-modal {
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  z-index: 1000;
}

.function-error-modal pre {
  margin: 10px 0;
}

.function-error-modal :deep(.modal-mask) {
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.function-error-modal :deep(.modal-container) {
  width: 600px !important;
  max-width: 90vw !important;
  margin: 30px auto !important;
}

.function-error-modal :deep(.modal-body) {
  width: 100%;
  overflow-x: hidden;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 25px;
}

.header-details {
  margin-right: 30px;
}

.line-number {
  color: var(--kate-type-light);
}

.code-quality-function {
  display: block;
  color: var(--kate-logo-lime-green);
}

.modal-footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.left-buttons,
.right-buttons {
  display: flex;
  gap: 10px;
}

.chatbot-icon-small {
  width: 20px;
  height: 20px;
}

.explain-modal-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

button.direction-arrow {
  background-color: transparent;
  border: 0;
  color: var(--kate-button-primary);
  position: absolute;
  top: 50%;
  transform: translateY(-25%);
  font-size: 1.5em;
  padding: 10px 12px;
}

button.direction-arrow:hover {
  color: var(--kate-button-secondary);
}

.arrow-left {
  left: 0;
}

.arrow-right {
  right: 0;
}

.error-help-section {
  margin-top: 20px;
  border-top: 1px solid var(--kate-border-color);
}

.error-help-toggle {
  width: 100%;
  text-align: left;
  padding: 15px 0;
  background: none;
  border: none;
  color: var(--kate-type-primary);
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error-help-toggle:hover {
  color: var(--kate-secondary);
}

.error-help-content {
  padding: 0 15px 15px;
}

.error-help-content h5 {
  color: var(--kate-secondary);
  font-size: 1.1em;
  margin-bottom: 15px;
}

.error-help-content ul {
  padding-left: 20px;
  margin: 10px 0;
}

.error-help-content li {
  margin: 5px 0;
}

@media only screen and (max-width: 767px) {
  .modal-body pre {
    white-space: normal;
    word-break: break-word;
  }
}
</style>

<script>
import { marked } from 'marked';
import dedent from 'dedent';
import KModal from '@base-components/k-modal.vue';
import useGlobalStore from '@stores/global';
import KEditor from '../../ide/k-editor.vue';
import { importImage } from '../../modules/dynamic-image-importer';
import FeedbackExplanation from './feedback-explanation.vue';
import ExceptionRenderer from '../exception-renderer/base.vue';
import ErrorHelpSection from './error-help-section.vue';

export default {
  name: 'FeedbackModal',

  components: {
    KModal,
    KEditor,
    FeedbackExplanation,
    ExceptionRenderer,
    ErrorHelpSection,
  },

  props: {
    show: {
      type: Boolean,
      required: true,
    },
    entry: {
      type: Object,
      required: true,
    },
    notebook: {
      type: Object,
      default: null,
    },
    displayDescription: {
      type: Boolean,
      default: false,
    },
    showNavigation: {
      type: Boolean,
      default: false,
    },
    isExplaining: {
      type: Boolean,
      default: false,
    },
    explanation: {
      type: String,
      default: '',
    },
    explanationError: {
      type: String,
      default: '',
    },
    explanationRating: {
      type: Boolean,
      default: null,
    },
    isRatingDisabled: {
      type: Boolean,
      default: false,
    },
    showExplanations: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['close', 'previous', 'next', 'explain', 'update-explanation-rating'],

  data() {
    return {
      store: useGlobalStore(),
    };
  },

  computed: {
    chatbotImage() {
      return importImage('icons/kate-bot-chat-colours.svg');
    },
    markedTestDescription() {
      return marked(this.entry.test_description);
    },
    isNotImplemented() {
      if (this.entry.details) {
        return this.entry.details === 'Not Implemented'
        || this.entry.details.includes('NotImplementedError');
      }
      return false;
    },
    cellContents() {
      if (!this.notebook || !this.notebook.cells[this.entry.cell_number]
      || !Array.isArray(this.notebook.cells[this.entry.cell_number].source)) {
        return '';
      }
      return this.notebook.cells[this.entry.cell_number].source.join('');
    },
    notebookOnly() {
      return Boolean(this.notebook);
    },
  },

  methods: {
    getPrettyText(str) {
      if (!str) {
        return '';
      }
      return dedent(str.trim());
    },
  },
};
</script>
