<template>
  <div class="panel-pagination">
    <div class="thread-count">
      <span>Displaying <b>{{ displayedEntries }}</b> out of </span>
      <span><b>{{ totalEntries }} entries</b></span>
    </div>
    <div class="pagination-container">
      <ul class="pagination">
        <li><span :class="isValueFirst ? 'disabled' : ''" class="clickable pagination-button pagination-previous" @click="prevPage"><i class="fas fa-angle-left"></i></span></li>
        <li v-for="n in prePage"><span class="clickable pagination-button" @click="updatePage(n)">{{n+1}}</span></li>
        <li><span class="clickable pagination-button current-page">{{modelValue+1}}</span></li>
        <li v-for="n in postPage"><span class="clickable pagination-button" @click="updatePage(n)">{{n+1}}</span></li>
        <li><span :class="isValueLast ? 'disabled' : ''" class="clickable pagination-button pagination-next" @click="nextPage"><i class="fas fa-angle-right"></i></span></li>
      </ul>
    </div>
  </div>
</template>

<style>
.panel-pagination.row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  margin: 0;
  align-items: center;
}
</style>

<style scoped>
.pagination-button {
  text-align: center;
}

.pagination {
  margin: 0;
  margin-bottom: 0;
  border-radius: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.pagination li {
  list-style: none;
  display: inline-block;
}

.pagination li span.current-page {
  background-color: var(--kate-type-accent);
  color: var(--kate-type-dark);
  cursor: default;
}

.pagination li span.pagination-previous::after {
  content: "Previous";
  padding-left: 5px;
}

.pagination li span.pagination-next::before {
  content: "Next";
  padding-right: 5px;
}

.pagination li span {
  background-color: transparent;
  color: var(--kate-type-primary);
  border: var(--border-secondary);
  white-space: nowrap;
  margin: 0;
  padding: 8px 14px;
  display: block;
}

.pagination li span.disabled {
  cursor: not-allowed;
  border-color: var(--kate-disabled);
  color: var(--kate-disabled);
}

.pagination li:hover span.disabled {
  background-color: transparent;
  color: var(--kate-disabled);
}

.pagination li span i {
  font-size: 1em;
}

@media only screen and (max-width: 767px) {
  .panel-pagination.row {
    margin-top: 10px;
  }

  .thread-count,
  .pagination-container {
    padding-left: 0;
    padding-right: 0;
  }

  .pagination li span.pagination-previous::after {
    content: "";
    padding-left: 0;
  }

  .pagination li span.pagination-next::before {
    content: "";
    padding-right: 0;
  }
}

</style>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      default: 5,
    },
    modelValue: Number,

    totalEntries: {
      type: Number,
    },

    displayedEntries: {
      type: Number,
    },

  },
  computed: {
    prePage() {
      const start = Math.max(this.modelValue - Math.floor(this.max / 2), 0);
      const output = [];
      for (let i = start; i < this.modelValue; i++) {
        output.push(i);
      }
      return output;
    },

    postPage() {
      const amount = this.max - this.prePage.length;
      const end = Math.min(this.modelValue + amount, this.count);
      const output = [];
      for (let i = this.modelValue + 1; i < end; i++) {
        output.push(i);
      }
      return output;
    },

    isValueLast() {
      return this.postPage.length === 0;
    },

    isValueFirst() {
      return this.prePage.length === 0;
    },
  },
  methods: {
    prevPage() {
      this.updatePage(Math.max(this.modelValue - 1, 0));
    },

    nextPage() {
      this.updatePage(Math.min(this.modelValue + 1, this.count));
    },

    updatePage(num) {
      if (num < this.count && num >= 0) {
        this.$emit('update:modelValue', num);
      }
    },
  },
};
</script>
