<template>
  <div class="panel panel-default answer-card" :class="isAcceptedAnswer ? 'accepted' : ''">
    <div class="panel-body">
      <div class="answer-details">
        <div class="thread-header">
          <div class="user">
            <k-avatar :avatarUrl="answer.avatar_url"></k-avatar>
            <div class="user-info">
              <div class="user-name">
                <h3 v-if="isDashboard">
                  <router-link :to="{ name: 'dashboard_students', params: { studentId: answer.user_id }, }">
                    {{ answerName }}
                  </router-link>
                </h3>
                <h3 v-else>{{ answerName }}</h3>
                <span class="tag-edukator" v-if="answer.is_edukator">Cambridge Spark</span>
              </div>
              <span class="date-posted">Posted: <b>{{ parseTimestamp(answer.posted_on) }}</b></span>
            </div>
          </div>
          <div v-if="isAnswerOriginalPoster" class="header-buttons-container action-buttons">
            <button @click="$emit('openEditModal')" @edited="$emit('edited')" class="btn btn-primary edit-btn">
              Edit answer <i class="fas fa-edit"></i>
            </button>
          </div>
        </div>
        <div class="answer-body">
          <div class="answer-body-content">
            <div v-html="$kpurify.sanitise(answer.body)"></div>
            <div v-if="answer.resources && answer.resources.length > 0" class="thread-resources">
              <k-gallery :images="imageResources"></k-gallery>
            </div>
          </div>
          <div class="thread-btn-container">
            <vote-card
              :thread="answer"
              :userVotes="userVotes"
              @upvote="$emit('upvote')"
              @unvote="$emit('unvote')"
              @voted="$emit('voted')"
            ></vote-card>
            <div v-if="userCanAccept" class="select-as-answer">
              <button @click="unacceptAnswer" v-if="isAcceptedAnswer" class="selected-answer">
                <b><i class="fas fa-check"></i></b><br />Accepted answer
              </button>
              <button @click="acceptAnswer" v-else>
                <b><i class="fas fa-check"></i></b><br />Accept answer
              </button>
            </div>
            <div v-else-if="isAcceptedAnswer" class="select-as-answer">
              <span class="selected-answer">
                <b><i class="fas fa-check"></i></b><br />Accepted answer
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.answer-body-content p {
  margin: 0;
}
</style>

<style scoped>
.answer-card.accepted {
  border: 4px solid var(--kate-success);
  padding: 0;
}

.thread-header {
  margin: 0 0 15px;
}

.user {
  align-items: center;
}

.user-info {
  width: 100%;
  flex-direction: column;
  gap: 15px;
}

.user-info h2 {
  font-size: 1.25em;
  margin-bottom: 0;
}

.user,
.user-info,
.timestamps-container {
  display: flexbox;
  display: flex;
}

.timestamps-container {
  flex-wrap: wrap;
}

.timestamps-container b {
  color: var(--kate-type-light);
}

.thread-resources {
  margin-bottom: 15px;
  padding: 0;
}

hr {
  border: 1px solid var(--border-secondary);
}

.thread-hr {
  border: 1px solid var(--kate-background-body);
}

/* Accept */
.thread-btn-container .select-as-answer {
  background-color: var(--kate-panel-alt);
  padding: 15px 5px;
  border-radius: 4px;
}

.thread-btn-container .select-as-answer button {
  background: transparent;
  transition: all 0.3s ease;
}

.thread-btn-container .select-as-answer:hover {
  background-color: var(--kate-background-body-alpha);
}

.thread-btn-container .select-as-answer button.downvote-btn {
  color: var(--kate-danger-alt);
  margin-right: 5px;
}

.thread-btn-container .select-as-answer button.downvote-btn.greyed {
  color: var(--kate-type-blue-grey);
}

.thread-btn-container .select-as-answer button.downvote-btn:hover {
  color: var(--kate-danger);
}

.thread-btn-container .select-as-answer button.upvote-btn {
  color: var(--kate-success);
  margin-left: 6px;
}

.thread-btn-container .select-as-answer button.upvote-btn.greyed {
  color: var(--kate-type-blue-grey);
}

.thread-btn-container .select-as-answer button.upvote-btn:hover {
  color: var(--kate-success-alt);
}

.thread-btn-container .select-as-answer .selected-answer b,
.thread-btn-container .select-as-answer .selected-answer {
  color: var(--kate-type-success);
}

.select-as-answer {
  display: flex;
  text-align: center;
  justify-content: space-between;
  margin-top: 15px;
}

.select-as-answer b {
  color: var(--kate-type-light);
  font-size: 1.25em;
}

.answer-body {
  margin-top: 15px;
  display: flex;
  gap: 15px;
  justify-content: space-between;
}

.answer-body-content {
  width: 100%;
  overflow: auto;
  padding: 15px 20px;
  border-radius: 4px;
  background-color: var(--kate-background-body-alpha);
  border: 2px solid var(--kate-background-body);
}

@media screen and (max-width: 767px) {
  .thread-btn-container {
    margin-bottom: 15px;
  }

  .select-as-answer {
    justify-content: center;
  }

  .tag-edukator {
    margin-left: 0;
  }
}
</style>

<script>
import useGlobalStore from '@stores/global';
import KGallery from '@base-components/k-gallery.vue';
import TimeMixin from '@mixins/time-mixins';
import ErrorMixin from '@mixins/error-mixins';
import KAvatar from '@base-components/k-avatar.vue';
import VoteCard from './vote-card.vue';

export default {
  components: {
    'vote-card': VoteCard,
    'k-gallery': KGallery,
    'k-avatar': KAvatar,
  },

  mixins: [TimeMixin, ErrorMixin],

  props: {
    answer: {
      type: Object,
    },
    question: {
      type: Object,
    },
    userVotes: {
      type: Array,
    },
  },

  data() {
    return {
      store: useGlobalStore(),
    };
  },

  computed: {
    isDashboard() {
      return this.store.isDashboard;
    },

    isQuestionOriginalPoster() {
      if (this.store.userId === this.question.user_id) {
        return true;
      }
      return false;
    },

    isAnswerOriginalPoster() {
      if (this.store.userId === this.answer.user_id) {
        return true;
      }
      return false;
    },

    userCanAccept() {
      if (this.isQuestionOriginalPoster || (this.isDashboard && this.$permissions.hasPermission('manage_knowledge_base'))) {
        return true;
      }
      return false;
    },

    answerName() {
      return this.answer.name !== null ? this.answer.name : 'Anonymous User';
    },

    isAcceptedAnswer() {
      return this.answer.id === this.question.accepted_id;
    },

    imageResources() {
      return this.answer.resources.filter(x => x.resource_type === 'image').map(x => x.resource_link);
    },
  },

  methods: {
    acceptAnswer() {
      this.$emit('accepted', { id: this.answer.id });
      this.$emit('awaiting');
      const payload = { answer_id: this.answer.id };
      this.$http
        .put(`/api/curriculum/knowledge/threads/${this.question.id}/accept`, payload)
        .then(() => {
          this.$logger.info('Accepted answer', { questionId: this.question.id }, true);
        })
        .catch(err => {
          this.$logger.error('Error accepting answer', { questionId: this.question.id }, err);
          this.showError(err);
        });
    },

    unacceptAnswer() {
      this.$emit('unaccepted');
      this.$http
        .put(`/api/curriculum/knowledge/threads/${this.question.id}/unaccept`)
        .then(() => {
          this.$logger.info('Unaccepted answer', { questionId: this.question.id }, true);
        })
        .catch(err => {
          this.$logger.error('Error unaccepting answer', { questionId: this.question.id }, err);
          this.showError(err);
        });
    },
  },
};
</script>
