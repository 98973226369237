<template>
  <div class="content-with-spacing email-verification-message-box">
    <h2 v-if="!finished">
      Verification in progress
    </h2>
    <div v-else-if="success" class="email-verification-message">
      Email successfully verified.
    </div>
    <div v-else class="email-verification-message">
      There was an error verifying your email: Invalid or expired link. Please try again.
    </div>
  </div>
</template>

<style>
.email-verification-message-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
}

.email-verification-message {
  font-size: larger;
  color: var(--kate-type-light);
}
</style>

<script>
import LoginMixin from '@mixins/login-mixin';

export default {
  mixins: [LoginMixin],
  data: () => ({
    success: false,
    finished: false,
  }),
  computed: {
    verificationToken() {
      return this.$route.query.verify_token;
    },
    endpoint() {
      const verificationType = this.$route.params?.verificationType;
      return verificationType ? `/api/auth/user/emails/${verificationType}/verify` : undefined;
    },
  },
  beforeMount() {
    this.$Loading.minimal();
    if (!this.verificationToken || !this.endpoint) {
      this.$router.push({ name: '404' });
    } else {
      this.verifyToken();
    }
  },
  methods: {
    verifyToken() {
      this.$http.post(this.endpoint, {
        token: this.verificationToken,
      }).then(() => {
        this.success = true;
        this.logIn();
      }).catch(err => {
        this.$logger.warn('Error logging in', undefined, err);
      }).then(() => {
        this.finished = true;
        this.$Loading.finish();
      });
    },
  },
};
</script>
