<template>
  <div class="kod-container panel panel-default">
    <div class="panel-body">
      <template v-if="loading">
        <i class="fas fa-spinner fa-spin"></i>
      </template>
      <template v-else>
        <div class="asset-row">
          <div class="asset-title">
            <i class="k-asset-icon" :class="getAssetIcon(assetSpec.iconType)"></i>
              <router-link
                v-if="currentApp"
                :to="assetLink">
                {{ assetDetails.name }} {{ assetDetails.version }}
                <i class="fad fa-arrow-up-right-from-square"></i>
              </router-link>

              <a
                v-else
                class="kod-row-absolute-link"
                :href="getAbsoluteUrl()"
                target="_blank">
                {{ assetDetails.name }} {{ assetDetails.version }}
                <i class="fad fa-arrow-up-right-from-square"></i>
              </a>
          </div>
        </div>
        <div class="asset-row">
          <router-link :to="{ name: 'spin_kod_page', params: { assetId, assetType }}">
            Open Learning Environment
            <i class="fad fa-arrow-up-right-from-square">
            </i>
          </router-link>
          <span class="time-since">Created {{ timeSince }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped>
.asset-row {
  display: flex;
  justify-content: space-between;
}

.asset-row.k-asset-icon {
  margin-right: 0.5em;
}
</style>

<script>
import { toRaw } from 'vue';
import useGlobalStore from '@stores/global';
import AssetIconMixin from '@mixins/asset-icon-mixin';
import TimeMixin from '@mixins/time-mixins';

const contentTypeToPageMap = {
  pak_id: {
    assetType: 'pak',
    appName: 'dashboard',
    iconType: 'pak',
    getRoute: asset => ({
      name: 'curriculum_asset_details',
      params: {
        assetId: asset.id,
        assetType: 'pak',
      },
    }),
    getAbsoluteUrl: (asset, appUrl) => `${appUrl}/curriculum/asset_details/pak/${asset.id}`,
    getEndpoint: id => `/api/curriculum/admin/pak/${id}`,
    getDetails: obj => obj[Object.keys(obj)[0]],
  },
  lu_id: {
    assetType: 'lu',
    getRoute: asset => ({
      name: 'curriculum_asset_details',
      params: {
        assetId: asset.id,
        assetType: 'lu',
      },
    }),
    appName: 'dashboard',
    getAbsoluteUrl: (asset, appUrl) => `${appUrl}/curriculum/asset_details/learning_unit/${asset.id}`,
    getEndpoint: id => `/api/curriculum/admin/learning_unit/${id}`,
    iconType: 'learning_unit',
    getDetails: obj => obj[Object.keys(obj)[0]],
  },
  module_pak_id: {
    assetType: 'module_pak',
    getRoute: asset => ({
      name: 'pak_ov_instructions',
      params: {
        modulePakId: asset.id,
        moduleId: asset.module_id,
      },
    }),
    appName: 'app',
    getAbsoluteUrl: (asset, appUrl) => `${appUrl}/modules/${asset.module_id}/pak/${asset.id}`,
    getEndpoint: id => `/api/curriculum/pak/${id}`,
    iconType: 'pak',
    getDetails: obj => obj,
  },
  module_lu_id: {
    assetType: 'module_lu',
    getRoute: asset => ({
      name: 'module_lu',
      params: {
        moduleLuId: asset.id,
        moduleId: asset.module_id,
      },
    }),
    appName: 'app',
    getAbsoluteUrl: (asset, appUrl) => `${appUrl}/modules/${asset.module_id}/lu/${asset.id}`,
    getEndpoint: id => `/api/curriculum/learning_unit/${id}`,
    iconType: 'learning_unit',
    getDetails: obj => obj,
  },
};

const keyPrefix = 'kloud.edukate.ai';

export default {
  mixins: [AssetIconMixin, TimeMixin],
  props: {
    labels: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    createdAt: Date,
  },
  data() {
    return {
      loading: false,
      assetDetails: undefined,
      store: useGlobalStore(),
    };
  },

  beforeMount() {
    this.getAssetDetails();
  },

  computed: {
    currentApp() {
      if (this.assetSpec) {
        return this.assetSpec.appName === this.store.appName;
      }
      return false;
    },
    assetSpec() {
      return contentTypeToPageMap[this.assetKey];
    },
    assetType() {
      return this.assetSpec?.assetType;
    },
    assetKey() {
      return this.getAssetKey();
    },
    assetLink() {
      if (this.assetSpec && this.assetKey && this.assetDetails) {
        return this.assetSpec.getRoute(this.assetDetails);
      }
      return undefined;
    },
    assetId() {
      return this.labels[`${keyPrefix}/${this.assetKey}`];
    },
    timeSince() {
      return this.getRelativeTime(this.createdAt);
    },
  },
  methods: {
    getAbsoluteUrl() {
      const baseUrl = this.assetSpec.appName === 'dashboard' ? this.store.dashboardUrl : this.store.appUrl;
      return this.assetSpec.getAbsoluteUrl(this.assetDetails, baseUrl);
    },
    getAssetKey() {
      if (!toRaw(this.labels)) {
        return undefined;
      }
      const keys = Object.keys(contentTypeToPageMap);
      for (let i = 0; i < keys.length; i++) {
        const key = `${keyPrefix}/${keys[i]}`;
        if (key in toRaw(this.labels)) {
          return keys[i];
        }
      }
      return undefined;
    },
    refineAssetDetails(res) {
      const details = this.assetSpec.getDetails(res);
      return {
        id: details.id,
        name: details.name,
        version: details.version,
        module_id: details.module_id,
      };
    },
    getAssetDetails() {
      this.loading = true;
      this.$http.get(this.assetSpec.getEndpoint(this.assetId)).then(res => {
        this.assetDetails = this.refineAssetDetails(res.data);
      }).catch(err => {
        this.$logger.autowarn('Could not get asset deatils', { [this.assetKey]: this.assetId }, err);
      }).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>
