import { defineStore } from 'pinia';

const useFeedbackExplanationsStore = defineStore('feedbackExplanations', {
  state: () => ({
    explanations: new Map(), // Using a Map to store explanations by test key
  }),

  actions: {
    setExplanation(modulePakId, testKey, explanation, feedbackExplanationId, userRating) {
      const key = `${modulePakId}-${testKey}`;
      this.explanations.set(key, { explanation, feedbackExplanationId, userRating });
    },

    getExplanation(modulePakId, testKey) {
      const key = `${modulePakId}-${testKey}`;
      return this.explanations.get(key);
    },

    hasExplanation(modulePakId, testKey) {
      const key = `${modulePakId}-${testKey}`;
      return this.explanations.has(key);
    },

    clearExplanations() {
      this.explanations.clear();
    },
  },
});

export default useFeedbackExplanationsStore;
