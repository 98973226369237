<template>
  <li class="nav-sub-group nav-sub-dropdown" :class="`${toggled ? 'opened-dropdown': 'closed-dropdown'}`">
    <button class="nav-sub-group-dropdown-title nav-sub-group-title" aria-label="Toggle dropdown" title="Toggle dropdown" @click="toggled = !toggled">
      <i class="k-asset-icon fa-duotone fa-compass-drafting"></i>
      <span>Blueprints</span>
      <i class="fas fa-angle-down"></i>
    </button>
    <ul class="third-level">
      <li class="third-level-nav-sub-group">
        <div class="nav-third-level-title">
          <router-link :to="{ name: 'curriculum_blueprints_programmes'}">
            <span>Programmes</span>
          </router-link>
        </div>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  data() {
    return {
      toggled: false,
    };
  },
};
</script>
