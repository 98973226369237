<template>
  <section class="profile-section content-with-spacing">
    <h2><i class="fas fa-lock-keyhole"></i> Change Password</h2>
    <k-panel :hasContentToggle="false">
      <template #body>
        <p>
          Click the button below to request to change your password. You will be sent an email
          to verify your identity and complete the password change process.
        </p>
        <button class="btn btn-success" @click="requestPasswordChange">
          Request Password Change
        </button>
        <p v-if="requestSent"><small>Password change request sent. Check your email for further instructions.</small></p>
      </template>
    </k-panel>
  </section>
</template>

<style scoped>
.btn-success {
  margin-bottom: 10px;
}

p > small {
  color: var(--kate-type-accent);
}
</style>

<script>
import PageReadyMixin from '@mixins/page-ready-mixin';
import KPanel from '@base-components/k-panel.vue';
import useGlobalStore from '@stores/global';

export default {
  mixins: [PageReadyMixin],

  components: {
    KPanel,
  },

  data() {
    return {
      store: useGlobalStore(),
      ready: false,
      requestSent: false,
    };
  },

  mounted() {
    this.ready = true;
  },

  computed: {
    userId() {
      return this.store.userId;
    },
  },

  methods: {
    requestPasswordChange() {
      this.$Loading.minimal();
      this.requestSent = false;
      this.$logger.info('User requested password change');
      this.$http.post('/api/profile/change_password', { userId: this.userId })
        .then(() => {
          this.$logger.info('Password change request sent');
          this.$ktoast.info('Password change request sent. Check your email for further instructions.');
          this.requestSent = true;
        })
        .catch(err => {
          this.$logger.autowarn('Password change request failed', { userId: this.userId }, err);
          this.$ktoast.warning('Failed to send password change request. Please try again later.');
        }).then(() => {
          this.$Loading.finish();
        });
    },
  },
};
</script>
