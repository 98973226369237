<template>
  <section v-if="ready" class="profile-question content-with-spacing">
      <h2><i class="fas fa-comments"></i> Knowledge Base</h2>

    <div class="panel question-container">
      <div class="panel-body k-table-main-container">
        <table class="question-table">
          <thead>
            <tr class="question-titles">
              <th class="title"><h3>Questions Posted</h3></th>
              <th class="module">Module</th>
              <th class="center status">Status</th>
              <th class="center">Visibility</th>
              <th class="center">Upvotes</th>
              <th class="center">Answers</th>
            </tr>
          </thead>
          <tbody v-if="userQuestions.length > 0">
            <tr class="question-entries" v-bind:key="question.id" v-for="question in paginatedEntries">
              <td class="title">
                <router-link class="thread-link" :to="linkToThread(question)">{{question.title}}</router-link>
              </td>
              <td class="module">
                <span>{{ question.module_name }}</span>
              </td>
              <td class="center answered">
                <span v-if="isAnswered(question)" class="tag-answered">Answered <i class="fas fa-check"></i></span>
              </td>
              <td class="center private">
                <span v-if="isPrivate(question)" class="tag-private">Private</span>
                <span v-else class="tag-public">Public</span>
              </td>
              <td class="center votes">{{question.votes}}</td>
              <td class="center responses">{{question.response_count}}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr class="question-entries empty">
              <td class="title">You have not posted any questions yet</td>
            </tr>
          </tbody>
        </table>
        <pagination
          v-if="numOfPages"
          v-model="currentPage"
          :count="numOfPages"
          :total-entries="userQuestions.length"
          :displayed-entries="paginatedEntries.length">
        </pagination>
      </div>
    </div>
  </section>
</template>

<style scoped>
/* TODO Use k-table instead */
.k-table-main-container {
  overflow-x: auto;
}

table.question-table {
  width: 100%;
}

th.title {
  width: 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.question-entries td.title {
  text-overflow: unset;
  overflow: auto;
  white-space: normal;
}

tr.question-titles th {
  color: var(--kate-type-primary);
}

th.status {
  min-width: 115px;
}

.question-titles th {
  font-weight: bold;
  border-bottom: 1px solid var(--kate-background-body-alpha);
}

.question-titles th,
.question-entries td {
  padding: 10px 10px 10px 0;
}

tr.question-entries td.center,
tr.question-titles th.center {
  text-align: center;
}

table.question-table tbody tr:nth-child(2n) {
  background-color: var(--kate-background-body-alpha);
}

.k-profile-page-hr {
  margin: 30px 0;
  border-top: 1px solid var(--kate-type-primary);
  width: 100%;
}

section h2 {
  width: 100%;
  margin-bottom: 10px;
  padding-left: 0;
}

.panel-pagination.row {
  display: flex;
  justify-content: space-between;
  margin: 0;
  align-items: center;
  padding-bottom: 15px;
  padding-top: 15px;
  width: 85%;
}
</style>

<script>
import useGlobalStore from '@stores/global';
import ErrorMixin from '@mixins/error-mixins';
import TimeMixin from '@mixins/time-mixins';
import PageReadyMixin from '@mixins/page-ready-mixin';
import Pagination from '@base-components/k-pagination.vue';

export default {
  components: {
    pagination: Pagination,
  },

  mixins: [ErrorMixin, TimeMixin, PageReadyMixin],

  data() {
    return {
      store: useGlobalStore(),
      userQuestions: [],
      userQuestionsReady: false,
      currentPage: 0,
      currentIndex: 0,
      maxItemsPerPage: 10,
    };
  },

  beforeMount() {
    this.getUserQuestions();
    this.$Loading.start();
  },

  computed: {
    ready() {
      return this.userQuestionsReady;
    },

    studentId() {
      return this.store.userId;
    },

    numOfPages() {
      if (this.userQuestions.length <= this.maxItemsPerPage) {
        return undefined;
      }
      return Math.ceil(this.userQuestions.length / this.maxItemsPerPage);
    },

    paginatedEntries() {
      if (!this.numOfPages) {
        return this.userQuestions;
      }
      const startingIndex = this.maxItemsPerPage * this.currentPage;
      const endingIndex = Math.min(this.maxItemsPerPage + startingIndex, this.userQuestions.length);
      return this.userQuestions.slice(startingIndex, endingIndex);
    },

    // To avoid having "showing 20 out of 12 entries", limit to the max number of entries.
    numberOfItemsDisplayedOptions() {
      const maxPagination = 50;
      return [10, 20, maxPagination].filter(x => x < this.userQuestions.length).concat(this.userQuestions.length < maxPagination ? [this.userQuestions.length] : []);
    },
  },

  methods: {
    isAnswered(thread) {
      return thread.accepted_id !== null;
    },

    isPrivate(thread) {
      return thread.is_private === true;
    },

    getAssetId(thread) {
      return thread.pak_id || thread.quiz_id || thread.learning_unit_id || thread.video_id || thread.slide_deck_id || thread.calendar_event_id;
    },

    linkToThread(thread) {
      if (thread.module_id && this.getAssetId(thread)) {
        return {
          name: 'knowledge_thread',
          params: {
            moduleId: thread.module_id,
            threadId: thread.id,
          },
        };
      }
      return {
        name: 'profile_knowledge_thread',
        params: {
          threadId: thread.id,
        },
      };
    },

    getUserQuestions() {
      this.$logger.info('Getting user questions', { studentId: this.studentId }, true);
      this.userQuestionsReady = false;
      this.$http.get(`/api/curriculum/knowledge/threads/user/${this.studentId}`)
        .then(response => {
          this.$logger.info('Got user questions', { studentId: this.studentId });
          this.userQuestions = response.data.threads;
        })
        .catch(err => {
          this.$logger.error('Error getting user questions', { studentId: this.studentId }, err);
        })
        .then(() => {
          this.userQuestionsReady = true;
        });
    },
  },
};
</script>
