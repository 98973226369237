<template>
  <k-modal
    class="function-error-modal"
    :show="showModal"
    @close="closeFeedbackModal"
  >
    <template #header>
      <div class="header-container">
        <h2>
          <span class="prompt" v-html="getMarkdown(displayPrompt)"></span>
        </h2>
      </div>
    </template>
    <template #body>
      <div class="feedback-container">
        <div class="explanation-section">
          <div class="explanation-header">
            <img :src="chatbotImage" class="chatbot-icon" alt="KATE bot avatar"/>
            <div class="title-section">
              <h4 class="explanation-title">KATE's Feedback <span class="beta-badge">BETA</span></h4>
              <p class="beta-disclaimer">This feature is in beta and under active development. Feedback helps us improve!</p>
            </div>
          </div>
          <div class="explanation-container">
            <div v-if="postingFeedback" class="explanation-loading">
              <i class="fas fa-spinner fa-spin"></i> Generating feedback...
            </div>
            <div v-else-if="error" class="explanation-error">
              <i class="fas fa-exclamation-circle"></i> {{ error }}
            </div>
            <div v-else-if="displayFeedback" class="explanation-content" v-html="displayFeedback">
            </div>
            <div v-if="feedback && !postingFeedback && !isFeedbackTest" class="rating-container">
                <button class="upvote"
                    @click="updateRating(true)"
                    :disabled="isRatingDisabled"
                    :class="rating ? 'upvoted': ''"
                    aria-label="Upvote button"
                    title="Upvote button"
                    type="button">
                <i class="fas fa-thumbs-up"></i>
                </button>
                <button class="downvote"
                    @click="updateRating(false)"
                    :disabled="isRatingDisabled"
                    :class="rating === false ? 'downvoted': ''"
                    aria-label="downvote button"
                    title="downvote button"
                    type="button">
                    <i class="fas fa-thumbs-down"></i>
                </button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="modal-footer-content">
        <button type="button" class="modal-close-button btn btn-danger" @click="closeFeedbackModal">
          Close
        </button>
      </div>
    </template>
  </k-modal>
</template>

<style scoped>
.chatbot-icon-small {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.explanation-section {
  background-color: var(--kate-panel-alt);
  border-radius: 8px;
  padding: 16px;
}

.explanation-header {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 16px;
}

.chatbot-icon {
  width: 32px;
  height: 32px;
}

.title-section {
  flex: 1;
}

.explanation-title {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.beta-badge {
  font-size: 0.7em;
  background-color: var(--kate-primary);
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
}

.beta-disclaimer {
  margin: 4px 0 0;
  font-size: 0.9em;
  color: var(--kate-type-secondary);
}

.explanation-loading {
  color: var(--kate-type-secondary);
}

.explanation-error {
  color: var(--kate-error);
}

.explanation-content {
  line-height: 1.5;
}

.explanation-container {
  min-height: 100px;
  overflow-y: auto;
  background: var(--kate-chat);
  border: var(--kate-chat-border);
  border-radius: 12px;
  padding: 15px;
}

.rating-container {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  margin-top: 10px;
  padding-right: 10px;
}

.rating-container button {
  background-color: var(--kate-background);
  border-radius: 50%;
  height: 35px;
  width: 35px;
  border: 1px solid;
  padding: 6px;
}

.rating-container button.upvote {
  color: var(--kate-success-alt);
  border-color: var(--kate-success-alt);
}

.rating-container button.upvote:hover,
.rating-container button.upvote.upvoted,
.rating-container button.upvote.upvoted:hover {
  color: var(--kate-type-success);
  border-color: var(--kate-type-success);
}

.rating-container button.downvote {
  color: var(--kate-danger-alt);
  border-color: var(--kate-danger-alt);
}

.rating-container button.downvote:hover,
.rating-container button.downvote.downvoted,
.rating-container button.downvote.downvoted:hover {
  color: var(--kate-danger);
  border-color: var(--kate-danger);
}

.rating-container button:disabled,
.rating-container button:disabled:hover {
  color: var(--kate-disabled);
  border-color: var(--kate-disabled);
  background-color: var(--kate-background);
}
</style>

<script>
import KModal from '@base-components/k-modal.vue';
import { importImage } from '../../modules/dynamic-image-importer';
import { markdown } from '../../modules/maths-markdown';

export default {
  components: {
    KModal,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    prompt: {
      type: Object,
    },
    currentResponse: {
      type: String,
    },
    aiFeedbackContextPrompt: {
      type: String,
      required: false,
    },
    isFeedbackTest: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      displayFeedback: null,
      displayPrompt: null,
      feedback: null,
      postingFeedback: false,
      error: null,
      rating: null,
      isRatingDisabled: false,
    };
  },

  computed: {
    chatbotImage() {
      return importImage('icons/kate-bot-chat-colours.svg');
    },
  },

  watch: {
    showModal(newVal) {
      if (newVal) {
        this.displayPrompt = this.isFeedbackTest ? this.prompt : this.prompt?.prompt;
        if (this.isFeedbackTest) {
          this.testAiFeedback();
        } else {
          this.generateFeedback();
        }
      }
    },
  },

  methods: {
    closeFeedbackModal() {
      this.feedback = null;
      this.$emit('close');
    },

    testAiFeedback() {
      this.$logger.info('Testing AI feedback', undefined, true);
      this.postingFeedback = true;
      this.$http.post('/api/chatbot/admin/questionnaire/feedback', {
        test_answer: this.currentResponse, // Note: this contains HTML from the markdown rendering but still works
        question: this.prompt, // Note: this contains HTML from the markdown rendering but still works
        context_prompt: this.aiFeedbackContextPrompt, // Note: this contains HTML from the markdown rendering but still works
      }).then(response => {
        this.displayFeedback = response.data.feedback;
        this.$logger.info('AI feedback response', response, true);
      }).catch(error => {
        this.$logger.error('AI feedback error', error, true);
        this.error = error.response?.data?.error || 'An error occurred. Please try again later.';
      }).then(() => {
        this.postingFeedback = false;
      });
    },

    generateFeedback() {
      this.$logger.info('Generating AI feedback', undefined, true);
      this.postingFeedback = true;
      this.$http.post('/api/chatbot/questionnaire/feedback', {
        questionnaire_prompt_id: this.prompt.id,
        learner_answer: this.currentResponse, // Note: this contains HTML from the markdown rendering but still works
        module_questionnaire_id: Number(this.$route.params.moduleQuestionnaireId),
      }).then(() => {
        this.$logger.info('AI feedback generated', undefined, true);
      }).catch(err => {
        this.$logger.error('Error generating AI feedback', undefined, err);
        this.error = err.response?.data?.error || 'An error occurred. Please try again later.';
      }).then(() => {
        this.getFeedback();
        this.postingFeedback = false;
      });
    },

    getFeedback() {
      this.$logger.info('Getting feedback', undefined, true);
      this.$http.get(`/api/chatbot/questionnaire/${Number(this.$route.params.moduleQuestionnaireId)}/feedback/${this.prompt.id}`)
        .then(response => {
          this.$logger.info('Feedback retrieved', undefined, true);
          this.feedback = response.data;
          this.displayFeedback = response.data.ai_feedback;
          this.isRatingDisabled = false;
          this.rating = response.data.user_rating;
        }).catch(err => {
          this.$logger.error('Error getting feedback', undefined, err);
          this.error = err.response?.data?.error || 'An error occurred. Please try again later.';
        }).then(() => {
          this.postingFeedback = false;
        });
    },

    updateRating(value) {
      this.$logger.info('Updating rating', undefined, true);
      this.$http.put(`/api/chatbot/questionnaire/${Number(this.$route.params.moduleQuestionnaireId)}/feedback/rating`, {
        questionnaire_feedback_id: this.feedback.id,
        user_rating: value,
      }).then(() => {
        this.$logger.info('Rating updated', undefined, true);
        this.rating = value;
        this.isRatingDisabled = true;
      }).catch(err => {
        this.$logger.error('Error updating rating', undefined, err);
      });
    },

    getMarkdown(str) {
      return markdown(str);
    },
  },
};
</script>
