/**
 * Recursively traverses route definitions and returns a list of route names that have the specified property.
 * If the property is 'name', returns all named routes.
 * @param {Array} routes - Array of Vue route objects
 * @param {string} property - Property to check for in each route
 * @returns {Array} Array of route names that have the specified property
 */
const getRoutesWithProperty = (routes, property, returnRouteObjects = false) => {
  const routesWithProperty = [];
  routes.forEach(route => {
    if (route.name && route[property]) {
      const { children, ...routeWithoutChildren } = route;
      routesWithProperty.push(
        returnRouteObjects ? routeWithoutChildren : route.name,
      );
    }
    if (route.children) {
      routesWithProperty.push(...getRoutesWithProperty(route.children, property, returnRouteObjects));
    }
  });
  return routesWithProperty;
};

export default getRoutesWithProperty;
