<template>
  <div class="content-with-spacing">
    <div class="help-title-container">
      <h1 class="help-title"><i class="fas fa-question-circle"></i> Help</h1>
    </div>

      <div class="col-md-6 col-sm-6 col-xs-12">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h2 class="panel-title">Advanced KATE Setup</h2>
          </div>
          <div class="panel-body">
            <p class="description">
              This is for advanced users who want to use git to submit on KATE.
            </p>
            <router-link :to="{ name: 'help_advanced_setup'}" class="btn btn-primary">
              Read More<i class="fas fa-angle-right"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
</template>

<style scoped>
.help-title-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.description {
  min-height: 100px;
}
</style>

<script>
import StorageMixin from '@mixins/storage-mixins';

export default {
  mixins: [StorageMixin],
  mounted() {
    this.$logger.pageReady();
    this.$Loading.finish();
  },
};
</script>
