<template>
  <section v-if="question" class="panel">
    <div class="panel-body">
      <div class="thread-header">
        <div class="user">
          <k-avatar :avatarUrl="question.avatar_url"></k-avatar>
          <div class="thread-title-container">
            <div class="user-info">
              <div class="user-name">
                <h2 v-if="isDashboard">
                  <router-link :to="{ name: 'dashboard_students', params: {studentId: question.user_id }}">{{ questionName }}</router-link>
                </h2>
                <h2 v-else>{{ questionName }}</h2>
                <span class="tag-edukator" v-if="question.is_edukator">Cambridge Spark</span>
              </div>
              <div class="timestamps-container">
                <span>Posted: <b>{{ parseTimestamp(question.posted_on) }}</b></span>
                <hr class="vertical-hr" />
                <span v-if="lastEdit">Last edited:
                  <b>{{ parseTimestamp(question.last_edit) }}</b>
                </span>
                <hr v-if="lastEdit" class="vertical-hr" />
                <span>Answers: <b>{{ answerCount }}</b></span>
                <hr class="vertical-hr" />
                <span>Upvotes: <b>{{ question.votes }}</b></span>
              </div>
              <div class="assignee-container" v-if="privilegedUsers.length > 0 && isDashboard && $permissions.hasPermission('manage_knowledge_base')">
                <span>Assigned to: </span>
                <k-dropdown class="dropdown-slim assign-to-dropdown" :options="assigneeOptions" v-model="assignedUserId" placeholder="Please Choose"></k-dropdown>
                <button v-if="questionAuthorCoachId" @click="assignedUserId = questionAuthorCoachId" class="btn-primary assign-to-coach">
                  Assign to Coach
                </button>
                <button v-if="questionAuthorMentorId" @click="assignedUserId = questionAuthorMentorId" class="btn-secondary assign-to-mentor">
                  Assign to Data Mentor
                </button>
              </div>
            </div>
            <thread-tags :thread="question"/>
          </div>
        </div>
        <div class="header-buttons-container action-buttons">
          <button v-if="isQuestionOriginalPoster || (isDashboard && $permissions.hasPermission('manage_knowledge_base'))" @click="$emit('openEditModal')" @edited="$emit('edited')" class="btn btn-primary edit-btn">
            Edit question <i class="fas fa-edit"></i>
          </button>
        </div>
      </div>
      <div class="thread-body-container">
        <div class="question-body">
          <div class="question-header-container">
            <div class="thread-info">
              <h1>{{ question.title }}</h1>
              <div v-if="publicThreadPossible" class="filters-container custom-toggle">
                <template v-if="$permissions.hasPermission('manage_knowledge_base')">
                  <button aria-label="Toggle" @click="togglePrivateOrPublic" title="Click to make question public" v-if="question.is_private" class="btn btn-public">Make question public</button>
                  <button aria-label="Toggle" @click="togglePrivateOrPublic" v-else title="Click to make question private" class="btn btn-danger">Make question private</button>
                </template>
                <div class="secondary-custom-toggle" v-if="!question.accepted_id && question.response_count >= 1">
                  <button v-if="!question.awaiting_op_answer" class="btn btn-warning" :class="disableToggle ? 'disabled' : ''"
                    aria-label="Toggle" @click="toggleAwaitingAnswer" title="Click to mark question as awaiting answer">
                    Mark as awaiting answer
                  </button>
                  <button v-else aria-label="Toggle" @click="toggleAwaitingAnswer" title="Click to remove awaiting answer"
                    class="btn btn-undo-awaiting" :class="disableToggle ? 'disabled' : ''">
                    Undo awaiting answer
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-html="$kpurify.sanitise(question.body)"></div>
          <div v-if="question.resources && question.resources.length > 0" class="thread-resources">
            <k-gallery :images="imageResources"></k-gallery>
          </div>
        </div>
        <div class="thread-btn-container">
          <vote-card
            :thread="question"
            :userVotes="userVotes"
            @upvote="$emit('upvote')"
            @unvote="$emit('unvote')"
            @voted="$emit('voted')"
          ></vote-card>
          <div class="question-labels">
            <span v-if="question.accepted_id" class="answered-label">Answered <i class="fas fa-check"></i></span>
            <span v-if="question.awaiting_op_answer" class="awaiting-label">Awaiting</span>
            <span v-if="question.is_private" class="private-label">Private</span>
            <span v-if="!question.is_private && isDashboard" class="public-label">Public</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style>
#content .thread-body-container h1,
#content .thread-title-container h1 {
  margin: 0;
  padding: 0;
  color: var(--kate-type-light);
}

.thread-resources .row.single-resource-container {
  margin-right: 0;
  margin-left: 0;
}

.question-body p {
  margin: 0;
}
</style>

<style scoped>
#thread-page {
  padding-top: 15px;
}

#module-overview #thread-page {
  padding-top: 45px;
}

.thread-body-container {
  display: flex;
  column-gap: 15px;
  justify-content: space-between;
}

.question-body {
  border: 2px solid var(--kate-background-body-alpha);
  padding: 15px 20px;
  border-radius: 4px;
  background-color: var(--kate-background-body-alpha);
  overflow: auto;
  width: 100%;
}

.question-body > p {
  display: grid;
}

.user {
  align-items: center;
  margin: 0;
  flex: 1;
}

.user-info {
  width: 100%;
  flex-direction: column;
  margin: 0;
}

.user-info h2 {
  font-size: 1.25em;
  margin: 0;
}

.question-header-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.thread-title-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 15px;
}

.thread-title-container .header-buttons-container {
  margin-left: 15px;
  display: flex;
  column-gap: 15px;
  align-items: flex-end;
}

.question-labels {
  margin-left: 0;
  flex: 100%;
  font-size: 13px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  column-gap: 4px;
}

span.awaiting-label,
span.answered-label,
span.private-label,
span.public-label {
  padding: 5px;
  border-radius: 5px;
}

span.answered-label {
  background-color: var(--kate-success);
  color: var(--kate-type-dark);
  white-space: nowrap;
}

span.private-label {
  background-color: var(--kate-danger);
  color: var(--kate-type-light);
}

span.awaiting-label {
  background-color: var(--kate-warning);
  color: var(--kate-type-dark);
}

.btn-public,
span.public-label {
  background-color: var(--kate-button-tertiary);
  color: var(--kate-type-light);
}

.btn-public:hover {
  background-color: var(--kate-button-tertiary-hover);
  color: var(--kate-type-light);
}

.btn-undo-awaiting {
  background-color: transparent;
  border-color: var(--kate-warning);
  color: var(--kate-warning);
}

.btn-undo-awaiting:focus,
.btn-undo-awaiting:hover {
  color: var(--kate-type-dark);
  background-color: var(--kate-warning-light);
}

.custom-toggle,
.user,
.user-info,
.timestamps-container {
  display: flexbox;
  display: flex;
}

.timestamps-container {
  flex-wrap: wrap;
}

.timestamps-container b {
  color: var(--kate-type-light);
}

.assignee-container .k-search-dropdown-menu {
  min-width: 150px;
  display: inline-block;
}

.thread-body {
  margin-top: 20px;
}

.thread-body .panel {
  padding: 15px;
  display: inline-block;
  width: 100%;
}

.thread-resources {
  margin-bottom: 15px;
  padding: 0;
}

hr {
  border: 1px solid var(--border-secondary);
}

.thread-hr {
  border: 1px solid var(--kate-background-body);
}

.custom-toggle {
  cursor: pointer;
  position: relative;
  gap: 15px;
}

.custom-toggle span {
  align-self: end;
}

.custom-toggle button.disabled {
  cursor: not-allowed;
  background-color: var(--kate-disable);
  color: var(--kate-type-alt-dark);
  border: var(--border-secondary);
}

.custom-toggle button:focus {
  outline: none;
}

.thread-info {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
}

.action-buttons {
  padding-left: 20px;
  display: flex;
  align-items: baseline;
  column-gap: 15px;
}

.action-buttons button {
  padding: 10px 15px;
}

/* media */
@media screen and (max-width: 900px) {
  .action-buttons {
    padding-left: 0;
  }

  .thread-btn-container > div {
    align-items: center;
    margin: 0 auto 15px;
    width: 100%;
  }

  .thread-header .action-buttons {
    display: block;
    width: 100%;
    text-align: center;
  }

  .question-body {
    max-width: unset;
    width: 100%;
  }
}
</style>

<script>
import useGlobalStore from '@stores/global';
import TimeMixin from '@mixins/time-mixins';
import ErrorMixin from '@mixins/error-mixins';
import KGallery from '@base-components/k-gallery.vue';
import KAvatar from '@base-components/k-avatar.vue';
import KDropdown from '@base-components/k-dropdown.vue';
import ThreadTags from './thread-tags.vue';
import VoteCard from './vote-card.vue';
import { ASSET_TYPES } from '../constants';

export default {
  components: {
    'thread-tags': ThreadTags,
    'vote-card': VoteCard,
    'k-gallery': KGallery,
    'k-avatar': KAvatar,
    'k-dropdown': KDropdown,
  },

  mixins: [TimeMixin, ErrorMixin],

  props: {
    question: {
      type: Object,
    },
    answers: {
      type: Array,
    },
    userVotes: {
      type: Array,
    },
  },

  data() {
    return {
      store: useGlobalStore(),
      disableToggle: false,
      privilegedUsers: [],
      assignedUserId: this.question.assign_to,
      questionAuthorCoachId: null,
      questionAuthorMentorId: null,
    };
  },

  beforeMount() {
    if (this.isDashboard && this.$permissions.hasPermission('manage_knowledge_base')) {
      this.getPrivilegedUsers();
      this.getStudentCoachAndMentor();
    }
  },

  watch: {
    assignedUserId() {
      if (this.assignedUserId) {
        this.assignQuestion(this.assignedUserId);
      }
    },
  },

  computed: {
    isDashboard() {
      return this.store.isDashboard;
    },

    assetId() {
      const assetTypes = Object.keys(ASSET_TYPES);
      for (let i = 0; i < assetTypes.length; i++) {
        if (this.question[`${assetTypes[i]}_id`]) {
          return this.question[`${assetTypes[i]}_id`];
        }
      }
      return null;
    },

    publicThreadPossible() {
      return Boolean(this.isDashboard && this.question.module_id && this.assetId);
    },

    questionName() {
      return this.question.name !== null ? this.question.name : 'Anonymous User';
    },

    isQuestionOriginalPoster() {
      return this.store.userId === this.question.user_id;
    },

    answerCount() {
      return this.answers.length === undefined ? 0 : this.answers.length;
    },

    lastEdit() {
      return this.question.last_edit !== this.question.posted_on;
    },

    imageResources() {
      return this.question.resources.filter(x => x.resource_type === 'image').map(x => x.resource_link);
    },

    assigneeOptions() {
      return this.privilegedUsers.map(x => ({ id: x.id, name: x.full_name }));
    },
  },

  methods: {
    // Dashboard
    togglePrivateOrPublic() {
      if (this.question.is_private !== undefined) {
        if (!this.question.is_private) {
          this.makePrivate();
        } else {
          this.makePublic();
        }
      }
    },

    toggleAwaitingAnswer() {
      if (!this.question.awaiting_op_answer || this.question.awaiting_op_answer === null) {
        this.setAsAwaitingAnswer();
      } else {
        this.setNotAwaiting();
      }
    },

    setAsAwaitingAnswer() {
      this.$logger.info('Mark question as awaiting answer');
      this.disableToggle = true;
      this.$Loading.minimal();
      this.$http.put(`/api/curriculum/knowledge/threads/${this.question.id}/set-awaiting-answer`).then(() => {
        this.$logger.info('Question marked as awaiting answer', undefined, true);
        this.$ktoast.info('Question now awaiting answer');
        this.$emit('awaiting');
      }).catch(err => {
        this.$logger.error('Error making question awaiting answer', { questionId: this.question.id }, err);
        this.showError(err);
      }).then(() => {
        this.disableToggle = false;
        this.$Loading.finish();
      });
    },

    setNotAwaiting() {
      this.$logger.info('Mark question as no longer awaiting answer');
      this.disableToggle = true;
      this.$Loading.minimal();
      this.$http.put(`/api/curriculum/knowledge/threads/${this.question.id}/not-awaiting`).then(() => {
        this.$logger.info('Question marked as no longer awaiting answer', undefined, true);
        this.$ktoast.success('Question no longer awaiting answer');
        this.$emit('awaiting');
      }).catch(err => {
        this.$logger.error('Error marking question as no longer awaiting answer', { questionId: this.question.id }, err);
        this.showError(err);
      }).then(() => {
        this.disableToggle = false;
        this.$Loading.finish();
      });
    },

    makePrivate() {
      this.disableToggle = true;
      this.$logger.info('Make question private');
      this.$http.put(`/api/curriculum/knowledge/threads/${this.question.id}/set-private`).then(() => {
        this.$logger.info('Question marked as private', undefined, true);
        this.$ktoast.info('Question now private');
        this.$emit('edited');
      }).catch(err => {
        this.$logger.error('Error making question private', { questionId: this.question.id }, err);
        this.showError(err);
      }).then(() => {
        this.disableToggle = false;
      });
    },

    makePublic() {
      this.disableToggle = true;
      this.$logger.info('Make question public');
      this.$http.put(`/api/curriculum/knowledge/threads/${this.question.id}/set-public`).then(() => {
        this.$logger.info('Question marked as public', undefined, true);
        this.$ktoast.info('Question now public');
        this.$emit('edited');
      }).catch(err => {
        this.$logger.error('Error making question public', { questionId: this.question.id }, err);
        this.showError(err);
      }).then(() => {
        this.disableToggle = false;
      });
    },

    getPrivilegedUsers() {
      this.$http.get('/api/profile/users/privileged').then(result => {
        this.privilegedUsers = result.data.users;
      }).catch(err => {
        this.$logger.error('Error retrieving privileged users', undefined, err);
        this.showError(err);
      });
    },

    assignQuestion(userId) {
      this.$logger.info('Assigning question to user', undefined, true);
      this.$http.put(`/api/curriculum/knowledge/threads/${this.question.id}/assign-to-admin/${userId}`).then(() => {
        this.$logger.info('Assigned question to user');
        this.$ktoast.success('Question Assigned');
      }).catch(err => {
        this.$logger.error('Error assigning question', { questionId: this.question.id }, err);
        this.showError(err);
      });
    },

    getStudentCoachAndMentor() {
      this.$logger.info('Getting student coach and mentor', { studentId: this.studentId }, true);
      this.studentDataReady = false;
      this.$http.get(`/api/profile/users/${this.question.user_id}`)
        .then(result => {
          const coach = result.data.coaches.find(c => c.programme_id === this.question.programme_id);
          const mentor = result.data.data_mentors.find(m => m.programme_id === this.question.programme_id);

          if (coach) {
            this.questionAuthorCoachId = coach.user_id;
          } else {
            this.$logger.info('No coach found for this programme', { studentId: this.studentId, programmeId: this.question.programme_id });
          }

          if (mentor) {
            this.questionAuthorMentorId = mentor.user_id;
          } else {
            this.$logger.info('No mentor found for this programme', { studentId: this.studentId, programmeId: this.question.programme_id });
          }
          this.$logger.info('Got student coach and mentor', { studentId: this.studentId });
        }).catch(error => {
          this.$logger.autowarn('Error getting student profile', { studentId: this.studentId }, error);
          this.showError(error);
        });
    },
  },
};
</script>
