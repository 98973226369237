<template>
  <li v-if="$permissions.hasPermission('view_knowledge_base')" class="nav-group nav-group-title knowledge-base">
    <router-link :to="{ name: 'knowledge_base_overview' }">
      <i class="k-asset-icon fa-duotone fa-comments fa-swap-opacity"></i><span>Knowledge Base</span>
    </router-link>
  </li>
</template>

<script>
import SidebarEntryMixin from '@mixins/sidebar-entry-mixin';

export default {
  mixins: [SidebarEntryMixin],

  data() {
    return {
      toggled: false,
    };
  },

  computed: {
    isVisible() {
      return this.$permissions.hasPermission('view_knowledge_base');
    },
  },
};
</script>
