<template>
  <div v-if="videoDetailsReady">
    <div class="content-with-spacing">
      <div class="heading-container">
        <h1 v-if="videoTitle">{{ videoTitle }}</h1>
        <div class="heading-buttons">
          <module-asset-completion-button :asset="asset"></module-asset-completion-button>
        </div>
      </div>
      <div class="video-container panel panel-default">
        <div v-if="!detectBrowser.isChrome && !detectBrowser.isSafari" class="category-header">
          <span class="pip-info">
            To access picture-in-picture mode, right-click over the video and select the "Picture-in-Picture" option.
          </span>
        </div>
        <k-video
          :moduleId="moduleId"
          :video="asset"
          @ready="(r) => videoReady = r"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.heading-container {
  display: flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
}

.heading-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.video-container {
  padding: 15px;
}

.category-header {
  margin-bottom: 20px;
}
</style>

<style>
#content .heading-container h1 {
  margin: 0;
}
</style>

<script>
import ErrorMixin from '@mixins/error-mixins';
import KVideo from './k-video.vue';
import ModuleAssetCompletionButton from './module-asset-completion-button.vue';

export default {
  components: {
    KVideo,
    ModuleAssetCompletionButton,
  },

  mixins: [ErrorMixin],

  props: {
    moduleDetails: {
      type: Object,
    },
    asset: {
      type: Object,
    },
    sidebarReady: {
      type: Boolean,
    },
  },

  data() {
    return {
      videoReady: false,
    };
  },

  beforeMount() {
    this.$Loading.start();
  },

  watch: {
    ready() {
      if (this.ready) {
        this.$Loading.finish();
      } else {
        this.$Loading.start();
      }
    },
    asset() {
      this.videoReady = false;
    },
  },

  computed: {
    ready() {
      return Boolean(this.moduleDetails && this.asset && this.videoReady && this.sidebarReady);
    },

    assetId() {
      return this.asset?.id;
    },

    videoDetailsReady() {
      return Boolean(this.moduleDetails && this.asset);
    },

    moduleId() {
      return parseInt(this.$route.params.moduleId, 10);
    },

    videoTitle() {
      return this.asset?.name;
    },
  },
};
</script>
